@use "global/mixin" as m;
@use "global/variable" as v;

.c-pic {
  &Step {
    width: 100%;
    margin: 0 0 88px;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      margin: 0 0 4rem;
    }

    &--right {
      @extend .c-picStep;

      .c-picStep {
        &_list {
          &:first-child {
            margin: 80px 0 0;

            @include m.sp {
              margin: 2rem 0 0;
            }
          }

          &:last-child {
            margin: -80px 0 0;

            @include m.sp {
              margin: -2rem 0 0;
            }
          }
        }
      }
    }

    &--left {
      @extend .c-picStep;

      .c-picStep {
        &_list {
          &:first-child {
            margin: -80px 0 0;

            @include m.sp {
              margin: -2rem 0 0;
            }
          }

          &:last-child {
            margin: 80px 0 0;

            @include m.sp {
              margin: 2rem 0 0;
            }
          }
        }
      }
    }

    &_list {
      width: 33%;

      @include m.pc {
        max-width: 540px;
      }

      @include m.sp {
        width: 32%;
      }
    }
  }
}
