@use "global/mixin" as m;
@use "global/variable" as v;

.c-group {
  &Wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      flex-flow: column;
    }

    &_box {
      &--short {
        width: 35%;
        margin: 0 24px 0 0;
        display: flex;

        @include m.pc {
          max-width: 285px;
        }

        @include m.sp {
          width: 100%;
          margin: 0 0 2rem;
          order: 0;
        }
      }

      &--flex {
        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          order: 1;
        }
      }
    }

    &_lead {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        padding: 1.8rem 0;
        font-size: 1.4rem;
      }

      &--green {
        @extend .c-groupWrap_lead;
        color: v.$white;
        background-color: v.$green;
      }
    }
  }
}
