@use "global/mixin" as m;
@use "global/variable" as v;

.c-slide {
  &Wrap {
    overflow: visible;

    @include m.sp {
      padding: 0 3rem;
    }
  }

  &Info {
    display: block;
    position: relative;
    z-index: 1;

    @include m.pc {
      cursor: pointer;
      transition: opacity v.$anime;

      .c-slideInfo {
        &_ico {
          &:before,
          &:after {
            transition: opacity v.$anime;
          }
        }
      }

      &:hover {
        opacity: 0.7;

        .c-slideInfo {
          &_ico {
            &:before {
              opacity: 0;
            }

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &:after {
      content: '';
      width: calc(100% - 40px);
      height: 63%;
      background: v.$gradGreen;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;

      @include m.sp {
        width: calc(100% - 2rem);
      }
    }

    &_intro {
      width: 100%;
      height: 80px;
      padding: 0 40px 0 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include m.sp {
        height: 6.4rem;
        padding: 0 2rem 0 3.5rem;
      }
    }

    &_heading {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.8571428571428572;
      color: v.$white;

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_wrap {
      width: 100%;
      padding: 0 7.7% 0 0;

      @include m.sp {
        padding: 0 2rem 0 0;
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 1;
    }

    &_pic {
      $this: &;
      width: 53%;
      height: 272px;
      display: flex;
      align-items: flex-start;

      @include m.pc {
        max-width: 252px;
      }

      @include m.sp {
        height: 24rem;
      }

      img {
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
      }

      &--tamachan {
        @extend #{$this};

        img {
          object-fit: contain;
          background-color: v.$white;
        }
      }
    }

    &_box {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: v.$white;
    }

    &_person {
      width: 100%;
      height: 120px;
      overflow: hidden;

      img {
        height: 120%;
        object-fit: cover;
      }
    }

    &_inner {
      width: 100%;
      padding: 9%;

      @include m.sp {
        padding: 1rem;
      }
    }

    &_text {
      margin: 0 0 11%;
      padding: 0 0 9%;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.6;
      border-bottom: 1px solid v.$whitePale;

      @include m.sp {
        margin: 0 0 1rem;
        padding: 0 0 1rem;
        font-size: 0.9rem;
      }
    }

    &_name {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5714285714285714;

      @include m.sp {
        font-size: 1.2rem;
      }

      &--en {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        letter-spacing: 0.05em;
        color: v.$white;
        position: absolute;
        top: -8px;
        left: calc(100% + 16px);
        z-index: 2;
        transform-origin: left;
        transform: rotate(90deg);

        @include m.sp {
          font-size: 1rem;
        }
      }
    }

    &_note {
      width: 100%;
      padding: 0px 14% 0 0;
      position: absolute;
      top: calc(100% + 12px);
      left: 0;
      z-index: 2;

      @include m.sp {
        top: calc(100% + 4px);
      }

      &List {
        font-size: 10px;
        font-weight: 500;
        line-height: 1.6;

        @include m.sp {
          font-size: 0.9rem;
        }
      }
    }

    &_ico {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 100%;
      left: 100%;
      z-index: 2;
      transform: translate(-50%, -50%);

      @include m.sp {
        width: 5rem;
        height: 5rem;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        background-color: v.$blackPale;
        z-index: -1;
      }

      &:after {
        background: v.$gradGreen;
        z-index: -2;
        opacity: 0;
      }

      &Inner {
        width: 30px;

        @include m.sp {
          width: 2.5rem;
        }
      }
    }
  }

  &Item {
    opacity: 0.5;

    &.swiper-slide-prev,
    &.swiper-slide-active,
    &.swiper-slide-next {
      opacity: 1;
    }
  }

  &Mask {
    width: 15%;
    height: 112%;
    position: absolute;
    z-index: 2;

    &--left {
      @extend .c-slideMask;
      top: 0;
      left: 0;
    }

    &--right {
      @extend .c-slideMask;
      top: 0;
      right: 0;
    }
  }

  &Arrow {
    width: 46px;
    height: 88px;
    background-color: v.$black;
    top: 50%;
    transform: translateY(-50%);

    @include m.sp {
      width: 4rem;
      height: 7rem;
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      opacity: 0;
      background: v.$gradGreen;
      z-index: -1;
    }

    &:after {
      opacity: 1;
      background-color: v.$black;
      z-index: -1;
    }

    &:after {
      display: none;
    }

    @include m.pc {
      &:before,
      &:after {
        transition: opacity v.$anime;
      }

      &:hover {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }
    }

    &.js-swiperSpPrev,
    &.js-swiperSpNext {
      @include m.pc {
        display: none;
      }

      @include m.sp {
        top: 44%;
      }
    }

    svg {
      width: 16px;
      height: 48px;

      @include m.sp {
        width: 1rem;
        height: 4rem;
      }

      path {
        stroke: v.$white;
      }
    }

    &--left {
      @extend .c-slideArrow;
      right: auto;
      left: 102%;

      @include m.tab(1440px) {
        left: 33%;
      }

      @include m.sp {
        left: 1.2rem;
      }
    }

    &--right {
      @extend .c-slideArrow;
      right: 102%;
      left: auto;

      @include m.tab(1440px) {
        right: 33%;
      }

      @include m.sp {
        right: 1.2rem;
      }
    }
  }
}
