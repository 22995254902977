@use "global/mixin" as m;
@use "global/variable" as v;

.c-scroll {
  &_heading {
  }

  &_line {
    width: 1px;
    height: 58px;
    margin: 16px auto 0;
    background: v.$white;
    animation: scrollDown 1.5s cubic-bezier(1, 0, 0, 1) infinite;

    @include m.sp {
      height: 3rem;
      margin: 1rem auto 0;
    }

    @keyframes scrollDown {
      0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
      }
      50% {
        transform: scale(1, 1);
        transform-origin: 0 0;
      }
      51% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
      }
      100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
      }
    }
  }
}
