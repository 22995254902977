@use "global/mixin" as m;
@use "global/variable" as v;

html {
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', sans-serif;

  &.is-fixed {
    overflow: hidden;
  }

  &.is-full {
    height: 100dvh;
  }
}

main {
  overflow: hidden;
}

img {
  height: auto;
  display: block;
}

.js-sliderSp {
  @include m.sp {
    padding: 0 4rem;
  }
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none !important;
  }
}

.sp {
  @include m.pc {
    display: none !important;
  }

  @include m.sp {
    display: block;
  }
}
