@use "global/mixin" as m;
@use "global/variable" as v;

.c-ac {
  width: 100%;
  border-bottom: 1px solid v.$gray;

  &_head {
    padding: 40px 72px 40px 0;
    color: v.$green;
    border-top: 1px solid v.$gray;
    position: relative;
    z-index: 1;

    @include m.pc {
      cursor: pointer;
      transition: opacity v.$anime;
    }

    &:hover {
      @include m.pc {
        opacity: 0.7;
      }
    }

    @include m.tab {
      padding: 32px 0;
    }

    @include m.sp {
      padding: 2rem 4.5rem 2rem 0;
    }

    &:after {
      content: '';
      width: 24px;
      height: 10px;
      background: url('../img/common/ico_ac_tri.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 36px;
      z-index: 2;
      transform: translateY(-50%);

      @include m.sp {
        width: 2rem;
        height: 0.8rem;
        right: 1.5rem;
      }
    }

    &.is-open {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &_body {
    opacity: 0;
    height: 0;
    padding: 0;
    transition: opacity v.$anime, height v.$anime, padding-bottom v.$anime;

    &.is-open {
      opacity: 1;
      height: auto;
      padding: 0 0 40px;

      @include m.tab {
        padding: 0 0 32px;
      }

      @include m.sp {
        padding: 0 0 2rem;
      }
    }
  }
}
