@use "global/mixin" as m;
@use "global/variable" as v;

.c-feature {
  width: 100%;
  padding: 40px 52px;
  background-color: v.$white;
  border: 1px solid;

  @include m.sp {
    padding: 2rem 2.5rem;
  }

  &--green {
    @extend .c-feature;
    border-color: v.$green;
  }

  &_heading {
    width: 100%;
    padding: 0 0 8px;
    font-size: 18px;
    font-weight: bold;
    line-height: 2.111111111111111;
    letter-spacing: 0.06em;
    text-align: center;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      padding: 0 0 0.5rem;
      font-size: 1.4rem;
    }

    &--green {
      @extend .c-feature_heading;
      color: v.$green;
    }
  }

  &_cont {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8571428571428572;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 1.2rem;
    }
  }
}
