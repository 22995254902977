@use "global/mixin" as m;
@use "global/variable" as v;

.c-balloon {
  position: relative;
  z-index: 1;

  &_cont {
    width: 100%;
    padding: 16px 20px;
    display: inline-block;
    text-align: center;
    color: v.$white;
    background-color: v.$green;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 18%;
    z-index: 2;
    transform: translate(-50%, -50%);

    @include m.pc {
      max-width: 220px;
    }

    @include m.sp {
      width: 90%;
      padding: 0.8rem 0.5rem;
      border-radius: 25px;
      top: -0.5rem;
      left: 40%;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 16px 0;
      border-color: transparent v.$green transparent transparent;
      position: absolute;
      top: calc(100% - 4px);
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
  }

  &_text {
    font-size: 14px;
    line-height: 1.8571428571428572;
    font-weight: bold;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 0.9rem;
    }
  }
}
