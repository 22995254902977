@use "global/mixin" as m;
@use "global/variable" as v;

.c-border {
  &Box {
    & + & {
      margin-top: 68px;
      padding-top: 68px;
      border-top: 1px solid;

      @include m.tab {
        margin-top: 40px;
        padding-top: 40px;
      }

      @include m.sp {
        margin-top: 2rem;
        padding-top: 2rem;
      }
    }

    &--middle {
      & + & {
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid;

        @include m.tab {
          margin-top: 28px;
          padding-top: 28px;
        }

        @include m.sp {
          margin-top: 1.5rem;
          padding-top: 1.5rem;
        }
      }
    }

    &--small {
      & + & {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid;

        @include m.tab {
          margin-top: 12px;
          padding-top: 12px;
        }

        @include m.sp {
          margin-top: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }
}
