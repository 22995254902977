@use "global/mixin" as m;
@use "global/variable" as v;

.u-ta {
  &c {
    text-align: center;
  
    &--pc {
      @include m.pc {
        text-align: center;
      }
    }

    &--sp {
      @include m.sp {
        text-align: center;
      }
    }
  }

  &r {
    text-align: right;
  
    &--pc {
      @include m.pc {
        text-align: right;
      }
    }

    &--sp {
      @include m.sp {
        text-align: right;
      }
    }
  }

  &l {
    text-align: left;
  
    &--pc {
      @include m.pc {
        text-align: left;
      }
    }

    &--sp {
      @include m.sp {
        text-align: left;
      }
    }
  }
}
