@use "global/mixin" as m;
@use "global/variable" as v;

.c-news {
  width: 100%;

  &_list {
    width: 100%;

    & + & {
      margin: 40px 0 0;

      @include m.sp {
        margin: 2rem 0 0;
      }
    }
  }

  &Inner {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      display: block;
    }

    &_head {
      width: 350px;
      margin: 0 32px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.tab {
        width: 300px;
      }

      @include m.sp {
        width: 100%;
        margin: 0 0 1rem;
      }
    }

    &_date {
      letter-spacing: 0.2em;
      font-size: 14px;
      color: v.$green;

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
      }
    }

    &_tag {
      width: 160px;
      margin: 0 0 0 20px;
      font-size: 12px;
      font-weight: bold;

      @include m.tab {
        width: 120px;
        font-size: 11px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_body {
      display: flex;
      align-items: center;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
        display: block;
      }
    }

    &_item {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8571428571428572;
      color: v.$white;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }
}
