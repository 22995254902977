@use "global/mixin" as m;
@use "global/variable" as v;

.l-section {
  position: relative;
  z-index: 1;

  &Type01 {
    $this: &;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 120px 0;

    @include m.tab {
      padding: 80px 0;
    }

    @include m.sp {
      padding: 6rem 0;
    }

    &--top {
      @extend #{$this};
      padding: 120px 0 0;

      @include m.tab {
        padding: 80px 0 0;
      }

      @include m.sp {
        padding: 6rem 0 0;
      }
    }

    &--btm {
      @extend #{$this};
      padding: 0 0 120px;

      @include m.tab {
        padding: 0 0 80px;
      }

      @include m.sp {
        padding: 0 0 6rem;
      }
    }
  }

  &Type02 {
    $this: &;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 100px 0;

    @include m.tab {
      padding: 60px 0;
    }

    @include m.sp {
      padding: 4rem 0;
    }

    &--top {
      @extend #{$this};
      padding: 100px 0 0;

      @include m.tab {
        padding: 60px 0 0;
      }

      @include m.sp {
        padding: 4rem 0 0;
      }
    }

    &--btm {
      @extend #{$this};
      padding: 0 0 100px;

      @include m.tab {
        padding: 0 0 60px;
      }

      @include m.sp {
        padding: 0 0 4rem;
      }
    }
  }

  &Type03 {
    $this: &;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 60px 0 120px;

    @include m.tab {
      padding: 40px 0 80px;
    }

    @include m.sp {
      padding: 3rem 0 6rem;
    }

    &--top {
      @extend #{$this};
      padding: 60px 0 0;

      @include m.tab {
        padding: 40px 0 0;
      }

      @include m.sp {
        padding: 3rem 0 0;
      }
    }

    &--btm {
      @extend #{$this};
      padding: 0 0 120px;

      @include m.tab {
        padding: 0 0 80px;
      }

      @include m.sp {
        padding: 0 0 6rem;
      }
    }
  }

  &Type04 {
    $this: &;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 60px 0;

    @include m.tab {
      padding: 40px 0;
    }

    @include m.sp {
      padding: 3rem 0;
    }

    &--top {
      @extend #{$this};
      padding: 60px 0 0;

      @include m.tab {
        padding: 40px 0 0;
      }

      @include m.sp {
        padding: 3rem 0 0;
      }
    }

    &--btm {
      @extend #{$this};
      padding: 0 0 60px;

      @include m.tab {
        padding: 0 0 40px;
      }

      @include m.sp {
        padding: 0 0 3rem;
      }
    }
  }
}
