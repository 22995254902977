@use "global/mixin" as m;
@use "global/variable" as v;

.l-header {
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  @include m.tab02 {
    height: 5rem;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(v.$black, 0.9);
    opacity: 0.85;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: height 0.4s cubic-bezier(0.9, 0.09, 0.2, 1);
  }

  &.is-actived {
    z-index: 9999;
    &:after {
      height: 100vh;
    }

    .l-headerInner {
      opacity: 1;
      pointer-events: auto;
      transition: opacity v.$anime 0.5s;
      z-index: 2;
    }

    .l-headerIndex {
      display: block;
      & * {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    &Head {
      width: 35%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include m.tab02 {
        width: 100%;
      }
    }

    &Body {
      width: 65%;
      display: flex;
      justify-content: flex-end;

      @include m.tab02 {
        width: 100%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;
      }
    }
  }

  &_logo {
    width: 100%;
    margin: 0 0 0 20px;

    @include m.pc {
      max-width: 283px;
    }

    @include m.tab {
      max-width: 283px;
    }

    @include m.sp {
      width: 72%;
      margin: 0 0 0 0.8rem;
    }

    &Item {
      width: 100%;
      display: block;

      @include m.pc {
        transition: v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &Nav {
    display: flex;
    flex: 1;

    @include m.tab02 {
      display: none;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &_list {
      margin: 0 3.5% 0 0;
      display: flex;
      align-items: center;
    }

    &_item {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: v.$white;

      @include m.pc {
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          width: 0;
          height: 2px;
          background-color: v.$white;
          position: absolute;
          top: calc(100% + 1px);
          left: 0;
          z-index: 2;
          transition: width 0.15s v.$animeBezierSingle02;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      @include m.tab {
        font-size: 14px;
      }

      @include m.tab02 {
        font-size: 14px;
      }
    }

    &_btn {
      display: flex;

      @include m.sp {
        display: none;
      }

      &List {
        width: 84px;
        display: flex;

        & + & {
          border-left: 1px solid v.$gray;
        }
      }
    }
  }

  &Btn {
    width: 180px;
    display: flex;

    @include m.tab02 {
      width: 7rem;
    }

    @include m.sp {
      width: 24%;
    }

    &_item {
      $this: &;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: v.$white;
      background-color: v.$green;
      border: 2px solid v.$green;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime;

        &:hover {
          color: v.$green;
          background-color: v.$white;
        }
      }

      @include m.tab02 {
        font-size: 14px;
        text-align: center;
      }

      @include m.sp {
        font-size: 1rem;
      }

      &--white {
        @extend #{$this};
        font-size: 14px;
        color: v.$black;
        background-color: v.$white;
        border: none;

        @include m.pc {
          &:hover {
            color: v.$white;
            background-color: v.$black;
          }
        }
      }

      &Br {
        display: none;

        @include m.tab02 {
          display: block;
        }
      }
    }
  }

  &Sp {
    @include m.pc {
      display: none;
    }

    @include m.tab02 {
      width: 100%;
      position: absolute;
      top: calc(100% + 1.5rem);
      left: 0;
      z-index: 2;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_list {
      & + & {
        margin: 0 0 0 2.5rem;
      }
    }

    &_item {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.2142857142857142;
      color: v.$white;
    }
  }

  /* ハンバーガー */
  &Hamburger {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: v.$green;
    border: 2px solid v.$green;

    @include m.pc {
      cursor: pointer;
      transition: background-color v.$anime;

      &:hover {
        background-color: v.$white;

        .l-headerHamburger {
          &_line {
            background-color: v.$green;
          }
        }
      }
    }

    @include m.tab02 {
      width: 5rem;
      height: 5rem;
    }

    &.is-active {
      background-color: v.$white;

      .l-headerHamburger {
        &_line {
          background-color: v.$green;

          &--top {
            -webkit-animation: hamburgerTop 0.8s v.$animeBezier forwards;
            animation: hamburgerTop 0.8s v.$animeBezier forwards;
          }

          &--mid {
            -webkit-animation: hamburgerMid 0.8s v.$animeBezier forwards;
            animation: hamburgerMid 0.8s v.$animeBezier forwards;
          }

          &--btm {
            -webkit-animation: hamburgerBtm 0.8s v.$animeBezier forwards;
            animation: hamburgerBtm 0.8s v.$animeBezier forwards;
          }
        }
      }

      @include m.pc {
        &:hover {
          background-color: v.$green;

          .l-headerHamburger {
            &_line {
              background-color: v.$white;
            }
          }
        }
      }
    }

    &.is-actived {
      .l-headerHamburger {
        &_line {
          &--top {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 1;
            -webkit-animation: hamburgerTopReverse 0.4s v.$animeBezier forwards;
            animation: hamburgerTopReverse 0.4s v.$animeBezier forwards;
          }

          &--mid {
            width: 0;
            opacity: 0;
            -webkit-animation: hamburgerMidReverse 0.4s v.$animeBezier forwards;
            animation: hamburgerMidReverse 0.4s v.$animeBezier forwards;
          }

          &--btm {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
            -webkit-animation: hamburgerBtmReverse 0.4s v.$animeBezier forwards;
            animation: hamburgerBtmReverse 0.4s v.$animeBezier forwards;
          }
        }
      }

      .l-headerIndex {
        opacity: 0;
        pointer-events: none;
        animation: none;

        & * {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &_cont {
      width: 20px;
      height: 20px;
      position: relative;
      z-index: 1;

      @include m.tab02 {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &_line {
      width: 100%;
      height: 2px;
      background-color: v.$white;
      position: absolute;
      left: 50%;
      z-index: 2;
      transition: width v.$animeBezier, transform v.$animeBezier, background-color v.$anime;

      &--top {
        @extend .l-headerHamburger_line;
        top: 0;
        transform: translateX(-50%);

        @-webkit-keyframes hamburgerTop {
          0% {
            transform: translateX(-50%);
          }

          33% {
            transform: translateX(-100%);
            opacity: 0;
          }

          66% {
            position: absolute;
            top: -50%;
            left: 150%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
          }

          100% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 1;
          }
        }

        @keyframes hamburgerTop {
          0% {
            transform: translateX(-50%);
          }

          33% {
            transform: translateX(-100%);
            opacity: 0;
          }

          66% {
            position: absolute;
            top: -50%;
            left: 150%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
          }

          100% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 1;
          }
        }

        @-webkit-keyframes hamburgerTopReverse {
          0% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 1;
          }

          33% {
            position: absolute;
            top: -50%;
            left: 150%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
          }

          66% {
            transform: translateX(-100%);
            opacity: 0;
          }

          100% {
            top: 0;
            transform: translateX(-50%);
          }
        }

        @keyframes hamburgerTopReverse {
          0% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 1;
          }

          33% {
            position: absolute;
            top: -50%;
            left: 150%;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
          }

          66% {
            transform: translateX(-100%);
            opacity: 0;
          }

          100% {
            top: 0;
            transform: translateX(-50%);
          }
        }
      }

      &--mid {
        @extend .l-headerHamburger_line;
        top: 50%;
        transform: translate(-50%, -50%);

        @-webkit-keyframes hamburgerMid {
          0% {
            transform: translate(-50%, -50%);
          }

          100% {
            width: 0;
            opacity: 0;
          }
        }

        @keyframes hamburgerMid {
          0% {
            transform: translate(-50%, -50%);
          }

          100% {
            width: 0;
            opacity: 0;
          }
        }

        @-webkit-keyframes hamburgerMidReverse {
          0% {
            width: 0;
            opacity: 0;
          }

          100% {
            transform: translate(-50%, -50%);
            width: 100%;
            opacity: 1;
          }
        }

        @keyframes hamburgerMidReverse {
          0% {
            width: 0;
            opacity: 0;
          }

          100% {
            transform: translate(-50%, -50%);
            width: 100%;
            opacity: 1;
          }
        }
      }

      &--btm {
        @extend .l-headerHamburger_line;
        bottom: 0;
        transform: translateX(-50%);

        @-webkit-keyframes hamburgerBtm {
          0% {
            transform: translateX(-50%);
          }

          33% {
            transform: translateX(100%);
            opacity: 0;
          }

          66% {
            position: absolute;
            top: 150%;
            left: -50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 0;
          }

          100% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
          }
        }

        @keyframes hamburgerBtm {
          0% {
            transform: translateX(-50%);
          }

          33% {
            transform: translateX(100%);
            opacity: 0;
          }

          66% {
            position: absolute;
            top: 150%;
            left: -50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 0;
          }

          100% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
          }
        }

        @-webkit-keyframes hamburgerBtmReverse {
          0% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
          }

          33% {
            position: absolute;
            top: 150%;
            left: -50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 0;
          }

          66% {
            transform: translateX(100%);
            opacity: 0;
          }

          100% {
            top: auto;
            bottom: 0;
            transform: translateX(-50%);
          }
        }

        @keyframes hamburgerBtmReverse {
          0% {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
          }

          33% {
            position: absolute;
            top: 150%;
            left: -50%;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 0;
          }

          66% {
            transform: translateX(100%);
            opacity: 0;
          }

          100% {
            top: auto;
            bottom: 0;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  /* 展開後メニュー */
  &Inner {
    width: 100vw;
    height: 100vh;
    padding: 64px 0;
    position: absolute;
    top: 0%;
    left: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    @include m.tab {
      padding: 5rem 0 4rem;
    }

    @include m.sp {
      padding: 6rem 0 11rem;
      /* chromeに「svh」単位が適用されたら↓に変更.. */
      // padding: 6rem 0 0;
      // height: 100svh;
    }

    &_wrap {
      width: 100%;
      height: 100%;
      padding: 60px 0 0;
      overflow-y: scroll;

      @include m.pc {
        margin: 20px 0 0;
      }

      @include m.tab02 {
        padding: 2rem 0 0;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ccc;
      }
      &::-webkit-scrollbar-thumb {
        background-color: v.$green;
      }
    }

    &_cont {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }
  /* end 展開後メニュー */

  /* メニュー用アニメーション */
  @keyframes frameOut {
    0% {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }

    100% {
      opacity: 0;
      transform: translateX(-50px);
      pointer-events: none;
    }
  }
  @keyframes frameOutReverse {
    0% {
      opacity: 0;
      transform: translateX(-50px);
      pointer-events: none;
    }

    100% {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }
  }
  @keyframes frameIn {
    0% {
      opacity: 0;
      transform: translateX(50px);
      pointer-events: none;
    }

    100% {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }
  }
  @keyframes frameInReverse {
    0% {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }

    100% {
      opacity: 0;
      transform: translateX(50px);
      pointer-events: none;
    }
  }
  /* end メニュー用アニメーション */

  /* 目次メニュー */
  &Index {
    width: 100%;
    -webkit-animation: frameOutReverse 0.1s ease forwards;
    animation: frameOutReverse 0.1s ease forwards;
    opacity: 0;
    pointer-events: none;
    display: none;

    &.is-animated {
      -webkit-animation: frameOut 0.1s ease forwards;
      animation: frameOut 0.1s ease forwards;
    }

    &.is-hide {
      display: none;
    }

    & * {
      opacity: 0;
      pointer-events: none;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.tab02 {
        flex-wrap: wrap;
      }

      @include m.sp {
        display: block;
      }

      & + & {
        @include m.pc {
          margin-top: 84px;
        }

        @include m.tab {
          margin-top: 4rem;
        }
      }

      &Main {
        @include m.pc {
          width: 64%;
          max-width: 580px;
        }

        @include m.tab {
          width: 100%;
          max-width: 100%;
        }
      }

      &Sub {
        @include m.pc {
          width: 27%;
          max-width: 240px;
        }

        @include m.tab {
          width: 100%;
          max-width: 100%;
        }

        @include m.tab02 {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }
  /* end 目次メニュー */

  /* 目次メニュー内リスト */
  &Menu {
    $this: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include m.pc {
      &:after {
        content: '';
        width: 27%;
        max-width: 240px;
        display: block;
      }
    }

    @include m.sp {
      display: block;
    }

    &--btn {
      @extend #{$this};
      @include m.tab02 {
        padding: 3rem 0 0;
        display: flex;
        order: 0;
      }

      @include m.sp {
        justify-content: space-between;
      }

      &Type02 {
        @extend #{$this};
        @include m.tab02 {
          padding: 3rem 0 0;
          display: flex;
          order: 2;
        }
      }
    }

    &_list {
      $this: &;

      @include m.pc {
        width: 27%;
        max-width: 240px;
      }

      &:nth-child(n + 4) {
        @include m.pc {
          margin-top: 24px;
        }
      }

      &--half {
        @include m.pc {
          width: 47%;
          max-width: 240px;
        }

        @include m.tab {
          width: 27%;
        }

        &:nth-child(n + 3) {
          @include m.pc {
            margin-top: 24px;
          }

          @include m.tab02 {
            margin-top: 0;
          }
        }
      }
    }

    &_item {
      $this: &;
      width: 100%;
      padding: 16px 0;
      display: block;
      line-height: 1;
      color: v.$white;
      border-bottom: 1px solid v.$green;
      position: relative;
      z-index: 1;

      @include m.pc {
        cursor: pointer;

        &:before {
          content: '';
          width: 0;
          height: 1px;
          background-color: v.$white;
          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 2;
          transition: width 0.15s v.$animeBezierSingle02;
        }

        &:hover {
          &:before {
            width: 100%;
          }

          &:after {
            background-image: url('../img/common/ico_arrow_white.svg');
            background-size: contain;
          }
        }
      }

      &:after {
        content: '';
        width: 28px;
        height: 10px;
        background: url('../img/common/ico_arrow_green.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 2px);
        right: 0;
        z-index: 2;
        transform: translateY(-50%);

        @include m.pc {
          transition: background-image 0.15s ease;
        }

        @include m.tab02 {
          width: 20px;
          height: 8px;
        }

        @include m.sp {
          width: 2rem;
          height: 0.9rem;
          right: 1rem;
        }
      }

      &--reverse {
        @extend #{$this};
        text-align: right;

        &:after {
          right: 0;
          left: 0;
          transform: translateY(-50%) rotateY(180deg);
        }
      }
    }

    &_sub {
      @include m.pc {
        display: none;
      }

      @include m.tab02 {
        width: 100%;
        padding: 3rem 0.5rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        order: 1;

        &:after {
          content: '';
          width: 33%;
          display: block;
        }
      }

      &List {
        width: 33%;

        @include m.tab {
          text-align: center;
        }

        &:nth-child(n + 4) {
          margin-top: 2rem;
        }
      }

      &Item {
        display: inline-block;
        color: v.$white;

        @include m.tab {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            width: 0;
            height: 2px;
            background-color: v.$white;
            position: absolute;
            top: calc(100% + 1px);
            left: 0;
            z-index: 2;
            transition: width 0.15s v.$animeBezierSingle02;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }

    &_btn {
      width: 100%;

      @include m.tab02 {
        width: 27%;
      }

      @include m.sp {
        width: 48%;
      }

      & + & {
        @include m.pc {
          margin-top: 40px;
        }

        @include m.tab {
          margin-top: 0;
        }
      }

      &Item {
        @extend #{$this};
        width: 100%;
        padding: 24px;
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: v.$white;
        background-color: v.$green;
        border: 1px solid v.$green;
        position: relative;
        z-index: 1;

        @include m.pc {
          transition: color v.$anime, background-color v.$anime;

          &:hover {
            color: v.$green;
            background-color: v.$white;

            &:after {
              background: url('../img/common/ico_arrow_green.svg') no-repeat center;
              background-size: contain;
            }
          }
        }

        @include m.tab02 {
          padding: 20px 20px;
          font-size: 14px;
        }

        @include m.sp {
          padding: 2rem 1.5rem;
          font-size: 1.3rem;
        }

        &:before {
          display: none;
        }

        &:after {
          content: '';
          width: 28px;
          height: 10px;
          background: url('../img/common/ico_arrow_white.svg') no-repeat center;
          background-size: contain;
          position: absolute;
          top: calc(50% - 2px);
          right: 24px;
          z-index: 2;
          transform: translateY(-50%);

          @include m.pc {
            transition: background-image 0.15s ease;
          }

          @include m.tab02 {
            width: 20px;
            height: 8px;
          }

          @include m.sp {
            width: 2rem;
            height: 0.9rem;
            right: 1rem;
            background-image: url('../img/common/ico_arrow_white.svg');
            background-size: contain;
          }
        }
      }

      &Login {
        @extend #{$this};
        width: 100%;
        display: none;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: v.$black;
        background-color: v.$white;
        border: none;
        position: relative;
        z-index: 1;

        @include m.pc {
          transition: color v.$anime, background-color v.$anime;

          &:hover {
            color: v.$white;
            background-color: v.$black;

            &:before {
              width: 100%;
            }
          }
        }

        @include m.tab02 {
          padding: 16px 24px;
          display: block;
          font-size: 14px;
        }

        @include m.sp {
          padding: 1.5rem;
          font-size: 1.3rem;
        }

        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
  /* end 目次メニュー内リスト */

  /* その他メニュー */
  &Other {
    width: 100%;
    opacity: 0;
    transform: translateX(50px);
    pointer-events: none;
    -webkit-animation: frameInReverse 0.1s ease forwards;
    animation: frameInReverse 0.1s ease forwards;
    position: absolute;
    top: 0;
    left: 0;

    &.is-animated {
      -webkit-animation: frameIn 0.1s ease forwards 0.1s;
      animation: frameIn 0.1s ease forwards 0.1s;
    }

    &_cont {
      display: none;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.05s ease;

      &.is-view {
        display: block;
        opacity: 1;
        pointer-events: auto;
      }

      &Box {
        & + & {
          margin-top: 60px;
          display: flex;

          @include m.sp {
            margin-top: 3rem;
            display: block;
          }
        }

        .l-headerMenu {
          @include m.sp {
            width: 40%;
          }
        }

        &Inner {
          width: 50%;

          @include m.sp {
            width: 100%;

            & + & {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  /* end その他メニュー */

  &Local {
    width: 100%;

    &_list {
      .l-headerLocal_inner {
        margin-top: 20px;
      }

      & + & {
        margin-top: 20px;
      }
    }

    &_item {
      $this: &;
      color: v.$white;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      &--ico {
        @extend #{$this};
        display: inline-flex;
        align-items: center;
      }

      &Ico {
        width: 16px;
        margin: 0 0 0 4px;
        display: block;
      }
    }

    &_inner {
      width: 100%;
      padding: 0 0 0 20px;

      &List {
        width: 100%;

        & + & {
          margin-top: 20px;
        }

        & > .l-headerLocal_item {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            width: 8px;
            height: 1px;
            margin-right: 8px;
            background-color: v.$green;
          }
        }

        .l-headerLocal_innerEnd {
          margin-top: 20px;
        }
      }

      /* メニュー内最下層 */
      &End {
        width: 100%;
        padding: 0 0 0 40px;

        &List {
          & + & {
            margin-top: 20px;
          }
        }
      }
      /* end メニュー内最下層 */
    }
  }
}
