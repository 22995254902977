@use "global/mixin" as m;
@use "global/variable" as v;

.c-link {
  &Text {
    display: inline-flex;
    align-items: baseline;
    font-size: inherit;
    color: v.$green;
    text-decoration: underline;

    @include m.pc {
      transition: opacity v.$anime;
    }

    &:hover {
      @include m.pc {
        opacity: 0.7;
      }
    }

    &_ico {
      width: 15px;
      height: 15px;
      margin-left: 4px;
    }
  }

  /* sp時にのみリンクとして表示される */
  &Info {
    @include m.pc {
      display: inline-block;
      pointer-events: none;
    }

    @include m.sp {
      text-decoration: underline;
      color: v.$green;
    }
  }
  /* end sp時にのみリンクとして表示される */

  /* サイトマップなどで使用するリンク一覧にネストする要素 */
  &Inner {
    width: 100%;

    &_list {
      margin-top: 4px;
      display: flex;
      justify-content: flex-start;

      @include m.sp {
        margin-top: 12px;
      }

      &:before {
        content: '-';
        margin: 0 0.5em 0 1em;
        color: v.$green;
      }
    }
  }
  /* end サイトマップなどで使用するリンク一覧にネストする要素 */
}
