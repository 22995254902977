@use "global/mixin" as m;
@use "global/variable" as v;

.c-table {
  width: 100%;

  @include m.sp {
    overflow-x: scroll;

    &:before {
      content: '※横にスクロールできます';
      display: block;
      padding: 0 0 0.5rem;
      font-size: 1.3rem;
    }
  }

  th,
  td {
    vertical-align: middle;
    border: 1px solid v.$gray;

    @include m.pc {
      max-width: 460px;
    }

    &.c-table {
      &_field {
        &--long {
          &--sp {
            @include m.sp {
              width: 330px;
            }
          }
        }

        &--small {
          &--sp {
            @include m.sp {
              width: 120px;
            }
          }
        }
      }
    }
  }

  th {
    padding: 8px 20px;

    @include m.tab {
      padding: 8px 16px;
    }

    @include m.sp {
      width: 180px;
      padding: 0.5rem 1rem;
    }
  }

  td {
    padding: 12px;

    @include m.tab {
      padding: 10px;
    }

    @include m.sp {
      padding: 1.2rem;
    }
  }

  &_size {
    &--w100 {
      &--pc {
        @include m.pc {
          width: 100px;
        }
      }
    }

    &--w110 {
      &--pc {
        @include m.pc {
          width: 110px;
        }
        @include m.tab {
          width: 90px;
        }
      }
    }

    &--w120 {
      &--pc {
        @include m.pc {
          width: 120px;
        }
        @include m.tab {
          width: 100px;
        }
      }
    }

    &--w130 {
      &--pc {
        @include m.pc {
          width: 130px;
        }
        @include m.tab {
          width: 110px;
        }
      }
    }

    &--w140 {
      &--pc {
        @include m.pc {
          width: 140px;
        }
        @include m.tab {
          width: 100px;
        }
      }
    }

    &--w160 {
      &--pc {
        @include m.pc {
          width: 160px;
        }
        @include m.tab {
          width: 140px;
        }
      }
    }

    &--w180 {
      &--pc {
        @include m.pc {
          width: 180px;
        }
        @include m.tab {
          width: 140px;
        }
      }
    }

    &--w230 {
      &--pc {
        @include m.pc {
          width: 230px;
        }
      }
    }

    &--w300 {
      width: 300px;
    }

    &--w340 {
      &--pc {
        @include m.pc {
          width: 340px;
        }
        @include m.tab {
          width: auto;
        }
      }
    }
  }

  &_cont {
    $this: &;
    width: 100%;

    @include m.sp {
      width: 767px;
      table-layout: fixed;
    }

    &--full {
      &--sp {
        @extend #{$this};
        @include m.sp {
          width: 100%;
        }
      }
    }

    &--fixed {
      @extend #{$this};
      table-layout: fixed;
    }
  }

  &_head {
    $this: &;
    color: v.$white;

    &--green {
      @extend #{$this};
      background-color: v.$green;

      &Pale {
        @extend #{$this};
        background-color: rgba(v.$green, 0.5);
      }

      &Pale02 {
        @extend #{$this};
        background-color: rgba(v.$green, 0.7);
      }

      &Pale03 {
        @extend #{$this};
        background-color: rgba(v.$green, 0.1);
      }
    }
  }

  &_inner {
    & + & {
      margin-top: 1em;
    }
  }

  &_text {
    $this: &;
    font-size: 14px;
    line-height: 1.8571428571428572;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 1.1rem;
    }

    &--vertical {
      @extend #{$this};
      width: 40px;
      line-height: 1;

      @include m.sp {
        min-height: 2rem;
        white-space: nowrap;
      }

      & * {
        -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
        writing-mode: vertical-lr;
      }
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }

    &--small {
      font-size: 12px;
      line-height: 1.4;

      @include m.tab {
        font-size: 11px;
      }

      @include m.sp {
        font-size: 1rem;
      }
    }
  }

  &_link {
    text-decoration: underline;

    @include m.pc {
      transition: opacity v.$anime;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &_color {
    $this: &;
    font-weight: bold;

    &--green {
      @extend #{$this};
      color: v.$green;
    }
  }

  &Modal {
    thead {
      th {
        padding: 12px 0;
        text-align: center;
        color: v.$white;
      }
    }

    tbody {
      th,
      td {
        padding: 40px 12px;
        vertical-align: middle;
        text-align: center;

        @include m.sp {
          padding: 2rem 1rem;
        }
      }

      th {
        color: v.$green;
        background-color: v.$greenPale02;
      }

      td {
        background-color: v.$white;
      }
    }

    th,
    td {
      border: 1px solid v.$black;
    }
  }

  &Note {
    width: 100%;
    margin-top: 16px;

    @include m.tab {
      margin-top: 12px;
    }

    @include m.sp {
      margin-top: 1rem;
    }

    &_list {
      $this: &;

      &--indent {
        @extend #{$this};
        padding: 0 0 0 1em;
        text-indent: -1em;

        &Num {
          @extend #{$this};
          padding: 0 0 0 2em;
          text-indent: -2em;
        }
      }
    }
  }

  &Label {
    width: 100%;
    margin-top: 16px;
    display: flex;

    &_list {
      & + & {
        margin-left: 32px;
      }
    }

    &_color {
      $this: &;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        width: 60px;
        height: 20px;
        margin-right: 8px;
        display: block;
      }

      &--gray {
        @extend #{$this};
        &:before {
          background-color: v.$grayDeep02;
        }
      }

      &--pink {
        @extend #{$this};
        &:before {
          background-color: v.$pinkDeep;
        }
      }
    }
  }
}
