@use "global/mixin" as m;
@use "global/variable" as v;

/* 入試情報ページ用scssファイルです */

.p-admission {
  &Lead {
    margin-top: 4rem;
  }
  &Section {
    margin-top: 3.2rem;
    @include m.pc {
      margin-top: 32px;
    }
  }
  &Txt {
    margin-top: 1em;
  }
  &BoxRow01--sp {
    @include m.sp {
      width: 100%;
    }
  }
  &FixedBtn {
    $this: &;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    z-index: 9990;
    opacity: 0;
    pointer-events: none;
    transition: opacity v.$anime;
    @include m.sp {
      width: 82%;
      left: 0;
    }
    @include m.pc {
      right: 84px;
    }
    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }
    &.is-fixed {
      opacity: 0;
      pointer-events: none;
    }
    &_btn {
      width: 48%;

      height: 5.5rem;
      @include m.pc {
        width: 270px;
        height: 64px;
      }
      & + & {
        margin-left: 15px;

        @include m.sp {
          margin-left: 1.2rem;
        }
      }
    }
  }

  &Top {
    &Exam {
      @include m.pc {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }
      &_btn {
        @include m.pc {
          margin-right: 3%;
          width: 30%;
        }
      }
      &_link {
        display: block;
        position: relative;
        margin-top: 1em;
        padding-right: 34px;
        color: #fff;
        &:first-of-type {
          margin-top: 0;
        }
        @include m.pc {
          transition: opacity 0.4s;
        }
        &:hover {
          @include m.pc {
            opacity: 0.7;
          }
        }
      }
      &LinkBox {
        @include m.pc {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 67%;
        }
        @include m.sp {
          margin-top: 2rem;
        }
        &_inner {
          &:nth-of-type(1) {
            @include m.pc {
              width: 44%;
            }
          }
          &:nth-of-type(2) {
            @include m.pc {
              width: 54%;
            }
            @include m.sp {
              margin-top: 1rem;
            }
          }
        }
      }
    }
    &Oc {
      padding-top: 46px;
      padding-bottom: 50px;
      @include m.pc {
        padding-top: 93px;
        padding-bottom: 100px;
      }
    }
    &OcList {
      width: 600px;
      max-width: 100%;
      margin: 4em auto 0;
      &_detail {
        margin-top: 4em;
      }
    }
    &Pamph {
      margin-bottom: 4em;
      &List {
        @include m.pc {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          width: 870px;
        }
        &_item {
          @include m.pc {
            margin: 0 40px 40px;
            width: 350px;
          }
          & + & {
            @include m.sp {
              margin-top: 4em;
            }
          }
        }
        &_pic {
          margin-bottom: 18px;
          @include m.pc {
            margin-bottom: 38px;
          }
        }
        &_ttl {
          margin-bottom: 1em;
        }
        &_txt {
          margin-bottom: 18px;
          @include m.pc {
            margin-bottom: 38px;
          }
        }
        &_btn {
          margin-top: 15px;
          @include m.pc {
            margin-top: 30px;
          }
        }
      }
    }
  }

  &Exam {
    &_table {
      td {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
    &_btn {
      @include m.pc {
        margin: 30px 15px 0;
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        @include m.pc {
          margin-top: 0;
        }
      }
    }
    &_ttl {
      margin-top: 38px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &_txt {
      margin-top: 15px;
      &--underline {
        text-decoration: underline;
      }
    }
    &LinkList {
      @include m.pc {
        display: flex;
        flex-wrap: wrap;
        width: 800px;
      }
    }
    &Symbol {
      &_head--num {
        width: 20px;
      }
    }
  }

  &ExamComposite {
    &Lead {
      margin-bottom: 4em;
    }
    &Table {
      &_txt {
        margin-top: 10px;
      }
    }
    &Box {
      border-bottom: 1px solid v.$gray;
      padding: 40px 0;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    &Info {
      $this: &;
      display: flex;
      align-items: flex-start;

      &--block {
        @extend #{$this};

        @include m.sp {
          display: block;
        }
      }

      &_head {
        width: 120px;
        margin-right: 20px;
      }

      &_body {
        flex: 1;
      }
    }
  }

  &ExamRecommend {
    &List {
      @include m.pc {
        display: flex;
        flex-wrap: wrap;
      }
      &_head,
      &_body {
        margin-bottom: 1em;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &_head {
        @include m.pc {
          width: 15%;
        }
      }
      &_body {
        @include m.pc {
          width: 85%;
        }
      }
    }
  }

  &ExamForeign {
    &List {
      width: 100%;

      & + & {
        margin-top: 1rem;
      }

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;

      @include m.sp {
        justify-content: flex-start;
      }

      &_head {
        margin: 0 16px 0 40px;

        @include m.sp {
          margin: 0;
        }
      }
      &_body {
        margin: 0 40px 0 16px;

        @include m.sp {
          margin: 0 0 0 2rem;
        }
      }
    }
  }

  &Preparatory {
    &Goal {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 4em;
      &_circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background: #fff;
        color: v.$green;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
        @include m.pc {
          width: 260px;
          height: 260px;
          font-size: 21px;
        }
      }
      &_x {
        position: relative;
        margin: 0 1rem;
        width: 6rem;
        height: 6rem;
        opacity: 0.5;
        @include m.pc {
          margin: 0 20px;
          width: 150px;
          height: 150px;
        }
        &::before,
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 6rem;
          height: 0.4rem;
          background: #fff;
          content: '';
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  &Expense {
    &Chart {
      &_wrap {
        border-top: 1px solid v.$gray;
        border-bottom: 1px solid v.$gray;
        margin-top: 4em;
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
    }
  }
}
