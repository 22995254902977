@use "global/mixin" as m;
@use "global/variable" as v;

.c-course {
  width: 100%;

  & + & {
    margin: 80px 0 0;

    @include m.sp {
      margin: 6rem 0 0;
    }
  }

  &_cont {
    width: 100%;
    padding: 0 0 8%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include m.pc {
      &:before,
      &:after {
        transition: opacity 0.2s ease;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    @include m.sp {
      flex-flow: column;
    }

    &:before,
    &:after {
      content: '';
      width: 80%;
      height: 100%;
      position: absolute;
      top: 0;

      @include m.pc {
        max-width: 920px;
      }

      @include m.sp {
        width: 98%;
        height: 82%;
        top: 7rem;
      }
    }

    &:before {
      background-color: v.$blackPale;
      z-index: -2;

      @include m.sp {
        opacity: 0;
      }
    }

    &:after {
      background: v.$gradGreen;
      opacity: 0;
      z-index: -1;

      @include m.sp {
        opacity: 1;
      }
    }

    &--bgRight {
      @extend .c-course_cont;

      &:before,
      &:after {
        right: 0;

        @include m.sp {
          right: -6.5%;
        }
      }
    }

    &--bgLeft {
      @extend .c-course_cont;

      &:before,
      &:after {
        left: 0;

        @include m.sp {
          left: -6.5%;
        }
      }
    }
  }

  &_pic {
    width: 49%;

    @include m.pc {
      max-width: 540px;
    }

    @include m.sp {
      width: 100%;
      order: 0;

      img {
        width: 88%;
        height: 100%;
      }

      & + .c-course_box {
        padding: 2rem 2rem 3rem 6rem;
      }
    }
  }

  &_box {
    padding: 4% 7% 2%;
    display: flex;
    flex-flow: column;

    @include m.pc {
      flex: 1;
      align-items: flex-start;
      justify-content: flex-end;
    }

    @include m.sp {
      padding: 2rem 6rem 3rem 2rem;
      align-items: flex-end;
      justify-content: flex-end;
      order: 1;

      & + .c-course_pic {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &_heading {
    width: 100%;
    padding: 0 0 16px;
    font-size: 20px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.06em;
    color: v.$white;
  }

  &_text {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8571428571428572;
    color: v.$white;
  }

  &_inner {
    width: 100%;
    padding: 28px 0 0;
    display: flex;
    justify-content: flex-end;

    @include m.sp {
      padding: 1rem 0 0;
    }
  }

  &_other {
    width: 100%;
    margin: -60px 0 0;
    display: flex;
    justify-items: flex-start;
    position: relative;
    z-index: 2;

    @include m.sp {
      margin: -3rem 0 0;
    }

    &--end {
      @extend .c-course_other;
      justify-content: flex-end;
    }
  }
}
