@use "global/mixin" as m;
@use "global/variable" as v;

.u-lh {
  /* font-size: 16px + line-height: 34px */
  &Type01 {
    line-height: 2.125;
  }

  /* font-size: 14px + line-height: 26px */
  &Type02 {
    line-height: 1.8571428571428572;
  }

  /* font-size: 12px + line-height: 19px */
  &Type03 {
    line-height: 1.5833333333333333;
  }

  /* font-size: 36px + line-height: 56px */
  &Type04 {
    line-height: 1.5555555555555556;
  }

  /* font-size: 24px + line-height: 46px */
  &Type05 {
    line-height: 1.9166666666666667;
  }

  /* font-size: 18px + line-height: 29px */
  &Type05 {
    line-height: 1.9166666666666667;
  }
}
