@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'sass:math';

$properties: (
  "mt": margin-top,
  "mb": margin-bottom,
  "ml": margin-left,
  "mr": margin-right,
  "pt": padding-top,
  "pb": padding-bottom,
  "pl": padding-left,
  "pr": padding-right,
  "gap": gap,
);

.u-m-a {
  margin: auto;
}

.u-mx-a {
  margin-left: auto;
  margin-right: auto;
}

// Half value while using mobile
@each $property, $property-value in $properties {
	@for $i from 0 through 10 {
		.u-#{$property}-#{$i*10} {
			#{$property-value}: #{$i*10}px !important;
		}

		.u-#{$property}-sp-#{$i*10} {
      @include m.sp {
        #{$property-value}: #{$i}rem !important;
      }
		}
	}
}
