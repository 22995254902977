@use "global/mixin" as m;
@use "global/variable" as v;

.c-recommend {
  width: 100%;

  &_intro {
    width: 100%;
    margin: 0 0 60px;

    @include m.sp {
      margin: 0 0 3rem;
    }
  }

  &_heading {
    width: 100%;
    margin: 0 0 48px;
    display: flex;
    align-items: center;

    @include m.sp {
      margin: 0 0 2.4rem;
    }

    &--start {
      @extend .c-recommend_heading;
      justify-content: flex-start;
    }

    &--end {
      @extend .c-recommend_heading;
      justify-content: flex-end;

      @include m.sp {
        justify-content: flex-start;
      }
    }

    &Category {
      width: auto;
      height: 44px;
      margin: 0 12px 0 0;

      @include m.sp {
        height: 2.2rem;
        margin: 0 0.6rem 0 0;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    &Obj {
      width: auto;
      height: 66px;
      margin: 0 12px 0 0;

      @include m.sp {
        height: 3rem;
        margin: 0 1rem 0 0;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    &Text {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.06em;
      color: v.$green;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.8rem;
        letter-spacing: 0.03em;
      }
    }
  }

  &_text {
    font-size: 16px;
    font-weight: bold;
    line-height: 2.125;
    color: v.$white;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &Media {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include m.sp {
      width: 112%;
      margin: 0 0 0 -6%;
      flex-flow: column;
    }

    & + & {
      margin: 300px 0 0;

      @include m.sp {
        margin: 8rem 0 0 -6%;
      }
    }

    &--end {
      @extend .c-recommendMedia;
      justify-content: flex-end;
    }

    &_box {
      width: 68%;
      padding: 4% 5%;
      background: v.$gradGreen;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 750px;

        & + .c-recommendMedia_pic {
          top: 50px;
          right: -70px;
          left: auto;
        }

        & + .c-recommendMedia_pic--balloon {
          @include m.pc {
            top: 22px;
          }
        }
      }

      @include m.tab {
        width: 58%;

        & + .c-recommendMedia_pic {
          right: 5px;
        }
      }

      @include m.sp {
        width: 88%;
        padding: 3rem;
        order: 0;
      }
    }

    &_inner {
      width: 80%;

      @include m.pc {
        max-width: 300px;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &_heading {
      width: 100%;
      margin: 0 0 28px;
      padding: 0 0 12px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.9166666666666667;
      letter-spacing: 0.06em;
      color: v.$white;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 32px;
        height: 1px;
        background-color: v.$white;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }

    &_lead {
      width: 100%;
      padding: 0 0 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.125;
      color: v.$white;
    }

    &_text {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8571428571428572;
      color: v.$white;
    }

    &_pic {
      width: 68%;

      @include m.pc {
        max-width: 750px;
        position: absolute;
        top: 50px;
        left: -70px;
        z-index: 2;

        & + .c-recommendMedia_box {
          display: flex;
          justify-content: flex-end;
        }
      }

      @include m.tab {
        width: 58%;
      }

      @include m.sp {
        width: 100%;
        order: 1;
      }

      &--balloon {
        @extend .c-recommendMedia_pic;

        @include m.pc {
          top: 22px;
        }

        @include m.sp {
          margin-top: 2rem;
        }
      }
    }
  }
}
