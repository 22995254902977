@use "global/mixin" as m;
@use "global/variable" as v;

.c-media {
  &Wrap {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include m.sp {
      flex-flow: column;
    }

    & + & {
      margin: 68px 0 0;

      @include m.sp {
        margin: 4rem 0 0;
      }
    }

    &--center {
      @extend #{$this};
      align-items: center;
    }

    &--border {
      &Btm {
        padding: 0 0 120px;
        border-bottom: 1px solid v.$green;

        @include m.tab {
          padding: 0 0 80px;
        }

        @include m.sp {
          padding: 0 0 6rem;
        }
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
        order: 1;
      }

      & + .c-mediaWrap_pic {
        margin: 0 0 0 40px;

        @include m.sp {
          margin: 0 auto 2rem;
        }

        &:after {
          right: 0;
          left: auto;
        }
      }

      &--flex {
        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          width: 100%;
          order: 1;
        }

        & + .c-mediaWrap_pic {
          margin: 0 0 0 100px;

          @include m.tab {
            margin: 0 0 0 60px;
          }

          @include m.sp {
            margin: 0 0 1.5rem;
          }
        }
      }

      &--layer {
        @extend .c-mediaWrap_box--flex;
        padding: 0 0 0 8%;

        @include m.sp {
          padding: 0;
        }

        & + .c-mediaWrap_pic {
          margin: 0 0 0 40px;

          @include m.sp {
            margin: 0 auto 2rem;
          }
        }
      }
    }

    &_head {
      width: 100%;
      margin-bottom: 24px;

      @include m.tab {
        margin-bottom: 16px;
      }

      @include m.sp {
        margin-bottom: 1rem;
      }
    }

    &_lead {
      padding: 0 0 4px;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.125;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }

      &--green {
        @extend .c-mediaWrap_lead;
        color: v.$green;
      }
    }

    &_heading {
      padding: 0 0 8px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.9166666666666667;
      letter-spacing: 0.06em;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.8rem;
        line-height: 1.5555555555555556;
      }

      &--white {
        @extend .c-mediaWrap_heading;
        color: v.$white;
      }
    }

    &_text {
      font-size: 16px;
      font-weight: 500;
      line-height: 2.125;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        font-size: 1.4rem;
        line-height: 1.5;
      }

      &--white {
        @extend .c-mediaWrap_text;
        color: v.$white;
      }
    }

    &_pic {
      $this: &;
      margin: 0 40px 0 0;

      @include m.sp {
        width: 100%;
        margin: 0 auto 2rem;
        order: 0;
      }

      & + .c-mediaWrap_box--flex {
        padding: 0 8% 0 0;

        @include m.sp {
          padding: 0;
        }
      }

      &--half {
        @extend #{$this};
        width: 49%;

        @include m.pc {
          max-width: 540px;
          margin: 0;
        }

        & + .c-mediaWrap_box--flex {
          margin: 0 0 0 80px;

          @include m.tab {
            margin: 0 0 0 40px;
          }

          @include m.sp {
            margin: 1.5rem 0 0;
          }
        }

        &--pc {
          @extend .c-mediaWrap_pic--half;

          @include m.sp {
            width: 100%;
          }
        }
      }

      &--w260 {
        @extend #{$this};
        width: 29%;

        @include m.pc {
          max-width: 260px;
        }

        @include m.sp {
          width: 60%;
        }
      }

      &--w315 {
        @extend #{$this};
        width: 29%;

        @include m.pc {
          max-width: 315px;
        }

        @include m.sp {
          width: 70%;
        }
      }

      &--w350 {
        @extend #{$this};
        width: 32%;

        @include m.pc {
          max-width: 350px;
        }

        @include m.sp {
          width: 72%;
        }
      }

      &--w428 {
        @extend #{$this};
        width: 100%;

        @include m.pc {
          max-width: 428px;
        }

        @include m.sp {
          width: 100%;
        }
      }

      &--w510 {
        @extend #{$this};
        width: 46%;

        @include m.pc {
          max-width: 510px;
        }
      }

      &--w540 {
        @extend #{$this};
        width: 49%;

        @include m.pc {
          max-width: 540px;
        }

        @include m.sp {
          width: 100%;
        }
      }

      &--w570 {
        @extend #{$this};
        width: 52%;

        @include m.pc {
          max-width: 570px;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }

    &_half {
      &Pic {
        width: 59%;

        @include m.pc {
          max-width: 540px;
          margin-right: 100px;
        }

        @include m.tab {
          margin-right: 40px;
        }

        @include m.sp {
          width: 100%;
          margin-bottom: 1rem;
          order: 0;
        }
      }

      &Box {
        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          width: 100%;
          order: 1;
        }

        & + .c-mediaWrap_halfPic {
          @include m.pc {
            margin-right: 0;
            margin-left: 100px;
          }

          @include m.tab {
            margin-right: 0;
            margin-left: 40px;
          }
        }
      }

      &Inner {
        width: 100%;
        margin: 0 auto;

        @include m.pc {
          max-width: 360px;
        }
      }
    }
  }
}
