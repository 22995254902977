@use "global/mixin" as m;
@use "global/variable" as v;

/* サイトポリシー・サイトマップページ用scssファイルです */

.p-site {
  /* サイトマップページ */
  &List {
    display: flex;
    justify-content: space-between;

    @include m.sp {
      display: block;
    }

    &_box {
      width: 47%;

      @include m.pc {
        max-width: 520px;
      }

      @include m.sp {
        width: 100%;
      }

      & + & {
        @include m.sp {
          margin-top: 4rem;
        }
      }
    }
  }

  &Inner {
    padding: 0 17%;

    @include m.sp {
      padding: 0 5rem;
    }
  }

  &Wrap {
    width: 100%;

    &_cont {
      width: 100%;
      display: flex;

      @include m.sp {
        display: block;
      }

      &Box {
        & + & {
          @include m.pc {
            margin-left: 80px;
          }

          @include m.sp {
            margin-top: 3rem;
          }
        }
      }
    }
  }
  /* end サイトマップページ */
}
