@use "global/mixin" as m;
@use "global/variable" as v;

.c-ruby {
  display: inline-block;
  line-height: 1;
  position: relative;
  z-index: 1;

  &:after {
    content: attr(data-ruby);
    display: block;
    font-size: 8px;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 100%;
    z-index: 2;
    transform: translateX(-50%) scale(0.6);
    white-space: nowrap;
  }
}
