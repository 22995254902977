@use "global/mixin" as m;
@use "global/variable" as v;

/* 学生生活ページ用scssファイルです */

.p-life {
  /* 学生生活サポート */
  &Support {
    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        display: block;
      }

      &Box {
        width: 100%;
        padding: 20px 40px;
        background-color: v.$white;
        border: 1px solid v.$green;

        @include m.pc {
          max-width: 540px;
        }

        @include m.tab {
          padding: 12px 28px;
        }

        @include m.sp {
          padding: 1rem 2rem;

          & + & {
            margin-top: 3rem;
          }
        }
      }
    }
  }
  /* end 学生生活サポート */

  /* 図書館 */
  &Btn {
    max-width: 350px;
    width: 100%;
    margin: 60px auto 0;

    @include m.tab {
      margin: 40px auto 0;
    }

    @include m.sp {
      margin: 3rem auto 0;
    }
  }
  /* end 図書館 */

  /* 芸短ライフ */
  &Style {
    width: 100%;

    &_wrap {
      width: 100%;
      margin: 0 auto;

      @include m.pc {
        max-width: 980px;
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      @include m.sp {
        display: block;
      }

      &Box {
        width: 46%;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: 445px;
        }

        @include m.sp {
          width: 100%;
        }

        &:nth-child(n + 3) {
          @include m.pc {
            margin-top: 60px;
          }
        }

        & + & {
          @include m.sp {
            margin-top: 4rem;
          }
        }
      }

      &Item {
        @include m.pc {
          &:hover {
            .p-lifeStyle {
              &_info {
                &Btn {
                  background-color: v.$white;

                  &Arrow {
                    background: url('../img/common/ico_arrow_black.svg') no-repeat center;
                    background-size: contain;
                  }

                  &Anchor {
                    background: url('../img/common/ico_arrow_anchor_black.svg') no-repeat center;
                    background-size: contain;
                  }
                }
              }
            }
          }
        }
      }

      &Pic {
        width: 100%;
        position: relative;
        z-index: 1;

        &Inner {
          width: 100%;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            width: 100%;
            height: 50%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
            opacity: 0.85;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
          }
        }
      }

      &Inner {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
      }
    }

    &_info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &Box {
        padding: 16px 20px;
        flex: 1;
      }

      &Course {
        padding-bottom: 8px;
        color: v.$white;
      }

      &Status {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &Head {
          width: 54px;
          margin-right: 16px;

          @include m.sp {
            width: 4rem;
            margin-right: 1.5rem;
          }
        }

        &Label {
          padding: 4px 0;
          text-align: center;
          line-height: 1;
          color: v.$white;
          background-color: v.$green;
        }

        &Body {
          flex: 1;
        }

        &Name {
          color: v.$white;
        }
      }

      &Btn {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: v.$blackPale;
        border: 2px solid v.$blackPale;

        @include m.pc {
          transition: background-color v.$anime;
        }

        @include m.sp {
          width: 6rem;
          height: 6rem;
          background-color: v.$white;
        }

        &Arrow {
          width: 28px;
          height: 9px;
          display: block;
          background: url('../img/common/ico_arrow_white.svg') no-repeat center;
          background-size: contain;

          @include m.pc {
            transition: background-image v.$anime;
          }

          @include m.sp {
            width: 2.5rem;
            height: 1rem;
            margin-right: -4px;
            background: url('../img/common/ico_arrow_black.svg') no-repeat center;
            background-size: contain;
          }
        }

        &Anchor {
          width: 12px;
          height: 6px;
          display: block;
          background: url('../img/common/ico_arrow_anchor_white.svg') no-repeat center;
          background-size: contain;

          @include m.pc {
            transition: background-image v.$anime;
          }

          @include m.sp {
            width: 2.5rem;
            height: 1rem;
            margin-right: -4px;
            background: url('../img/common/ico_arrow_anchor_black.svg') no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    &_table {
      thead {
        th {
          width: 16.5%;

          &:nth-child(1) {
            width: 17.5%;
          }
        }
      }

      tbody {
        th, td {
          height: 100px;
        }
      }
    }

    &Sec {
      width: 100%;
      padding: 120px 0 0;

      @include m.sp {
        padding: 6rem 0 0;
      }

      &_intro {
        width: 100%;
        margin: 0 0 210px;
        padding: 72px 0;

        @include m.sp {
          margin: 0 0 6rem;
          padding: 3rem 0;
        }

        &Cont {
          width: 100%;
          display: flex;

          &Person {
            $this: &;
            width: 50%;
            position: relative;
            z-index: 1;

            @include m.tab {
              width: 45%;
            }

            @include m.sp {
              width: 30%;
            }

            img {
              width: 100%;
              position: absolute;
              top: 55%;
              left: 50%;
              z-index: 2;
              transform: translate(-50%, -50%);

              @include m.pc {
                max-width: 220px;
              }

              @include m.sp {
                width: 13rem;
                top: 35%;
                left: 65%;
              }
            }

            &--media01 {
              @extend #{$this};

              img {
                @include m.pc {
                  max-width: 220px;
                  top: 62%;
                }

                @include m.sp {
                  width: 10rem;
                  top: 32%;
                }
              }
            }

            &--media02 {
              @extend #{$this};

              img {
                @include m.pc {
                  max-width: 400px;
                }

                @include m.sp {
                  width: 16rem;
                }
              }
            }

            &--design01 {
              @extend #{$this};

              img {
                @include m.pc {
                  max-width: 270px;
                }

                @include m.sp {
                  width: 14rem;
                  top: 20%;
                }
              }
            }

            &--design02 {
              @extend #{$this};

              img {
                @include m.pc {
                  max-width: 300px;
                }

                @include m.sp {
                  width: 14rem;
                  top: 28%;
                  left: 47%;
                }
              }
            }
          }

          &Box {
            width: 50%;
            padding: 0 56px;

            @include m.tab {
              width: 55%;
              padding: 0 28px;
            }

            @include m.sp {
              width: 70%;
              padding: 0 2rem;
            }

            & + .p-lifeStyleSec_introContPerson {
              img {
                @include m.sp {
                  left: 45%;
                }
              }
            }

            &--media02 {
              @extend .p-lifeStyleSec_introContBox;

              @include m.sp {
                width: 64.5%;
                padding: 0 1rem 0 0;
              }

              & + .p-lifeStyleSec_introContPerson {
                img {
                  @include m.sp {
                    left: 55%;
                    top: 25%;
                  }
                }
              }
            }
          }
        }

        &Head {
          width: 100%;
          margin-bottom: 20px;
        }

        &Text {
          color: v.$white;
        }

        &Info {
          width: 100%;
          padding: 12px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &Head {
            width: 54px;
            margin-right: 16px;

            @include m.sp {
              margin-right: 1rem;
            }
          }

          &Label {
            padding: 8px 0;
            text-align: center;
            line-height: 1;
            color: v.$green;
            background-color: v.$white;
          }

          &Body {
            flex: 1;

            &Inner {
              width: 100%;
            }
          }

          &Name {
            color: v.$white;
          }
        }

        &Heading {
          padding-bottom: 20px;
          color: v.$white;
        }

        &Body {
          &Inner {
            width: 100%;

            & + & {
              margin-top: 20px;
            }
          }
        }

        &Status {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include m.sp {
            display: block;
          }

          &Head {
            min-width: 90px;
            height: 25px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1;
            color: v.$white;
            border: 1px solid v.$white;
            border-radius: 100px;

            @include m.sp {
              min-width: 8rem;
              height: 2.2rem;
              margin: 0 0 0.7rem;
              display: inline-flex;
            }
          }

          &Body {
            line-height: 1;
            color: v.$white;

            @include m.pc {
              flex: 1;
            }
          }
        }
      }
    }

    &Faq {
      width: 100%;

      &_list {
        width: 100%;

        & + & {
          margin-top: 32px;
          padding-top: 36px;
          border-top: 1px solid v.$gray;

          @include m.sp {
            margin-top: 3rem;
            padding-top: 3rem;
          }
        }
      }

      &_info {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        & + & {
          margin-top: 12px;
        }

        &Head {
          width: 16px;
          margin-right: 14px;
          text-align: left;
        }

        &Body {
          flex: 1;
        }
      }
    }

    &Message {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @include m.pc {
        max-width: 920px;
      }

      @include m.sp {
        display: block;
      }

      &_pic {
        width: 32%;
        margin-right: 80px;

        @include m.pc {
          max-width: 320px;
        }

        @include m.tab {
          margin-right: 60px;
        }

        @include m.sp {
          width: 75%;
          margin: 0 auto 5rem;
        }
      }

      &_box {
        padding: 52px;
        flex: 1;
        background-color: v.$green;
        border-radius: 70px;
        position: relative;
        z-index: 1;

        @include m.tab {
          padding: 52px 40px;
        }

        @include m.sp {
          padding: 4rem;
        }

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 30px 10px 0;
          border-color: transparent v.$green transparent transparent;
          position: absolute;
          top: 50%;
          right: calc(100% - 1px);
          z-index: 2;
          transform: translateY(-50%);

          @include m.sp {
            border-width: 0 10px 30px 10px;
            border-color: transparent transparent v.$green transparent;
            top: auto;
            bottom: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    &Related {
      width: 100%;
      margin-top: 200px;

      @include m.tab {
        margin-top: 120px;
      }

      @include m.sp {
        margin-top: 6rem;
      }
    }
  }
  /* end 芸短ライフ */
}
