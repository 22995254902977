@use "global/mixin" as m;
@use "global/variable" as v;

.c-ico {
  &_tri {
    $this: &;
    width: 0;
    height: 0;
    display: inline-block;
    border-style: solid;
    border-width: 6px 0 6px 12px;

    @include m.sp {
      border-width: 0.5rem 0 0.5rem 1rem;
    }

    &--green {
      @extend #{$this};
      border-color: transparent transparent transparent v.$green;
    }
  }

  &_arrow {
    width: 30px;
    height: 12px;
    display: block;
    // background-color: v.$white;
    position: relative;
    z-index: 1;

    @include m.sp {
      width: 3rem;
      height: 1.2rem;
    }

    // &:after {
    //   content: '';
    //   width: 30%;
    //   height: 2px;
    //   background-color: v.$white;
    //   position: absolute;
    //   top: 1px;
    //   right: 0;
    //   z-index: 2;
    //   transform-origin: right;
    //   transform: rotate(45deg);

    //   @include m.sp {
    //     height: 1px;
    //   }
    // }

    &--black {
      @extend .c-ico_arrow;
      // background-color: v.$black;

      // &:after {
      //   background-color: v.$black;
      // }

      .c-ico_arrowInner {
        stroke: v.$black;
      }
    }
  }
}
