@use "global/mixin" as m;
@use "global/variable" as v;

.p-other {
  /* 404 */
  &Nf {
    width: 100%;
    margin: 64px 0 0;
    padding: 200px 0;
    text-align: center;
    background-color: rgba(v.$black, 0.7);
    position: relative;
    z-index: 2;

    @include m.tab {
      padding: 120px 0;
    }

    @include m.tab02 {
      margin: 5rem 0 0;
    }

    @include m.sp {
      padding: 5rem 0 7rem;
    }
  }
  /* end 404 */
}
