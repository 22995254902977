@use "global/mixin" as m;
@use "global/variable" as v;

/* 大学案内ページ用scssファイルです */

.p-guide {
  &Top {
    &_message {
      padding-bottom: 100px;
      @include m.sp {
        padding-bottom: 4rem;
      }
      &--mt35 {
        margin-top: 3rem;
        @include m.sp {
          margin-top: 2rem;
        }
      }

      &--text {
        line-height: 1.85;
      }
    }

    &_overview {
      &--scroll {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        @include m.tab {
          font-size: 20px;
        }
        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_campus {
      &--text {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 1.555;
        @include m.sp {
          font-size: 1.6rem;
        }
      }

      &--scroll {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        @include m.tab {
          font-size: 20px;
        }
        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_group {
      &--text {
        font-size: 18px;
        font-weight: bold;
        line-height: 2.111;
        text-align: center;
        @include m.sp {
          font-size: 1.6rem;
        }
      }

      &--scroll {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        @include m.tab {
          font-size: 20px;
        }
        @include m.sp {
          font-size: 1.4rem;
        }

        &02 {
          font-size: 18px;
          font-weight: bold;
          @include m.tab {
            font-size: 16px;
          }
          @include m.sp {
            font-size: 1.4rem;
          }
        }
      }
      &--mt40 {
        margin-top: 40px;
        @include m.sp {
          margin-top: 3rem;
        }
      }

      &--mt20 {
        margin-top: 20px;
        @include m.sp {
          margin-top: 1rem;
        }
      }

      &--preschool {
        .c-panelCard_external {
          width: 20px;
          height: 20px;
          @include m.sp {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }
    }

    &_financial {
      padding-bottom: 7rem;
      @include m.sp {
        padding-bottom: 3rem;
      }
    }
  }

  &Message {
    padding-bottom: 120px;
    @include m.tab {
      flex-flow: column;
    }
    @include m.sp {
      padding-bottom: 8rem;
    }

    .c-mediaWrap_box--layer {
      @include m.tab {
        width: 100%;
        padding: 0;
        order: 1;
      }
    }

    .c-layer--bottomRight {
      @include m.tab {
        margin: 3rem auto;
      }
    }

    &_borderBtm {
      $this: &;
      // padding-bottom: 2rem;
      margin-bottom: 2rem;
      position: relative;
      // &:after {
      //   content: '';
      //   width: 30px;
      //   height: 2px;
      //   background-color: v.$green;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      // }

      &--text {
        line-height: 1.85;
      }

      &--noBorder {
        @extend #{$this};
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        &:after {
          display: none;
        }
      }
    }

    &_text {
      line-height: 1.85;
    }
  }

  &Philosophy {
    &_about {
      &--text {
        line-height: 2.125;
        text-align: center;
        @include m.sp {
          text-align: left;
        }
      }
    }

    &_education {
      &--img {
        max-width: 530px;
        width: 100%;
        margin: 0 auto;
      }

      &List {
        &--item {
          &:not(:first-child) {
            margin-top: 50px;
            @include m.sp {
              margin-top: 3rem;
            }
          }
        }

        &--ttl {
          font-weight: bold;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #d8d6d6;
          @include m.sp {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
          }
        }

        &--text {
          line-height: 1.85;
        }
      }
    }

    &_purpose {
      &--text {
        line-height: 2.125;
        .tab {
          display: none;
          @include m.tab {
            display: block;
          }
        }
      }

      &Dept {
        &--item {
          &:not(:first-child) {
            margin-top: 50px;
            @include m.sp {
              margin-top: 3rem;
            }
          }
        }
        &--text {
          max-width: 790px;
          width: 100%;
          margin: 20px auto 0;
          line-height: 2.125;
        }
      }

      &Policy {
        &--ttl {
          margin-bottom: 40px;
          @include m.sp {
            margin-bottom: 3rem;
          }
        }

        &--mt {
          &20 {
            margin-top: 20px;
            @include m.sp {
              margin-top: 1rem;
            }
          }

          &40 {
            margin-top: 40px;
            @include m.sp {
              margin-top: 3rem;
            }
          }
        }

        &--list {
          li {
            text-indent: -0.6em;
            padding-left: 0.6em;
          }
        }

        &--cont {
          &Ttl {
            .c-ico_tri {
              margin-right: 10px;
            }
          }
          &Text {
            line-height: 1.85;
          }
        }
      }
    }
  }

  &History {
    padding-bottom: 120px;
    @include m.sp {
      padding-bottom: 6rem;
    }

    .c-infoLine_text {
      line-height: 1.85;
    }
  }

  &Campus {
    &_itami {
      padding-top: 0;
      &--pic {
        @include m.sp {
          width: 100%;
        }
      }
    }
    &_pickup {
      position: relative;
      &--pic {
        max-width: 420px;
        @include m.sp {
          width: 100%;
        }
      }

      &--text {
        padding: 0 !important;
      }

      &--ico {
        font-size: 18px;
        width: 130px;
        height: 130px;
        border: 2px solid v.$green;
        border-radius: 50%;
        text-align: center;
        line-height: 130px;
        display: block;
        position: absolute;
        top: -20px;
        left: -30px;
        @include m.tab {
          font-size: 16px;
          width: 100px;
          height: 100px;
          line-height: 100px;
          top: -15px;
          left: -15px;
        }
        @include m.sp {
          font-size: 1.4rem;
          width: 8rem;
          height: 8rem;
          line-height: 8rem;
          top: -1.5rem;
          left: -1rem;
        }
      }
    }

    &_facility {
      padding-top: 0;
      &--text {
        max-width: 380px;
        width: 100%;
        @include m.sp {
          max-width: none;
        }

        &Right {
          max-width: 380px;
          width: 100%;
          margin: 0 0 0 auto;
          @include m.sp {
            max-width: none;
            margin: 0;
          }
        }
      }

      &--pic {
        @include m.sp {
          width: 100%;
        }
      }

      &List {
        &--item {
          @include m.sp {
            display: block;
          }

          .c-textType02--medium {
            line-height: 1.85;
          }
        }
      }
    }
  }

  &Access {
    padding-bottom: 120px;
    @include m.sp {
      padding-bottom: 6rem;
    }
    &_location {
      max-width: 365px;
      width: 100%;
      margin: 0 0 0 auto;
      @include m.sp {
        max-width: none;
      }

      &--btn {
        margin: 60px 0 0;
        @include m.sp {
          margin-top: 4rem;
        }
      }

      &--map {
        @include m.sp {
          width: 100%;
          height: 0;
          overflow: hidden;
          padding-bottom: 66.666%;
          position: relative;
        }

        iframe {
          @include m.sp {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
          }
        }
      }

      .c-textType02--medium {
        line-height: 1.85;
      }
    }

    &_train {
      &List {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &--item {
          width: 50%;
          @include m.sp {
            width: 100%;
          }

          &:last-child {
            width: 100%;
            margin-top: 30px;
            @include m.sp {
              margin-top: 2rem;
            }
          }

          &:not(:first-child) {
            @include m.sp {
              margin-top: 2rem;
            }
          }

          .c-textType02--medium {
            line-height: 1.85;
          }
        }
      }
    }

    &_bus {
      &List {
        display: flex;
        align-items: center;
        @include m.sp {
          display: block;
        }
      }
      &--wrap {
        display: flex;
        align-items: center;
        @include m.sp {
          display: block;
        }

        & + .p-guideAccess_bus--wrap {
          margin-left: 60px;
          @include m.sp {
            margin: 2rem 0 0;
          }
        }
      }

      &--ttl {
        margin-right: 20px;
      }
    }
  }

  &Fin {
    &_local {
      &List {
        @include m.sp {
          display: block;
        }

        &--item {
          @include m.sp {
            width: 100%;
          }

          &:not(:first-child) {
            @include m.sp {
              margin-top: 1.5rem;
            }
          }
        }
      }
      &--btn {
        padding-right: 40px;
      }
    }

    &_sec {
      &--link {
        .c-textType01--medium {
          line-height: 2.125;
        }
      }
      .c-textType02--medium {
        line-height: 1.85;
      }
      &--ttl {
        text-indent: -1.2em;
        padding-left: 1.2em;
        line-height: 1.5555;
      }

      &--wrap {
        display: flex;

        > span {
          margin-right: 15px;
          @include m.sp {
            margin-right: 10px;
          }
        }

        p {
          flex: 1;
        }
      }

      &--list {
        padding-left: 1em;

        &--item {
          display: flex;
        }
      }

      .c-linkText {
        display: inline-block;

        &_ico {
          display: inline-block;
          @include m.sp {
            width: 10px;
            height: 10px;
          }
        }
      }

      &--btn {
        margin: 80px 0 0;
        @include m.sp {
          width: 100%;
          margin-top: 4rem;
        }
      }
    }

    &_eval {
      &--wrap {
        display: flex;
        justify-content: space-between;
        @include m.sp {
          display: block;
        }
        &Left {
          line-height: 1.85;
          max-width: 635px;
          width: 69%;
          @include m.sp {
            width: 100%;
          }
        }

        &Right {
          max-width: 226px;
          width: 24%;
          @include m.sp {
            width: 100%;
            margin: 0 auto;
          }
        }
      }

      &Btn {
        max-width: 795px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include m.sp {
          display: block;
        }
        &--item {
          max-width: 350px;
          width: 48%;
          @include m.sp {
            max-width: none;
            width: 100%;
          }

          &:not(:first-child) {
            @include m.sp {
              margin-top: 2em;
            }
          }
        }
      }

      .c-headingType05 {
        line-height: 1.555;
      }
    }
  }

  &--mt {
    &120 {
      margin-top: 120px;
      @include m.sp {
        margin-top: 6rem;
      }
    }
    &80 {
      margin-top: 80px;
      @include m.sp {
        margin-top: 4rem;
      }
    }
    &70 {
      margin-top: 70px;
      @include m.sp {
        margin-top: 3rem;
      }
    }

    &60 {
      margin-top: 60px;
      @include m.sp {
        margin-top: 3rem;
      }
    }

    &40 {
      margin-top: 40px;
      @include m.sp {
        margin-top: 3rem;
      }
    }

    &30 {
      margin-top: 30px;
      @include m.sp {
        margin-top: 2rem;
      }
    }

    &20 {
      margin-top: 20px;
      @include m.sp {
        margin-top: 1rem;
      }
    }

    &15 {
      margin-top: 15px;
      @include m.sp {
        margin-top: 1rem;
      }
    }

    &10 {
      margin-top: 10px;
      @include m.sp {
        margin-top: 0.5rem;
      }
    }

    &5 {
      margin-top: 5px;
    }
  }

  /* 教員一覧ページ用 */
  &Teacher {
    // 詳細ページサムネ
    &_thumb {
      width: 100%;
      margin: 0 auto;

      @include m.pc {
        max-width: 400px;
      }

      @include m.sp {
        width: 85%;
      }
    }
  }
  /* end 教員一覧ページ用 */
}
