@use "global/mixin" as m;
@use "global/variable" as v;

.c-list {
  &Wrap {
    $this: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include m.sp {
      &.swiper-wrapper {
        flex-wrap: nowrap;

        .c-listWrap_box--row03 {
          &:nth-child(n + 3) {
            @include m.sp {
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    &--pc {
      @extend #{$this};

      @include m.sp {
        display: block;

        .c-listWrap_box {
          &--row02 {
            width: 100%;

            & + .c-listWrap_box--row02 {
              margin-top: 3rem;
            }
          }
        }
      }
    }

    &--row03 {
      @extend #{$this};

      @include m.pc {
        &:after {
          content: '';
          width: 32%;
          display: block;
        }
      }

      &--pc {
        @extend .c-listWrap--row03;

        @include m.sp {
          display: block;
        }
      }

      &--full {
        @extend #{$this};

        @include m.sp {
          justify-content: center;
          flex-wrap: nowrap;
        }
      }
    }

    &--col03 {
      @extend #{$this};

      @include m.pc {
        &:after {
          content: '';
          width: 32%;
          display: block;
        }
      }

      @include m.sp {
        flex-flow: column;
      }
    }

    &--row04 {
      @extend #{$this};

      @include m.pc {
        &:before,
        &:after {
          content: '';
          width: 25%;
          display: block;
        }

        &:before {
          order: 1;
        }

        &:after {
          order: 2;
        }
      }

      &--pc {
        @extend .c-listWrap--row04;

        @include m.sp {
          display: block;
        }
      }

      &--end {
        @extend .c-listWrap--row04;

        @include m.pc {
          &:before {
            order: 0;
          }

          &:after {
            order: 1;
          }

          .c-listWrap_box {
            &--row04 {
              order: 2;
            }
          }
        }
      }
    }

    &--center {
      @extend #{$this};
      flex-wrap: nowrap;
      justify-content: center;

      @include m.sp {
        justify-content: space-between;
      }

      &Res {
        @extend #{$this};
        flex-wrap: nowrap;
        justify-content: center;

        .c-listWrap {
          &_box {
            &--row03 {
              & + .c-listWrap_box--row03 {
                @include m.pc {
                  margin-left: 90px;
                }

                @include m.tab {
                  margin-left: 60px;
                }

                @include m.sp {
                  margin-left: 2rem;
                }
              }
            }
          }
        }
      }

      .c-listWrap {
        &_box {
          &--row03 {
            & + .c-listWrap_box--row03 {
              @include m.pc {
                margin-left: 90px;
              }

              @include m.tab {
                margin-left: 60px;
              }

              @include m.sp {
                margin-left: 2rem;
              }
            }
          }
        }
      }
    }

    &_box {
      &--full {
        width: 100%;

        & + & {
          margin-top: 60px;

          @include m.sp {
            margin-top: 3rem;
          }
        }
      }

      &--row02 {
        $this: &;
        width: 49%;

        @include m.pc {
          max-width: 540px;

          &:nth-child(n + 3) {
            margin: 36px 0 0;
          }
        }

        @include m.sp {
          width: 47%;

          &:nth-child(n + 3) {
            margin: 3rem 0 0;
          }
        }

        &--sp {
          @extend .c-listWrap_box--row02;
          @include m.sp {
            width: 100%;
          }
        }

        figure {
          iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
          }
        }
      }

      &--row03 {
        $this: &;
        width: 32%;

        @include m.pc {
          max-width: 350px;

          &:nth-child(n + 4) {
            margin: 36px 0 0;
          }
        }

        @include m.sp {
          width: 47%;

          &:nth-child(n + 3) {
            margin: 3rem 0 0;
          }
        }

        &--pc {
          @extend #{$this};

          @include m.sp {
            width: 100%;

            & + .c-listWrap_box--row03--pc {
              margin-top: 3rem;
            }
          }
        }

        &--full {
          @extend #{$this};

          @include m.sp {
            width: 34%;

            &:nth-child(n + 3) {
              margin: 0;
            }
          }
        }
      }

      &--col03 {
        width: 32%;

        @include m.pc {
          max-width: 350px;

          &:nth-child(n + 4) {
            margin: 36px 0 0;
          }
        }

        @include m.sp {
          width: 100%;

          & + & {
            margin: 3rem 0 0;
          }
        }
      }

      &--row04 {
        width: 23%;

        @include m.pc {
          max-width: 255px;

          &:nth-child(n + 5) {
            margin-top: 36px;
          }

          & + & {
            margin-left: 2%;
          }
        }

        @include m.sp {
          width: 47%;

          &:nth-child(n + 3) {
            margin: 3rem 0 0;
          }
        }

        &--pc {
          @extend #{$this};

          @include m.sp {
            width: 100%;

            & + .c-listWrap_box--row04--pc {
              margin-top: 2rem;
            }
          }
        }
      }

      &--short {
        @extend .c-listWrap_box--row03;

        @include m.sp {
          width: 100%;
          display: block;
          order: 0;
        }

        & + .c-listWrap_box--long {
          @include m.pc {
            margin: 0 0 0 20px;
          }
          @include m.sp {
            margin: 2rem 0 0;
          }
        }
      }

      &--long {
        flex: 1;

        @include m.pc {
          margin: 0 20px 0 0;
        }

        @include m.sp {
          width: 100%;
          display: block;
          order: 1;
        }

        & + .c-listWrap_box--short {
          @include m.sp {
            margin: 0 0 2rem;
          }
        }
      }
    }
  }

  &Wide {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      display: block;
    }

    &_box {
      &--row03 {
        width: 32.5%;

        @include m.pc {
          max-width: 620px;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }
  }

  &Text {
    &_item {
      &--tri {
        width: 100%;

        & + & {
          margin: 8px 0 0;
        }
      }

      &--dots {
        .c-listText_text,
        .c-listText_text--small {
          &:before {
            content: '●';
            padding: 0 4px 0 0;
            display: inline-block;
          }
        }
      }
    }

    &_tri {
      margin: 0 24px 0 0;

      &--green {
        @extend .c-listText_tri;
        border-color: transparent transparent transparent v.$green;
      }

      @include m.sp {
        margin: 0 4px 0 -4px;
      }
    }

    &_text {
      text-indent: -0.6em;
      padding: 0 0 0 1.2em;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.111111111111111;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        font-size: 1.3rem;
        line-height: 1.8;
      }

      &--small {
        @extend .c-listText_text;
        font-size: 16px;
        font-weight: normal;
        line-height: 2.125;

        @include m.tab {
          font-size: 14px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }
      }

      &--green {
        @extend .c-listText_text;
        color: v.$green;
      }

      &--white {
        @extend .c-listText_text;
        color: v.$white;
      }
    }

    &_first {
      &--green {
        .c-listText_text,
        .c-listText_text--small {
          &:before {
            color: v.$green;
          }
        }
      }
    }
  }

  &Related {
    width: 100%;
    display: block;

    @include m.pc {
      &_pic {
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          @include m.opacity();
        }
      }

      .c-listRelated {
        &_box {
          transition: opacity v.$anime;
        }
      }

      &:hover {
        .c-listRelated {
          &_pic {
            &:after {
              opacity: 0.3;
            }
          }

          &_box {
            opacity: 0.7;
          }
        }
      }
    }

    &_pic {
      width: 100%;
      margin: 0 0 16px;

      @include m.sp {
        margin: 0 0 1rem;
      }
    }

    &_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &_text {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      color: v.$white;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }

    &_inner {
      margin: 0 0 0 20px;
    }
  }
}
