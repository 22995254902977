@use "global/mixin" as m;
@use "global/variable" as v;

.l-wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @include m.pc {
    max-width: 1150px;
    padding: 0 20px;
  }

  @include m.sp {
    width: 93%;
  }

  &--wide {
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 1720px;
      padding: 0 20px;
    }

    @include m.sp {
      width: 100%;
    }
  }

  &Inner {
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 960px;
      padding: 0 20px;
    }

    @include m.sp {
      width: 85%;
    }
  }

  &Movie {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;

    &_cont {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &_item {
      width: 100%;
      height: 100%;
      background-color: v.$black;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }
  }

  &Fixed {
    &Btn {
      width: 270px;
      height: 64px;
      position: fixed;
      right: 84px;
      bottom: 0;
      z-index: 100;

      @include m.sp {
        height: 5.5rem;
        right: 8rem;
      }

      &_item {
        height: 100%;
      }
    }
  }
}
