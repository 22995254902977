@use "global/mixin" as m;
@use "global/variable" as v;

/* Informationページ用scssファイルです */

.p-news {
  padding-bottom: 80px;
  @include m.sp {
    padding-bottom: 6rem;
  }
  &_tab {
    li {
      color: v.$white;
      background-color: #d8d6d6;
    }

    &Wrap {
    }
  }

  &_list {
    li {
      border-bottom: 1px solid #d8d6d6;
      &:first-child {
        border-top: 1px solid #d8d6d6;
      }

      a {
        color: #303833;
        width: 100%;
        min-height: 84px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        @include m.sp {
          min-height: 0;
          padding: 0 0 1rem;
        }
      }

      & + li {
        margin-top: 0 !important;
      }
    }

    .c-tag--green {
      color: v.$white;
      background-color: v.$green;
    }
  }

  &_select {
    margin: 60px 0;
    @include m.sp {
      margin: 4rem 0;
    }

    &Box {
      width: 160px;
      height: 30px;
      margin: 0 0 0 auto;
      position: relative;
      @include m.sp {
        width: 16rem;
        height: 3rem;
      }
      &:after {
        content: '';
        width: 9.4px;
        height: 6.1px;
        margin: auto 0;
        background: url('../img/news/arrow_index01.svg') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        @include m.sp {
          width: 0.94rem;
          height: 0.61rem;
          right: 2rem;
        }
      }
    }

    select {
      color: v.$green;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      padding: 0 25px;
      background-color: v.$white;
      border: 1px solid v.$green;
      border-radius: 0;
      display: block;
      line-height: 30px;
      appearance: none;

      @include m.pc {
        cursor: pointer;
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.sp {
        font-size: 1.4rem;
        padding: 0 2.5rem;
        line-height: 3rem;
      }
      &::-ms-expand {
        display: none;
      }
    }
  }

  &_pagenation {
    margin-top: 60px;
    @include m.sp {
      margin-top: 4rem;
    }

    &List {
      display: flex;
      align-items: center;
      justify-content: center;

      &--item {
        &:not(:first-child) {
          margin-left: 15px;
          @include m.sp {
            margin-left: 1.5rem;
          }
        }
        span,
        a {
          color: v.$white;
          font-size: 16px;
          font-weight: bold;
          width: 30px;
          height: 30px;
          background-color: v.$green;
          display: block;
          text-align: center;
          line-height: 30px;

          @include m.sp {
            font-size: 1.6rem;
            width: 3rem;
            height: 3rem;
            line-height: 3rem;
          }
        }

        a {
          opacity: 0.5;
        }

        .omit {
          color: #303833;
          background-color: transparent;
        }

        .arrow {
          height: auto;
          background-color: transparent;
          opacity: 1;

          &.prev {
            transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
            -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
          }
        }
      }
    }
  }

  &_hero {
    width: 100%;
    position: relative;
    z-index: 1;

    &--inner {
      margin-top: 160px;
    }

    &--date {
      letter-spacing: 0.2em;
    }

    &--ttl {
      margin: 10px 0 0;
    }
  }

  &_breadcrumbs {
    flex-wrap: wrap;
  }

  &_category {
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    @include m.sp {
      margin-bottom: 5rem;
    }
    &--item {
      color: v.$white;
      font-size: 12px;
      font-weight: bold;
      width: 160px;
      height: 28px;
      background-color: v.$green;
      text-align: center;
      line-height: 28px;
      @include m.sp {
        font-size: 1.2rem;
        width: 32%;
        height: 2.8rem;
        line-height: 2.8rem;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:not(:nth-child(3n)) {
        @include m.sp {
          margin-right: 2%;
        }
      }
    }
  }

  &_detail {
    h2 {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 30px;
      position: relative;
      @include m.sp {
        font-size: 2rem;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
      }

      &:after {
        content: '';
        width: 100%;
        height: 6px;
        background: linear-gradient(to right, #39ba70 0%, #8fdb5f 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        transform: skew(30deg);
        @include m.sp {
          height: 0.6rem;
        }
      }
    }

    h3 {
      margin-top: 80px;
      font-size: 21px;
      font-weight: bold;
      padding-left: 20px;
      margin-bottom: 30px;
      border-left: 5px solid v.$green;
      @include m.sp {
        margin-top: 4rem;
        font-size: 1.8rem;
        padding-left: 1rem;
        margin-bottom: 2rem;
        border-width: 0.3rem;
      }
    }

    h4 {
      margin-top: 80px;
      color: v.$green;
      font-size: 18px;
      font-weight: bold;
      padding-left: 30px;
      margin-bottom: 20px;
      position: relative;
      @include m.sp {
        margin-top: 4rem;
        font-size: 1.6rem;
        padding-left: 2rem;
        margin-bottom: 1rem;
      }
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0 7.5px 15px;
        border-color: transparent transparent transparent v.$green;
        position: absolute;
        top: 6px;
        left: 7px;
        @include m.sp {
          border-width: 0.5rem 0 0.5rem 1rem;
          top: 0.7rem;
          left: 0.5rem;
        }
      }
    }

    .column02 {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      @include m.sp {
        margin-bottom: 3rem;
        display: block;
      }

      li {
        width: 48%;
        @include m.sp {
          width: 100%;
        }

        &:not(:nth-child(-n + 2)) {
          margin-top: 20px;
        }

        &:not(:first-child) {
          @include m.sp {
            margin-top: 1rem;
          }
        }

        h4 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
          @include m.sp {
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }
        }
      }

      h2,
      h3,
      h4 {
        margin-top: 0;

        @include m.sp {
          margin-top: 0;
        }
      }

      p {
        padding-bottom: 0;

        @include m.sp {
          padding-bottom: 0;
        }
      }
    }

    .box {
      &_type01 {
        margin-bottom: 40px;
        padding: 50px 5.4%;
        background: linear-gradient(to right, #39ba70 0%, #8fdb5f 100%);
        @include m.sp {
          margin-bottom: 3rem;
          padding: 3rem 1.5rem;
        }

        p {
          padding-bottom: 0;

          @include m.sp {
            padding-bottom: 0;
          }
        }

        h2,
        h3,
        h4 {
          margin-top: 0;

          @include m.sp {
            margin-top: 0;
          }
        }

        h4 {
          color: v.$white;
          font-size: 18px;
          font-weight: bold;
          padding-left: 50px;
          margin-bottom: 25px;
          position: relative;
          @include m.sp {
            font-size: 1.6rem;
            padding-left: 3rem;
          }
          &:before {
            content: '';
            width: 30px;
            height: 30px;
            background: url('../img/news/ico_check01.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include m.sp {
              width: 2rem;
              height: 2rem;
            }
          }
        }

        p {
          color: v.$white;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.85;
        }
      }
    }

    .youtube {
      position: relative;
      width: 100%;

      iframe {
        width: 100%;
        height: 520px;
        margin-bottom: 40px;

        @include m.sp {
          height: 32rem;
          margin-bottom: 3rem;
        }
      }
    }

    table {
      margin-bottom: 60px;

      @include m.sp {
        margin-bottom: 3rem;
      }

      tr {
        border-bottom: 1px solid #d8d6d6;

        &:first-child {
          border-top: 1px solid #d8d6d6;
        }
      }

      th,
      td {
        padding: 25px;
        vertical-align: middle;
        @include m.sp {
          padding: 1.5rem;
        }
      }

      th {
        color: v.$green;
        font-size: 16px;
        font-weight: bold;
        width: 27%;
        background-color: rgba(v.$green, 0.05);
        @include m.sp {
          font-size: 1.4rem;
        }
      }

      td {
        font-size: 14px;
        font-weight: 500;
        width: 73%;
        line-height: 1.85;
        @include m.sp {
          font-size: 1.2rem;
        }
      }
    }

    .balloon {
      max-width: 730px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;

      @include m.sp {
        margin: 0 auto 3rem;
        display: block;
      }

      &--person {
        width: 23%;
        @include m.sp {
          width: 100%;
          margin-top: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          margin-bottom: 0;
          @include m.sp {
            margin-bottom: 0;
            width: 9rem;
            margin-right: 2rem;
          }
        }

        p {
          padding-bottom: 0;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          margin-top: 20px;
          @include m.sp {
            padding-bottom: 0;
            font-size: 1.4rem;
            margin-top: 1rem;
          }
        }
      }

      &--text {
        color: v.$white;
        font-weight: bold;
        width: 64%;
        padding: 30px 4%;
        background: linear-gradient(to right, #39ba70 0%, #8fdb5f 100%);
        position: relative;

        @include m.sp {
          width: 100%;
          padding: 2rem 1.5rem;
        }
        &:before {
          content: '';
          width: 0;
          height: 0;
          margin: auto 0;
          border-style: solid;
          border-width: 0 0 40px 40px;
          border-color: transparent transparent v.$green transparent;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -40px;
          @include m.sp {
            border-width: 0 2rem 2rem 0;
            border-color: transparent v.$green transparent transparent;
            position: absolute;
            top: auto;
            bottom: -2rem;
            left: 5rem;
          }
        }

        p {
          padding-bottom: 0;
          font-size: 16px;
          line-height: 2.125;
          @include m.sp {
            padding-bottom: 0;
            font-size: 1.4rem;
          }
        }
      }

      &--reverse {
        max-width: 730px;
        width: 100%;
        margin: 0 auto 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include m.sp {
          margin: 0 auto 3rem;
          display: block;
        }

        .balloon--text {
          &:before {
            border-width: 40px 0 0 40px;
            border-color: transparent transparent transparent #8fdb5f;
            left: auto;
            right: -40px;
            @include m.sp {
              border-width: 2rem 2rem 0 0;
              border-color: #8fdb5f transparent transparent transparent;
              right: 5rem;
            }
          }
        }
      }
    }

    img {
      margin-bottom: 30px;

      @include m.sp {
        margin-bottom: 2rem;
      }
    }

    p {
      padding-bottom: 30px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.85;

      @include m.sp {
        padding-bottom: 2rem;
      }

      img {
        max-width: 100%;
        margin-bottom: 0;
      }

      a {
        color: v.$green;
        text-decoration: underline;
      }
    }

    a,
    a.c-btnType01 {
      p {
        padding-bottom: 0;

        @include m.sp {
          padding-bottom: 0;
        }
      }
    }

    figure {
      margin-bottom: 30px;

      @include m.sp {
        margin-bottom: 2rem;
      }

      img {
        max-width: 100%;
        margin-bottom: 10px;

        @include m.sp {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
