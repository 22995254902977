@use "global/mixin" as m;
@use "global/variable" as v;

.c-tab {
  &Nav {
    width: 100%;
    margin: 0 0 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.sp {
      margin: 0 0 4rem;
      flex-wrap: wrap;
    }

    &_list {
      width: 15%;
      padding: 16px 0;
      display: block;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: v.$green;
      background-color: v.$white;

      @include m.pc {
        max-width: 160px;
        cursor: pointer;
        transition: color v.$anime, background-color v.$anime;

        &:hover {
          color: v.$white;
          background-color: v.$green;
        }
      }

      @include m.sp {
        width: 31%;

        &:nth-child(n + 4) {
          margin: 1rem 0 0;
        }
      }

      @include m.tab {
        font-size: 11px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }

      &.is-selected {
        color: v.$white;
        background-color: v.$green;
      }
    }
  }

  &Wrap {
    width: 100%;
  }

  &Cont {
    width: 100%;
    display: none;

    &.is-view {
      display: block;
    }
  }
}
