@use "global/mixin" as m;
@use "global/variable" as v;

.c-text {
  &Type01 {
    $this: &;
    font-size: 16px;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.4rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &Type02 {
    $this: &;
    font-size: 14px;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 1.2rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &Type03 {
    $this: &;
    font-size: 12px;

    @include m.tab {
      font-size: 11px;
    }

    @include m.sp {
      font-size: 1rem;
    }

    &--medium {
      @extend #{$this};
      font-weight: 500;
    }

    &--bold {
      @extend #{$this};
      font-weight: bold;
    }
  }

  &LineThrough {
    text-decoration: line-through;
  }

  &Symbol {
    width: 100%;
    display: flex;
    align-items: flex-start;

    &_head {
      width: 40px;
    }

    &_body {
      flex: 1;
    }
  }
}
