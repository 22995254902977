@use "global/mixin" as m;
@use "global/variable" as v;

.c-profile {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include m.pc {
    min-height: 120px;
  }

  @include m.sp {
    align-items: center;
  }

  &_pic {
    width: 90px;
    margin-right: 20px;

    @include m.sp {
      width: 25%;
      margin-right: 2rem;
    }
  }

  &_box {
    flex: 1;
  }

  &_lead {
    padding-bottom: 8px;
  }

  &Wrap {
  }
}
