@use "global/mixin" as m;
@use "global/variable" as v;

.u-flex {
  display: flex;

  /* justify-content */
  &Jc {
    &--start {
      justify-content: flex-start;

      &--pc {
        @include m.pc {
          justify-content: flex-start;
        }
      }

      &--sp {
        @include m.sp {
          justify-content: flex-start;
        }
      }
    }

    &--center {
      justify-content: center;

      &--pc {
        @include m.pc {
          justify-content: center;
        }
      }

      &--sp {
        @include m.sp {
          justify-content: center;
        }
      }
    }

    &--end {
      justify-content: flex-end;

      &--pc {
        @include m.pc {
          justify-content: flex-end;
        }
      }

      &--sp {
        @include m.sp {
          justify-content: flex-end;
        }
      }
    }
  }
  /* end justify-content */

  /* align-items */
  &Ai {
    &--start {
      align-items: flex-start;

      &--pc {
        @include m.pc {
          align-items: flex-start;
        }
      }

      &--sp {
        @include m.sp {
          align-items: flex-start;
        }
      }
    }

    &--center {
      align-items: center;

      &--pc {
        @include m.pc {
          align-items: center;
        }
      }

      &--sp {
        @include m.sp {
          align-items: center;
        }
      }
    }

    &--end {
      align-items: flex-end;

      &--pc {
        @include m.pc {
          align-items: flex-end;
        }
      }

      &--sp {
        @include m.sp {
          align-items: flex-end;
        }
      }
    }
  }
  /* end align-items */
}
