@use "global/mixin" as m;
@use "global/variable" as v;

.c-catch {
  width: 100%;
  min-height: 780px;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    width: 0;
    height: 100%;
    background: v.$gradGreen;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s v.$animeBezierSingle02;
  }

  @include m.sp {
    min-height: 38rem;
  }

  &--oc {
    @extend .c-catch;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &:after {
      width: 100%;
      background: none;
      background-color: rgba(v.$black, 0.7);
      opacity: 1;
      transition: none;
    }
  }

  &.is-view {
    &:after {
      width: 100%;
    }

    .c-catch {
      &_heading {
        &--inner {
          -webkit-clip-path: inset(0);
          clip-path: inset(0);
        }
      }

      &_lead {
        opacity: 1;
      }

      &Pannel {
        &_list {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &--end {
    @extend .c-catch;
    display: flex;
    align-items: flex-end;
  }

  &--main {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      width: 100%;
      height: 201px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 31%, rgba(0, 0, 0, 0.6) 100%);
      // opacity: 0.7;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;

      @include m.sp {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 31%, rgba(0, 0, 0, 0.6) 100%);
      }
    }
  }

  &--opacity {
    @extend .c-catch;
  }

  &_cont {
    width: 100%;
    min-height: 625px;
    position: relative;
    z-index: 1;

    @include m.sp {
      min-height: 38rem;
    }

    .c-catch_lead {
      top: 66%;
    }
  }

  &_heading {
    width: 100%;
    height: 165px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    transition: -webkit-clip-path 0.6s v.$animeBezierSingle 0.6s;
    transition: clip-path 0.6s v.$animeBezierSingle 0.6s;
    transition: clip-path 0.6s v.$animeBezierSingle 0.6s, -webkit-clip-path 0.6s v.$animeBezierSingle 0.6s;

    @include m.tab {
      max-height: 165px;
      height: 14.5vw;
    }

    @include m.sp {
      height: 22rem;
    }

    img {
      width: auto;
      height: 100%;
      margin: 0 auto;
      display: block;
    }

    &--inner {
      @extend .c-catch_heading;
      top: 40%;
      transition: -webkit-clip-path 0.6s v.$animeBezierSingle 0.1s;
      transition: clip-path 0.6s v.$animeBezierSingle 0.1s;
      transition: clip-path 0.6s v.$animeBezierSingle 0.1s, -webkit-clip-path 0.6s v.$animeBezierSingle 0.1s;
      -webkit-clip-path: inset(0 100% 0 0);
      clip-path: inset(0 100% 0 0);

      @include m.sp {
        height: 6rem;
      }
    }
  }

  &_menu {
    width: 286px;
    position: absolute;
    top: 80%;
    right: 60px;
    z-index: 3;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity v.$animeBezier02 1.6s;

    @include m.sp {
      width: 75%;
      right: 1.5rem;
    }

    &.is-view {
      opacity: 1;
    }
  }

  &_scroll {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity v.$animeBezier02 1.6s;

    @include m.pc {
      max-width: 114px;
    }

    @include m.sp {
      width: 32%;
      opacity: 1;
    }

    &.is-view {
      opacity: 1;
    }
  }

  &_lead {
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.5555555555555556;
    letter-spacing: 0.1em;
    text-align: center;
    color: v.$white;
    position: absolute;
    top: 60%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity v.$anime 0.8s;

    @include m.tab {
      font-size: 30px;
    }

    @include m.sp {
      font-size: 1.8rem;
      top: 65%;
    }

    &--oc {
      width: 100%;
      height: 146px;

      @include m.tab {
        height: 120px;
      }

      @include m.sp {
        height: 6rem;
      }

      img {
        width: auto;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  &Pannel {
    width: 100%;
    padding: 0 0 200px;

    @include m.sp {
      padding: 0 0 5rem;
    }

    &_list {
      opacity: 0;
      transform: translateY(10px);

      &:nth-of-type(1) {
        transition: opacity v.$anime 1s, transform v.$anime 1s;
      }

      &:nth-of-type(2) {
        transition: opacity v.$anime 1.2s, transform v.$anime 1.2s;
      }

      &:nth-of-type(3) {
        transition: opacity v.$anime 1.4s, transform v.$anime 1.4s;
      }
    }
  }
}
