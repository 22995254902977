@use "global/mixin" as m;
@use "global/variable" as v;

.c-card {
  width: 100%;
  display: block;

  &_item {
    width: 100%;
    display: block;
    color: v.$white;

    @include m.pc {
      cursor: pointer;

      .c-card {
        &_pic {
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            @include m.opacity();
          }

          &Ico {
            &:before,
            &:after {
              transition: opacity v.$anime;
            }
          }
        }

        &_lead,
        &_text {
          transition: opacity v.$anime;
        }
      }
    }

    &:hover {
      .c-card {
        &_pic {
          &:after {
            opacity: 0.3;
          }

          &Ico {
            &:before {
              opacity: 0;
            }

            &:after {
              opacity: 1;
            }
          }
        }

        &_lead,
        &_text {
          opacity: 0.7;
        }
      }
    }
  }

  &_pic {
    $this: &;
    width: 100%;
    margin: 0 0 12px;
    position: relative;
    z-index: 1;

    iframe,
    video {
      @include m.sp {
        width: 100%;
        height: auto !important;
      }
    }

    &--aspect {
      @extend #{$this};
      aspect-ratio: 16/9;

      iframe {
        height: 100% !important;
        object-fit: cover;
      }

      &Info {
        aspect-ratio: auto 350/240;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--height {
      &Type {
        &01 {
          @extend #{$this};

          @include m.pc {
            height: 25vw;
            max-height: 283px;

            img {
              width: auto;
              height: 100%;
              object-fit: contain;
              margin: 0 auto;
            }
          }
        }
      }
    }

    img {
      // @include m.pc {
      //   max-height: 320px;
      //   height: 100vh;
      //   -o-object-fit: cover;
      //   object-fit: cover;
      // }
    }

    &Ico {
      $this: &;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;

      @include m.sp {
        width: 3.5rem;
        height: 3.5rem;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        background-color: v.$blackPale;
        z-index: -1;
      }

      &:after {
        background: v.$gradGreen;
        z-index: -2;
        opacity: 0;
      }

      &--modal {
        @extend #{$this};
      }

      &Inner {
        width: 30px;

        @include m.sp {
          width: 2rem;
        }

        img {
          @include m.pc {
            -o-object-fit: none;
            object-fit: none;
          }
        }
      }
    }
  }

  &_lead {
    padding: 0 0 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &_label {
    $this: &;
    margin: 4px 0 12px;
    padding: 8px 16px;
    display: inline-block;
    text-align: center;
    line-height: 1;
    border: 1px solid;

    @include m.sp {
      width: 100%;
      padding: 0.8rem 0.5rem;
    }

    &--green {
      @extend #{$this};
      color: v.$green;
      border-color: v.$green;
    }
  }

  &_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5714285714285714;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &_inner {
    & + & {
      margin-top: 8px;
    }
  }

  &_clamp {
    &--line02 {
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // overflow: hidden;
    }
    &--line03 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
