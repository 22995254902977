@use "global/mixin" as m;
@use "global/variable" as v;

.u-color {
  &_bg {
    &--black {
      background-color: v.$black;
    }

    &--white {
      background-color: v.$white;

      &Pale {
        background-color: rgba(v.$white, 0.95);
      }
    }

    &--green {
      background-color: v.$green;

      &Grad {
        background: v.$gradGreen;

        &Pale {
          background: v.$gradGreenPale;
        }
      }

      &Pale01 {
        background-color: v.$greenPale;
      }

      &Pale02 {
        background-color: v.$greenPale02;
      }

      &Pale03 {
        background-color: v.$greenPale03;
      }
    }

    &--gray {
      &Pale {
        background-color: v.$grayPale;
      }

      &Deep {
        background-color: v.$grayDeep;
      }

      &Deep02 {
        background-color: v.$grayDeep02;
      }
    }

    &--pink {
      &Deep {
        background-color: v.$pinkDeep;
      }
    }
  }

  &_text {
    &--black {
      color: v.$black;
    }

    &--white {
      color: v.$white;
    }

    &--green {
      color: v.$green;
    }
  }

  &_border {
    &--white {
      border-color: v.$white !important;
    }

    &--black {
      border-color: v.$black !important;
    }

    &--gray {
      border-color: v.$gray !important;
    }
  }
}
