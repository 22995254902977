@use "global/mixin" as m;
@use "global/variable" as v;

.c-box {
  &Type01 {
    width: 100%;
    padding: 40px;

    @include m.tab {
      padding: 28px;
    }

    @include m.sp {
      padding: 2rem;
    }
  }

  &Type02 {
    width: 100%;
    padding: 24px;

    @include m.tab {
      padding: 16px;
    }

    @include m.sp {
      padding: 1.4rem;
    }
  }

  &Type03 {
    width: 100%;
    padding: 20px 40px;

    @include m.tab {
      padding: 12px 28px;
    }

    @include m.sp {
      padding: 1rem 2rem;
    }
  }

  &Type04 {
    width: 100%;
    padding: 60px;

    @include m.tab {
      padding: 36px;
    }

    @include m.sp {
      padding: 2rem;
    }
  }

  &Type05 {
    width: 100%;
    padding: 12px 24px;

    @include m.tab {
      padding: 8px 16px;
    }

    @include m.sp {
      padding: 1rem 1.4rem;
    }
  }
}
