@use "global/mixin" as m;
@use "global/variable" as v;

.c-tile {
  &Wrap {
    $this: &;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &--half {
      @extend #{$this};
      width: 75%;
      margin: 0 auto;

      @include m.sp {
        width: 100%;
      }

      &--pc {
        @extend .c-tileWrap--half;

        @include m.sp {
          width: 100%;
        }
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: v.$black;

      @include m.sp {
        flex-flow: column;
      }

      &.is-view {
        .c-tileWrap {
          &_pic,
          &_box {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }

    &_pic {
      width: 55%;
      opacity: 0;
      transform: translateX(-50px);
      transition: opacity 1s ease, transform 1s ease;
      position: relative;
      z-index: 2;

      @include m.pc {
        max-width: 1040px;
      }

      @include m.sp {
        width: 100%;
        order: 0;
      }

      & + .c-tileWrap_box {
        justify-content: flex-start;
        transform: translateX(-25px);
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &_box {
      padding: 5% 6%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      z-index: 1;
      overflow: hidden;
      opacity: 0;
      transform: translateX(25px);
      transition: opacity 1s ease 0.3s, transform 1s ease 0.3s;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
        padding: 3rem;
        order: 1;
      }

      & + .c-tileWrap_pic {
        transform: translateX(50px);
      }

      &--short {
        width: 41%;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: 785px;
        }

        @include m.sp {
          width: 100%;
          height: 25rem;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &--long {
        width: 60%;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: 1135px;
        }

        @include m.sp {
          width: 100%;
          height: 25rem;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &--half {
        width: 50%;
        position: relative;
        z-index: 1;

        @include m.sp {
          width: 100%;
          height: 25rem;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &--row03 {
        width: 34%;
        position: relative;
        z-index: 1;

        @include m.sp {
          width: 100%;
          height: 25rem;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_inner {
      width: 315px;
    }

    &_heading {
      width: 100%;
      padding: 0 0 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.06em;
      color: v.$white;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.8rem;
      }
    }

    &_text {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8571428571428572;
      color: v.$white;
    }

    &_bg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -2;
      transform: translate(-50%, -50%);

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(v.$black, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        width: 120%;
        height: 120%;
        object-fit: cover;
      }
    }

    &_grad {
      width: 100%;
      height: 100%;
      padding: 0 44px 32px;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-start;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 150%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      @include m.sp {
        padding: 0 2rem 1.5rem;
      }

      &Lead {
        width: 100%;
        padding: 0 0 4px;
        font-size: 18px;
        font-weight: bold;
        line-height: 2.125;
        color: v.$white;

        @include m.sp {
          padding: 0;
          font-size: 1.6rem;
        }
      }

      &Text {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.8571428571428572;
        color: v.$white;
      }
    }
  }
}
