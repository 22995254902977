@use "global/mixin" as m;
@use "global/variable" as v;

.c-menu {
  width: 100%;
  transform: skew(30deg);

  &_list {
    width: 100%;
  }

  &_item {
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: v.$white;

    @include m.pc {
      transition: background-color v.$anime;

      .c-menu {
        &_ico {
          transition: opacity v.$anime;
        }

        &_text {
        }
      }

      &:hover {
        background-color: v.$green;

        .c-menu {
          &_ico {
            opacity: 1;
          }

          &_text {
            &--full {
              background: url('../img/common/text_full_movie_white.svg') no-repeat left center;
              background-size: 160px 9px;
            }

            &--making {
              background: url('../img/common/text_making_movie_white.svg') no-repeat left center;
              background-size: 190px 9px;
            }
          }
        }
      }
    }

    @include m.sp {
      background-color: v.$green;

      .c-menu {
        &_ico {
          opacity: 1;
        }

        &_text {
          &Item {
            fill: v.$white;
          }
        }
      }
    }
  }

  &_ico,
  &_text {
    margin: 0 6px 0 0;
    display: block;
    border-color: transparent transparent transparent v.$white;
    transform: skew(-30deg);
  }

  &_ico {
    opacity: 0;
  }

  &_text {
    $this: &;
    height: 9px;
    display: flex;
    align-items: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @include m.pc {
      transition: background-image v.$anime;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &--full {
      @extend #{$this};
      width: 160px;
      background: url('../img/common/text_full_movie_green.svg') no-repeat left center;
      background-size: contain;

      @include m.sp {
        background: url('../img/common/text_full_movie_white.svg') no-repeat left center;
        background-size: contain;
      }
    }

    &--making {
      @extend #{$this};
      width: 190px;
      background: url('../img/common/text_making_movie_green.svg') no-repeat left center;
      background-size: contain;

      @include m.sp {
        background: url('../img/common/text_making_movie_white.svg') no-repeat left center;
        background-size: contain;
      }
    }
  }
}
