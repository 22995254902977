@use "global/variable" as v;

@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab($max: 1150px) {
  @media screen and (min-width: 768px) and (max-width: $max) {
    @content;
  }
}

@mixin tab02($width: 1150px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin opacity() {
  width: 100%;
  height: 100%;
  display: block;
  background-color: v.$white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity v.$anime;
}
