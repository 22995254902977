@use "global/mixin" as m;
@use "global/variable" as v;

.c-hero {
  width: 100%;
  margin: 0 0 120px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @include m.sp {
    margin: 0 0 6rem;
  }

  &_main {
    position: relative;
    z-index: 1;
  }

  &_cont {
    width: 100%;
    height: 17vw;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    @include m.pc {
      max-height: 320px;
      min-height: 200px;
    }

    @include m.sp {
      height: 16rem;
    }

    .c-hero {
      &_intro,
      &_box {
        transition: width 0.4s v.$animeBezierSingle;
      }

      &_heading,
      &_text {
        opacity: 0;
        transition: opacity v.$anime 0.6s;
      }
    }

    &.is-view {
      .c-hero {
        &_intro {
          width: 68%;

          @include m.sp {
            width: 100%;
          }
        }

        &_box {
          width: 60%;

          @include m.sp {
            width: 100%;
          }
        }

        &_heading,
        &_text {
          opacity: 1;
        }
      }
    }
  }

  &_intro {
    width: 0;
    height: 50%;
    position: absolute;
    z-index: 1;

    @include m.pc {
      max-width: 1295px;
    }

    @include m.sp {
      width: 100%;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: v.$gradGreen;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: skew(30deg);
    }

    &--left {
      @extend .c-hero_intro;
      padding: 0 80px 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: 0;
      left: -50px;

      @include m.sp {
        padding: 0 4rem 0 0;
        left: -5rem;
      }
    }

    &--right {
      @extend .c-hero_intro;
      padding: 0 0 0 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      top: 0;
      right: -50px;

      @include m.sp {
        padding: 0 0 0 10.5rem;
        justify-content: flex-start;
        left: -5rem;
      }
    }
  }

  &_heading {
    width: auto;
    height: 5vw;

    @include m.pc {
      max-height: 80px;
      min-height: 40px;
    }

    @include m.sp {
      height: 2.4rem;
    }

    img {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }

  &_box {
    width: 0;
    height: 50%;
    position: absolute;
    z-index: 1;

    @include m.pc {
      max-width: 1100px;
    }

    @include m.sp {
      width: 100%;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: v.$white;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: skew(30deg);
    }

    &--right {
      @extend .c-hero_box;
      padding: 0 0 0 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      bottom: 0;
      right: -40px;

      @include m.sp {
        padding: 0 0 0 3rem;
        right: -2.5rem;
      }
    }

    &--left {
      @extend .c-hero_box;
      padding: 0 80px 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      bottom: 0;
      left: -40px;

      @include m.sp {
        padding: 0 2rem 0 3rem;
        justify-content: flex-start;
        right: -2.5rem;
        left: auto;
      }
    }
  }

  &_text {
    font-size: 18px;
    font-weight: bold;
    line-height: 2.111111111111111;
    letter-spacing: 0.06em;
    color: v.$green;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
  }

  &Lower {
    width: 100%;
    position: relative;
    z-index: 1;

    &_main {
      $this: &;
      margin-top: 64px;
      position: relative;
      z-index: 1;

      @include m.tab02 {
        margin-top: 5rem;
      }

      @include m.sp {
        height: 50vh;

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }

      &--nofit {
        @extend #{$this};
        object-fit: none;

        @include m.sp {
          height: auto;
        }
      }

      &Pos {
        &--right {
          img {
            @include m.sp {
              object-position: right;
            }
          }
        }

        &--75 {
          img {
            @include m.sp {
              object-position: 75%;
            }
          }
        }
      }
    }

    &_movie {
      width: 100%;
      height: 100vh;

      @include m.pc {
        max-height: 780px;
      }

      @include m.sp {
        // max-height: 333px;
        height: 80vh;
      }
    }

    &_cont {
      $this: &;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      overflow: hidden;

      &:after {
        content: '';
        width: 75%;
        height: 100%;
        display: block;
        background: v.$gradGreen;
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: -50px;
        z-index: -1;
        transform: skew(21deg);

        @include m.sp {
          width: 90%;
        }
      }

      &--noimg {
        @extend #{$this};
        margin-top: 200px;
        position: relative;
        bottom: auto;
        left: auto;

        @include m.tab {
          margin-top: 160px;
        }

        @include m.sp {
          margin-top: 12rem;
        }
      }

      &--full {
        @extend #{$this};

        &:after {
          width: 85%;

          @include m.sp {
            width: 105%;
          }
        }
      }
    }

    &_heading {
      width: 60%;
      padding: 54px 0;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.5555555555555556;
      color: v.$white;

      @include m.tab {
        padding: 32px 0;
        font-size: 30px;
      }

      @include m.sp {
        width: 70%;
        padding: 1.5rem 0;
        font-size: 2.4rem;
      }

      &Inner {
        margin-top: 12px;
        display: inline-block;
        position: relative;
        z-index: 1;

        @include m.sp {
          margin-top: 1.2rem;
        }
      }
    }

    &_ruby {
      width: 100%;
      text-align: left;
      position: absolute;
      bottom: 100%;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);

      @include m.sp {
        bottom: calc(100% - 4px);
      }
    }

    &_inner {
      padding: 40px 12% 28px 0;

      @include m.tab {
        padding: 32px 22vw 20px 0;
      }

      @include m.sp {
        padding: 1.5rem 2rem 1.5rem 0;
      }

      &Heading {
        font-size: 36px;
        font-weight: bold;
        line-height: 1.5555555555555556;
        letter-spacing: 0.05em;

        @include m.tab {
          font-size: 32px;
        }

        @include m.sp {
          padding-bottom: 0.5rem;
          font-size: 2rem;
        }
      }

      &Lead {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.9166666666666667;
        letter-spacing: 0.06em;

        @include m.tab {
          font-size: 20px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }
  }
}
