@use "global/mixin" as m;
@use "global/variable" as v;

.c-bnr {
  &--small {
    width: 70%;
    margin: 0 auto;

    @include m.sp {
      width: 80%;
    }
  }

  &_list {
    $this: &;
    width: 100%;

    & + & {
      margin: 48px 0 0;

      @include m.sp {
        margin: 3rem 0 0;
      }
    }

    &--border {
      border: 1px solid v.$black;
    }
  }

  &_item {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;

    @include m.pc {
      &:after {
        content: '';
        @include m.opacity();
      }

      &:hover {
        &:after {
          opacity: 0.3;
        }
      }
    }
  }

  /* 左画像、右テキストのバナー */
  &Label {
    width: 100%;

    &_item {
      width: 100%;
      display: block;
      position: relative;
      z-index: 1;

      @include m.pc {
        .c-bnr {
          &Label {
            &_box {
              &:after {
                transition: width 0.15s v.$animeBezierSingle02;
              }
            }
          }
        }
      }

      &:hover {
        @include m.pc {
          .c-bnr {
            &Label {
              &_box {
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &_pic {
      width: 57%;
      position: relative;
      z-index: 1;
      @include m.pc {
        max-width: 630px;
      }
      @include m.sp {
        width: 100%;
      }
    }

    &_box {
      width: 49%;
      height: 100%;
      padding: 0 0 0 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      @include m.pc {
        max-width: 543px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 9% 100%);
      }

      @include m.sp {
        width: 100%;
        padding: 1.2rem 1rem;
        position: static;
      }

      &:before,
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        width: 100%;
        background-color: v.$blackPale;
      }

      &:after {
        width: 0;
        background: v.$gradGreen;
      }
    }

    &_text {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.9166666666666667;
      color: v.$white;
      position: relative;
      z-index: 1;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_external {
      width: 26px;
      height: 26px;
      margin-left: 16px;
      background: url('../img/common/ico_external_white.svg') no-repeat center;
      background-size: contain;
      position: relative;
      z-index: 1;

      @include m.tab {
        width: 18px;
        height: 18px;
        margin-left: 12px;
      }

      @include m.sp {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 0 0.3rem 1rem;
      }
    }
  }
  /* end 左画像、右テキストのバナー */
}
