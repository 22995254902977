@use "global/mixin" as m;
@use "global/variable" as v;

/* 教員専用ページ用scssファイルです */

.p-teacher {
  &_heading {
    margin: 0 0 12px;
    padding: 4px 0 4px 16px;
    font-size: 18px;
    font-weight: bold;
    border-left: 4px solid v.$green;

    @include m.sp {
      margin: 0 0 1rem;
      padding: 4px 0 4px 1.2rem;
      font-size: 1.6rem;
    }
  }

  &Table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &_list {
      width: 25%;
      border-bottom: 1px solid v.$gray;
      border-right: 1px solid v.$gray;

      @include m.pc {
      }

      @include m.sp {
        width: 50%;
      }

      &:nth-child(4n) {
        // @include m.pc {
        //   border-right: none;
        // }
      }

      &:nth-child(2n) {
        @include m.sp {
          border-right: none;
        }
      }

      &:last-child {
        @include m.pc {
          border-right: 1px solid v.$gray;
        }
      }
    }

    &_item {
      width: 100%;
      padding: 20px;
      display: block;
      text-align: left;
      color: v.$green;
      text-decoration: underline;
    }

    &_item_nolink {
      width: 100%;
      padding: 20px;
      display: block;
      text-align: left;
      color: v.$black;
    }
  }
}
