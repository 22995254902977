@use "global/mixin" as m;
@use "global/variable" as v;

.c-breadcrumbs {
  width: 100%;
  padding: 40px 0 68px;
  display: flex;

  @include m.tab {
    padding: 32px 0 54px;
  }

  @include m.sp {
    flex-wrap: wrap;
    padding: 2rem 0 3rem;
  }

  &_list {
    margin-right: 8px;
    display: flex;
    align-items: center;

    @include m.sp {
      margin-bottom: 8px;
    }

    & + & {
      &:before {
        content: '＞';
        margin: 0 8px 0 0;

        @include m.sp {
          font-size: 1rem;
        }
      }
    }
  }

  &_item {
    @include m.pc {
      transition: color v.$anime, opacity v.$anime;
    }

    &:hover {
      @include m.pc {
        color: v.$green;
        opacity: 0.7;
      }
    }
  }
}
