@use "global/mixin" as m;
@use "global/variable" as v;

.c-modal {
  width: 100vw;
  height: 100vh;
  padding: 6vh 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9995;
  opacity: 0;
  pointer-events: none;
  transition: opacity v.$anime;

  @include m.sp {
    padding: 8rem 0;
  }

  &.is-view {
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  &_overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(v.$black, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9990;
    opacity: 0;
    pointer-events: none;
    transition: opacity v.$anime;

    &.is-view {
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }

  &_cont {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    overflow-y: scroll;
    position: relative;
    z-index: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include m.sp {
      width: 100%;
    }

    &--table {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &_movie {
    width: 100%;

    iframe,
    video {
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;

      @include m.pc {
        min-height: 540px;
      }

      @include m.sp {
        min-height: 16.8rem;
      }
    }
  }

  &_close {
    width: 40px;
    height: 40px;
    background-color: v.$green;
    border: 1px solid v.$green;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    @include m.pc {
      transition: background-color v.$anime;
      cursor: pointer;

      &:before,
      &:after {
        transition: background-color v.$anime;
      }

      &:hover {
        background-color: v.$white;

        &:before,
        &:after {
          background-color: v.$green;
        }
      }
    }

    @include m.tab {
      width: 36px;
      height: 36px;
    }

    @include m.sp {
      bottom: calc(100% + 0.7rem);
    }

    &:before,
    &:after {
      content: '';
      width: 60%;
      height: 1px;
      background-color: v.$white;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &_figure {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    @include m.sp {
      width: 100%;
    }

    &Item {
      &Inner {
        width: auto;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-height: 57vh;
          height: 100vh;
        }

        @include m.sp {
          width: 100%;
        }

        img {
          width: auto;
          height: 100%;
          object-fit: contain;
          margin: 0 auto;

          @include m.sp {
            width: 100%;
          }
        }
      }
    }

    &Text {
      padding-top: 12px;
      color: v.$white;

      @include m.pc {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
      }
    }
  }

  &_table {
    width: 100%;
    height: calc(90% - 32px);
    position: absolute;
    top: calc(50% + 32px);
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    &Inner {
      max-height: 70vh;

      @include m.pc {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include m.sp {
        overflow: scroll;
      }
    }
  }

  &_media {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include m.sp {
      display: block;
    }

    &Pic {
      width: 54%;

      @include m.pc {
        max-width: 488px;
        margin-right: 80px;
      }

      @include m.sp {
        width: 100%;
        margin-bottom: 2rem;
      }

      &Inner {
        margin: 0 0 8px;
      }
    }

    &Box {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &Heading {
      padding: 0 0 16px;
    }
  }

  &_btn {
    max-width: 350px;
    width: 100%;
    margin: 40px auto 80px;

    @include m.sp {
      width: 75%;
      margin: 2rem auto 4rem;
    }
  }

  &_ac {
    &Lead {
      margin: 60px 0 80px;
      position: relative;
      z-index: 1;

      // @include m.pc {
      //   cursor: pointer;
      //   transition: opacity v.$anime;

      //   &:hover {
      //     opacity: 0.7;
      //   }
      // }

      @include m.tab {
        margin: 30px 0 40px;
      }

      @include m.sp {
        margin: 3rem 0 4rem;
      }

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 2px solid v.$green;
        border-bottom: 2px solid v.$green;
        position: absolute;
        top: calc(100% + 16px);
        left: 50%;
        z-index: 2;
        transform: translateX(-50%) rotate(45deg);

        @include m.tab {
          top: calc(100% + 12px);
        }
      }

      &.is-open {
        &:after {
          transform: translateX(-50%) rotate(-135deg);
        }
      }
    }

    &Info {
      width: 100%;
      border-top: 1px solid v.$green;
      border-bottom: 1px solid v.$green;
      opacity: 1;
    }
  }

  &_list {
    $this: &;
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include m.tab {
      padding: 40px 0;
    }

    @include m.sp {
      padding: 3rem 0;
      display: block;
    }

    &--center {
      @extend #{$this};
      justify-content: center;
    }

    &--modal {
      @extend #{$this};
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .c-modal_listItem {
        width: 70%;

        @include m.sp {
          width: 100%;
        }

        & + .c-modal_listItem {
          margin: 10% 0 0;

          @include m.sp {
            margin: 4rem 0 0;
          }
        }
      }
    }

    &Item {
      width: 45%;

      @include m.pc {
        // max-width: 445px;
      }

      @include m.sp {
        width: 100%;
      }

      & + & {
        margin: 0 0 0 10%;

        @include m.sp {
          margin: 4rem 0 0;
        }
      }

      &:nth-child(n + 3) {
        margin-top: 40px;

        @include m.tab {
          margin-top: 28px;
        }

        @include m.sp {
          margin-top: 2rem;
        }
      }
    }
  }

  &Wrap {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;
  }

  &Movie {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &_cont {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }
}

.js-modalTeacherEle {
  .c-modal_close {
    @include m.sp {
      bottom: 100%;
      top: auto;
    }
  }
}
