@use "global/mixin" as m;
@use "global/variable" as v;

.c-info {
  &Feature {
    width: 100%;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        display: block;
      }
    }

    &_list {
      width: 45%;
      padding: 20px 20px 24px;
      background-color: v.$green;

      @include m.pc {
        max-width: 495px;
      }

      @include m.tab {
        width: 48%;
      }

      @include m.sp {
        width: 100%;
      }

      & + & {
        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }

    &_box {
      width: 100%;
      margin: 0 0 28px;
      padding: 28px;
      background-color: v.$white;

      @include m.sp {
        margin: 0 0 1.4rem;
        padding: 1.4rem;
      }
    }

    &_pic {
      width: 100%;
      height: 240px;
      margin: 0 0 28px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include m.sp {
        height: 18rem;
        margin: 0 0 1rem;
      }

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    &_lead {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.125;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }
    }

    &_inner {
      width: 100%;
    }
  }

  &Unit {
    $this: &;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & + & {
      .c-info {
        &Unit {
          &_head {
            border-top: 1px solid v.$white;
          }

          &_body {
            border-top: 1px solid v.$gray;
          }
        }
      }
    }

    &--block {
      &--sp {
        @extend #{$this};
        @include m.sp {
          display: block;

          & + .c-infoUnit--block--sp {
            margin-top: 2rem;
          }

          .c-infoUnit {
            &_head {
              width: 100%;
            }
          }
        }
      }
    }

    &_head {
      $this: &;
      padding: 20px;
      display: flex;
      align-items: flex-start;
      color: v.$white;
      background-color: v.$green;

      @include m.pc {
        width: 190px;
      }

      @include m.tab {
        padding: 12px;
      }

      @include m.sp {
        width: 30%;
        padding: 2rem 1.5rem;
      }

      &--w300 {
        @extend #{$this};
        @include m.pc {
          width: 300px;
        }

        @include m.sp {
          width: 40%;
        }
      }
    }

    &_body {
      padding: 20px;
      // display: flex;
      // align-items: center;
      flex: 1;
      background-color: v.$greenPale03;

      @include m.tab {
        padding: 12px;
      }

      @include m.sp {
        padding: 2rem 1.5rem;
      }
    }
  }

  &Line {
    $this: &;
    width: 100%;
    margin: 0 auto;

    @include m.pc {
      max-width: 620px;
    }

    &--full {
      @extend #{$this};
      @include m.pc {
        max-width: 100%;
      }
    }

    &_list {
      width: 100%;
      display: flex;

      &:last-child {
        .c-info {
          &Line {
            &_box {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }

    &_head {
      width: 180px;

      @include m.tab {
        width: 150px;
      }

      @include m.sp {
        width: 20%;
      }
    }

    &_box {
      $this: &;
      width: 15px;
      margin: 5px 30px 0;
      position: relative;
      z-index: 1;

      @include m.sp {
        margin: 5px 1.5rem 0;
      }

      &:before {
        content: '';
        width: 1px;
        height: calc(100% + 7.5px);
        background-color: v.$green;
        position: absolute;
        top: 0;
        left: calc(50% + 0.5px);
        z-index: 2;
        transform: translateX(-50%);

        @include m.tab {
          left: calc(50% - 1.5px);
        }

        @include m.sp {
          left: calc(50% - 1px);
        }
      }

      &--left {
        @extend #{$this};
        margin: 5px 30px 0 0;

        @include m.sp {
          margin: 5px 1.5rem 0 0;
        }
      }
    }

    &_ico {
      width: 15px;
      height: 15px;
      display: block;
      background-color: v.$green;
      border-radius: 50%;

      @include m.tab {
        width: 12px;
        height: 12px;
      }

      @include m.sp {
        width: 1.3rem;
        height: 1.3rem;
      }
    }

    &_body {
      flex: 1;
    }

    &_inner {
      padding-bottom: 80px;

      @include m.tab {
        padding-bottom: 40px;
      }

      @include m.sp {
        padding-bottom: 2rem;
      }
    }

    &_text {
      & + .c-infoLine_pic {
        margin-top: 12px;
      }
    }

    &_pic {
      width: 100%;

      @include m.pc {
        max-width: 300px;
      }

      img {
        width: 100%;
      }
    }
  }
}
