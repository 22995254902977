@use "global/mixin" as m;
@use "global/variable" as v;

.c-panel {
  width: 100%;
  display: block;
  color: v.$white;
  position: relative;
  z-index: 1;

  @include m.pc {
    .c-panel {
      &_box {
        &:after {
          transition: width v.$anime, height v.$anime, background v.$anime;
        }
      }
    }

    &:hover {
      .c-panel {
        &_box {
          &:after {
            width: 88%;
            height: 75%;
            background: v.$gradGreen;
          }
        }
      }
    }
  }

  &_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(v.$blackPale, 0.8);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }
  }

  &_text {
    &--main {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.5555555555555556;
      letter-spacing: 0.06em;

      @include m.tab {
        font-size: 30px;
      }

      @include m.sp {
        font-size: 1.8rem;
      }
    }

    &--sub {
      font-size: 24px;
      font-weight: bold;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }

  &_head {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 38%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  &_body {
    position: absolute;
    top: 70%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  &_arrow {
    position: relative;
    z-index: 1;
  }

  &_external {
    margin: 16px 0 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &Ico {
      width: 28px;
      margin: 0 0 12px;
    }

    &Text {
      text-align: center;
    }
  }

  &_pic {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &Card {
    width: 100%;
    display: block;
    color: v.$white;
    position: relative;
    z-index: 1;

    &:hover {
      @include m.pc {
        .c-panel {
          &Card {
            &_pic {
              img {
                transform: scale(1.1);
              }
            }

            &_cont {
              &:before {
                opacity: 0.3;
              }
            }

            &_center {
              &:before {
                width: 100%;
                opacity: 0.9;
              }

              &:after {
                background: rgba(v.$blackPale, 0);
              }
            }
          }
        }
      }
    }

    &_pic {
      width: 100%;
      position: relative;
      z-index: 1;
      overflow: hidden;

      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;

        @include m.pc {
          height: 100%;
          transition: transform 0.15s v.$animeBezierSingle02;
        }
      }
    }

    &_cont {
      $this: &;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      &:before {
        content: '';
        @include m.opacity();
        z-index: -2;
      }

      &:after {
        content: '';
        width: 100%;
        height: 50%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        opacity: 0.85;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;

        @include m.pc {
          transition: background-color v.$anime;
        }
      }

      &--noShadow {
        @extend #{$this};

        &:after {
          display: none;
        }
      }

      &--outer {
        @extend #{$this};
        position: static;

        &:before,
        &:after {
          display: none;
        }

        .c-panelCard_inner {
          padding: 12px 20px 12px 0;
          position: static;

          @include m.sp {
            padding: 1rem 1rem 1rem 0;
          }
        }
      }
    }

    &_center {
      @extend .c-panelCard_cont;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        width: 0;
        height: 100%;
        background: v.$gradGreen;
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        @include m.pc {
          transition: width 0.2s v.$animeBezierSingle02;
        }

        @include m.sp {
          width: 100%;
          background: none;
        }
      }

      &:after {
        height: 100%;
        background: rgba(v.$blackPale, 0.8);
        opacity: 1;

        @include m.pc {
          transition: background-color 0.3s v.$animeBezierSingle02;
        }

        @include m.sp {
          background: linear-gradient(to bottom, rgba(v.$black, 0) 0%, rgb(0, 0, 0) 200%);
        }
      }

      &Inner {
        width: 80%;

        @include m.sp {
          width: 90%;
        }
      }
      &Ico {
        width: 28px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 30px;
        bottom: 30px;
        z-index: 1;

        @include m.sp {
          width: 2rem;
          height: 1rem;
          right: 1rem;
          bottom: 1rem;
        }
      }
    }

    &_inner {
      width: 100%;
      padding: 0 20px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      @include m.tab {
        padding: 0 12px 12px;
      }

      @include m.sp {
        padding: 0 1rem 1rem;
      }
    }

    &_text {
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.06em;

      @include m.tab {
        font-size: 14px;
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_arrow {
      width: 28px;
      height: 10px;
      background: url('../img/common/ico_arrow_white.svg') no-repeat right bottom;
      background-size: contain;

      @include m.sp {
        width: 1.8rem;
        height: 1rem;
      }
    }

    &_external {
      width: 26px;
      height: 26px;
      background: url('../img/common/ico_external_white.svg') no-repeat right bottom;
      background-size: contain;

      @include m.sp {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &_modal {
      width: 26px;
      height: 26px;
      background: url('../img/common/ico_modal_white.svg') no-repeat right bottom;
      background-size: contain;

      @include m.sp {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &Wrap {
    $this: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include m.sp {
      display: block;
    }

    &--row03 {
      @extend #{$this};

      @include m.pc {
        &:after {
          content: '';
          width: 32%;
          display: block;
        }
      }
    }

    &_list {
      &--row03 {
        width: 32%;

        @include m.pc {
          max-width: 350px;

          &:nth-child(n + 4) {
            margin: 36px 0 0;
          }
        }

        @include m.sp {
          width: 100%;

          & + & {
            margin-top: 2rem;
          }
        }
      }
    }
  }

  &Box {
    width: 100%;
    background: linear-gradient(to right top, #39ba70 0%, #8fdb5f 100%);
    border-radius: 40px;

    @include m.pc {
      height: 350px;
    }

    @include m.tab {
      height: 260px;
      border-radius: 20px;
    }

    @include m.sp {
      border-radius: 20px;
    }

    &_cont {
      width: 100%;
      height: 100%;
      padding: 54px 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @include m.tab {
        padding: 40px 0;
      }

      @include m.sp {
        padding: 2rem 3rem;
        flex-flow: row;
        justify-content: flex-start;
      }
    }

    &_intro {
      width: 86px;
      margin: 0 0 32px;

      @include m.tab {
        margin: 0 0 12px;
      }

      @include m.sp {
        width: 7rem;
        margin: 0 2rem 0 0;
      }
    }

    &_text {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.9166666666666667;
      text-align: center;
      color: v.$white;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.4rem;
        text-align: left;
      }
    }
  }

  &Nolink {
    width: 100%;
    display: block;
    color: v.$white;
    position: relative;
    z-index: 1;
  }
}
