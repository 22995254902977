@use "global/mixin" as m;
@use "global/variable" as v;

.c-chart {
  &Wrap {
    width: 100%;
    display: flex;
    justify-content: center;

    @include m.sp {
      flex-wrap: wrap;
    }

    &.is-view {
      .c-chartGraph {
        &_circle {
          opacity: 1;

          &Type {
            &--per97 {
              @extend .c-chartGraph_circleType;
              animation: per97 1s forwards;

              @include m.sp {
                animation: per97Sp 1s forwards;
              }

              @keyframes per97 {
                0% {
                  stroke-dasharray: 0 421; // 421（svgの円周長） = 直径*円周率
                }
                100% {
                  stroke-dasharray: 408.4 421; // 378は421が100%とした時の%
                }
              }

              @keyframes per97Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 203.7 210;
                }
              }
            }

            &--per96 {
              @extend .c-chartGraph_circleType;
              animation: per96 1s forwards;

              @include m.sp {
                animation: per96Sp 1s forwards;
              }

              @keyframes per96 {
                0% {
                  stroke-dasharray: 0 421; // 421（svgの円周長） = 直径*円周率
                }
                100% {
                  stroke-dasharray: 404.2 421; // 378は421が100%とした時の%
                }
              }

              @keyframes per96Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 201.6 210;
                }
              }
            }

            &--per95 {
              @extend .c-chartGraph_circleType;
              animation: per95 1s forwards;

              @include m.sp {
                animation: per95Sp 1s forwards;
              }

              @keyframes per95 {
                0% {
                  stroke-dasharray: 0 421; // 421（svgの円周長） = 直径*円周率
                }
                100% {
                  stroke-dasharray: 399 421; // 378は421が100%とした時の%
                }
              }

              @keyframes per95Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 199.5 210;
                }
              }
            }

            &--per90 {
              @extend .c-chartGraph_circleType;
              animation: per90 1s forwards;

              @include m.sp {
                animation: per90Sp 1s forwards;
              }

              @keyframes per90 {
                0% {
                  stroke-dasharray: 0 421; // 421（svgの円周長） = 直径*円周率
                }
                100% {
                  stroke-dasharray: 400 421; // 378は421が100%とした時の%
                }
              }

              @keyframes per90Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 189 210;
                }
              }
            }

            &--per89 {
              @extend .c-chartGraph_circleType;
              animation: per89 1s forwards;

              @include m.sp {
                animation: per89Sp 1s forwards;
              }

              @keyframes per89 {
                0% {
                  stroke-dasharray: 0 421;
                }
                100% {
                  stroke-dasharray: 373 421;
                }
              }

              @keyframes per89Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 187 210;
                }
              }
            }

            &--per86 {
              @extend .c-chartGraph_circleType;
              animation: per86 1s forwards;

              @include m.sp {
                animation: per86Sp 1s forwards;
              }

              @keyframes per86 {
                0% {
                  stroke-dasharray: 0 421;
                }
                100% {
                  stroke-dasharray: 361 421;
                }
              }

              @keyframes per86Sp {
                0% {
                  stroke-dasharray: 0 210;
                }
                100% {
                  stroke-dasharray: 180 210;
                }
              }
            }

            &--perAll {
              @extend .c-chartGraph_circleType;
              animation: perAll 1s forwards;

              @keyframes perAll {
                0% {
                  stroke-dasharray: 0 421;
                }
                100% {
                  stroke-dasharray: 421 421;
                }
              }
            }
          }
        }
      }
    }

    &_box {
      &--row03 {
        width: 268px;

        @include m.sp {
          width: 40%;
        }

        @include m.pc {
          & + & {
            margin: 0 0 0 68px;
          }
        }

        @include m.sp {
          &:nth-of-type(2) {
            margin: 0 0 0 5rem;
          }

          &:nth-of-type(3) {
            margin: 2rem 0 0;
          }
        }
      }
    }
  }

  &Graph {
    @include m.sp {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    &_heading {
      width: 100%;
      padding: 0 0 40px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.06em;
      text-align: center;

      @include m.sp {
        padding: 0 0 2rem;
        font-size: 14px;
      }
    }

    &_circle {
      width: 100%;
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.1s ease;

      &Item {
        display: inline-block;
        transform: rotate(-90deg);
      }

      &Num {
        display: flex;
        align-items: flex-end;
        font-size: 120px;
        font-weight: bold;
        line-height: 0.8;
        color: v.$white;
        position: absolute;
        top: 45%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);

        @include m.sp {
          font-size: 60px;
        }
      }

      &Unit {
        font-size: 68px;
        font-weight: bold;

        @include m.sp {
          font-size: 34px;
        }
      }

      &Text {
        font-size: 24px;
        font-weight: bold;
        position: absolute;
        top: 75%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        color: v.$white;

        @include m.sp {
          font-size: 16px;
        }
      }

      &Type {
        fill: transparent;
        stroke-width: 134;

        @include m.sp {
          stroke-width: 67;
        }

        &--green {
          stroke: v.$green;

          &Pale {
            stroke: v.$greenPale;
          }
        }
      }
    }
  }

  &Cost {
    width: 100%;

    & + & {
      margin: 100px 0 0;

      @include m.sp {
        margin: 5rem 0 0;
      }
    }

    &_list {
      width: 100%;
      padding: 0 0 60px;

      @include m.tab {
        padding: 0 0 30px;
      }

      @include m.sp {
        padding: 0 0 1rem;
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        align-items: flex-end;
      }

      &.is-view {
        .c-chartCost_graph {
          &--green {
            &[data-num='94'] {
              &:after {
                width: 94%;
              }
            }

            &[data-num='91'] {
              &:after {
                width: 91%;
              }
            }

            &[data-num='86'] {
              &:after {
                width: 86%;
              }
            }
          }
        }
      }
    }

    &_box {
      display: flex;
      flex: 1;
      flex-flow: column;
      justify-content: space-between;

      @include m.sp {
        width: 100%;
        display: block;
      }
    }

    &_info {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include m.sp {
        display: block;
      }

      & + & {
        @include m.sp {
          margin: 1.5rem 0 0;
        }
      }

      &Head {
        padding: 0 28px 0 0;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.8571428571428572;
        text-align: right;

        @include m.pc {
          flex: 1;
        }

        @include m.tab {
          font-size: 12px;
        }

        @include m.sp {
          width: 100%;
          padding: 0 0 0.5rem;
          display: block;
          font-size: 1.2rem;
          text-align: left;
        }
      }

      &Body {
        width: 72%;
        height: 100%;
        position: relative;
        z-index: 1;

        @include m.pc {
          max-width: 445px;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }

    &_graph {
      width: 100%;
      min-height: 74px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      z-index: 1;

      @include m.sp {
        min-height: 5rem;
        padding: 0.5rem 0;
      }

      &:before,
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: left;
      }

      &:before {
        width: 100%;
        z-index: -2;
      }

      &:after {
        width: 0;
        z-index: -1;
      }

      &--gray {
        @extend .c-chartCost_graph;

        &:before {
          background-color: v.$grayDeep;
        }
      }

      &--green {
        @extend .c-chartCost_graph;

        &:before {
          background-color: v.$green;
        }

        &:after {
          background-color: v.$greenPale;
          transition: width 1s ease 0.5s; // prm仮
        }
      }
    }

    &_text {
      width: 100%;
      padding: 0 0 0 36px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.625;

      @include m.tab {
        padding: 0 0 0 18px;
        font-size: 13px;
      }

      @include m.sp {
        padding: 0 0 0 1rem;
        font-size: 1.2rem;
        color: v.$white;
      }

      &--white {
        @extend .c-chartCost_text;
        color: v.$white;
      }
    }

    &_balloon {
      width: 100%;
      height: 200px;
      margin: 0 0 0 64px;
      padding: 0 0 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 220px;
      }

      @include m.sp {
        width: 10.5rem;
        height: 10.5rem;
        margin: 0 0 1rem 3rem;
        padding: 0 0 2rem;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        width: 100%;
        height: 100%;
        background: v.$gradGreen;
        top: 0;
        left: 0;
        z-index: -1;
      }

      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 53px 53px;
        border-color: transparent transparent v.$green transparent;
        right: calc(100% - 16px);
        bottom: 30px;
        z-index: -2;

        @include m.sp {
          border-width: 0 0 3.5rem 3.5rem;
          bottom: 1.5rem;
        }
      }
    }

    &_num {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-size: 100px;
      font-weight: bold;
      line-height: 0.65;
      letter-spacing: 0.01em;
      color: v.$white;
      position: relative;
      z-index: 1;

      @include m.sp {
        height: 4rem;
        font-size: 5rem;
      }

      &:after {
        content: '万円の差';
        font-size: 24px;
        font-weight: bold;
        line-height: 1.9166666666666667;
        letter-spacing: 0.006em;
        color: v.$white;
        position: absolute;
        top: calc(100% + 4px);
        right: 15%;
        z-index: 2;
        white-space: nowrap;

        @include m.sp {
          font-size: 1.2rem;
        }
      }
    }

    &_unit {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.006em;

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_note {
      width: 100%;
      padding: 20px 0 0;
      display: flex;
      flex-flow: column;
      align-items: flex-end;

      @include m.sp {
        align-items: flex-start;
      }

      &List {
        text-indent: -1em;
        padding: 0 0 0 1em;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5833333333333333;

        & + & {
          margin-top: 16px;

          @include m.sp {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
