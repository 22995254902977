@use "global/mixin" as m;
@use "global/variable" as v;

.c-heading {
  /* 基本font-size違いの見出し */
  &Type {
    &01 {
      $this: &;
      width: 100%;
      margin: 0 0 36px;
      font-size: 48px;
      font-weight: 600;
      text-align: center;
      color: v.$white;

      @include m.tab {
        font-size: 36px;
      }

      @include m.sp {
        margin: 0 0 3rem;
        font-size: 3rem;
      }

      &--inner {
        @extend #{$this};
        margin: 0;

        @include m.tab {
          margin: 0;
        }

        @include m.sp {
          margin: 0;
        }
      }
    }

    &02 {
      $this: &;
      margin: 0 0 80px;
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0.06em;

      @include m.tab {
        margin: 0 0 40px;
        font-size: 28px;
      }

      @include m.sp {
        margin: 0 0 4rem;
        font-size: 2.4rem;
        letter-spacing: 0;
      }

      &--inner {
        @extend #{$this};
        margin: 0;

        @include m.tab {
          margin: 0;
        }

        @include m.sp {
          margin: 0;
        }
      }

      /* c-headingType02でsp時の改行位置都合で少しfont-size小さくしたい時 */
      &--small {
        @extend #{$this};

        @include m.sp {
          font-size: 1.8rem;
        }
      }
      /* end c-headingType02でsp時の改行位置都合で少しfont-size小さくしたい時 */
    }

    &03 {
      font-size: 24px;
      font-weight: bold;

      @include m.tab {
        font-size: 20px;
      }

      @include m.sp {
        font-size: 1.6rem;
      }
    }

    &04 {
      font-size: 21px;
      font-weight: bold;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.5rem;
      }
    }

    &05 {
      font-size: 18px;
      font-weight: bold;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }
  /* end 基本font-size違いの見出し */

  /* 見出し下に注釈がある場合 */
  &Note {
    padding: 8px 0 0;
    display: block;
    font-size: 12px;
    font-weight: 500;
  }
  /* end 見出し下に注釈がある場合 */

  /* 固定線が下につく見出し */
  &Lead {
    text-align: center;

    &_inner {
      display: inline-block;
    }

    &_text {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0.06em;

      @include m.tab {
        font-size: 28px;
      }

      @include m.sp {
        font-size: 2rem;
      }
    }

    &_line {
      $this: &;
      width: 160px;
      height: 12px;
      margin: 20px auto 0;
      display: block;
      transform: skew(30deg);

      @include m.tab {
        width: 120px;
        margin: 16px auto 0;
      }

      @include m.sp {
        width: 12rem;
        height: 0.8rem;
        margin: 1.5rem auto 0;
      }

      &--fit {
        @extend #{$this};
        width: 100%;
      }
    }
  }
  /* end 固定線が下につく見出し */

  /* 緑グラデ背景の見出し */
  &Label {
    width: calc(100% - 45px);
    margin: 0 auto;
    padding: 24px 64px;
    background: v.$gradGreen;
    transform: skew(30deg);

    @include m.tab {
      padding: 20px 48px;
    }

    @include m.sp {
      width: 97%;
      margin: 0 auto;
      padding: 1rem 3rem;
      transform: skew(15deg);
    }

    &_text {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      color: v.$white;
      transform: skew(-30deg);
      position: relative;
      z-index: 1;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        font-size: 1.6rem;
        transform: skew(-15deg);
      }

      &Sub {
        padding: 8px 16px;
        border: 1px solid v.$white;
        display: inline-block;

        @include m.pc {
          white-space: nowrap;
          line-height: 1;
          position: absolute;
          top: 50%;
          left: calc(100% + 48px);
          z-index: 2;
          transform: translateY(-50%);
        }

        @include m.sp {
          margin-top: 6px;
          padding: 4px 8px;
        }
      }
    }
  }
  /* end 緑グラデ背景の見出し */

  /* 隣に線が伸びている見出し */
  &Line {
    $this: &;
    width: 100%;
    display: flex;

    &_main {
      display: flex;
      align-items: center;
      background-color: v.$white;
    }

    &_text {
      margin-right: 12px;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 0.06em;
      color: v.$green;

      @include m.tab {
        margin-right: 8px;
        font-size: 18px;
      }

      @include m.sp {
        margin-right: 0.8rem;
        font-size: 1.8rem;
      }
    }

    &_ico {
      max-width: 56px;
      height: 21px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include m.tab {
        max-width: 40px;
        height: 18px;
        margin-right: 8px;
      }

      @include m.sp {
        max-width: 4rem;
        height: 1.5rem;
        margin-right: 0.8rem;
      }
    }

    &_sub {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: v.$green;
      }
    }
  }
  /* end 隣に線が伸びている見出し */
}
