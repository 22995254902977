@use "global/mixin" as m;
@use "global/variable" as v;

/* 資料請求・お問い合わせページ用scssファイルです */

.p-contact {
  &Name {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &Require {
    margin-left: 8px;
    padding: 6px 8px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.06em;
    line-height: 1;
    color: v.$white;
    background-color: v.$redPale;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      margin-left: 1rem;
      font-size: 1.2rem;
    }
  }

  &Input {
    width: 100%;
    padding: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06em;
    line-height: 1;
    border: 1px solid v.$gray;

    @include m.sp {
      padding: 1rem;
      font-size: 1.6rem;
    }

    &::placeholder {
      color: v.$gray;
    }
  }

  &Text {
    @extend .p-contactInput;
    height: 200px;
    resize: none;

    @include m.sp {
      height: 20rem;
    }
  }

  &Error {
    color: v.$redPale;
  }

  &Radio {
    &_list {
      height: 18px;

      & + & {
        margin-top: 24px;

        @include m.sp {
          margin-top: 1.5rem;
        }
      }
    }

    &_label {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      @include m.pc {
        cursor: pointer;
      }

      &Input {
        display: none;

        &:checked + span {
          &:before,
          &:after {
            opacity: 1;
          }
        }
      }

      &Ico {
        width: 18px;
        height: 18px;
        display: block;
        border: 1px solid v.$gray;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          border-radius: 50%;
          position: absolute;
          opacity: 0;
        }

        &:before {
          width: 100%;
          height: 100%;
          background: v.$gradGreen;
          top: 0;
          left: 0;
          z-index: 2;
        }

        &:after {
          width: 8px;
          height: 8px;
          background-color: v.$white;
          top: 50%;
          left: 50%;
          z-index: 3;
          transform: translate(-50%, -50%);
        }
      }

      &Text {
        margin-left: 16px;
      }
    }
  }

  &Privacy {
    width: 100%;
    display: flex;
    justify-content: center;

    &_label {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      @include m.pc {
        cursor: pointer;
        transition: opacity v.$anime;
      }

      &:hover {
        @include m.pc {
          opacity: 0.7;
        }
      }

      &Input {
        display: none;

        &:checked + span {
          &:after {
            opacity: 1;
          }
        }
      }

      &Ico {
        width: 25px;
        height: 25px;
        margin-right: 16px;
        background-color: v.$white;
        border: 1px solid v.$gray;
        position: relative;
        z-index: 1;

        @include m.sp {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }

        &:after {
          content: '';
          width: 14px;
          height: 8px;
          border-left: 2px solid v.$green;
          border-bottom: 2px solid v.$green;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%) rotate(-50deg);
          opacity: 0;
        }
      }
    }
  }

  &Btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &_list {
      width: 100%;

      @include m.pc {
        max-width: 350px;
      }

      @include m.sp {
        width: 47%;
      }

      & + & {
        margin-left: 40px;

        @include m.sp {
          margin-left: 6%;
        }
      }
    }

    &_label {
      @include m.pc {
        cursor: pointer;
      }
    }
  }
}
