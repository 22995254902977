@use "global/mixin" as m;
@use "global/variable" as v;

.c-note {
  width: 100%;
  padding: 8px 0 0;

  &_list {
    text-indent: -1em;
    padding: 0 0 0 1em;
  }
}
