@use "global/variable" as v;

.u-font {
  &--yugo {
    font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', sans-serif;
  }

  &--petch {
    font-family: v.$petch;
  }
}
