@use "global/mixin" as m;
@use "global/variable" as v;

/* 就職・進路ページ用scssファイルです */

.p-career {
  /* 就職サポート - 卒業生のコメント */
  &Comment {
    width: 100%;

    &_cont {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    &_pic {
      width: 47%;
      position: relative;
      z-index: 2;

      @include m.pc {
        max-width: 428px;
      }

      @include m.sp {
        width: 80%;
        margin: 0 auto 2rem;
      }
    }

    &_balloon {
      width: 128px;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: v.$gradGreen;
      border-radius: 50%;
      position: absolute;
      top: 15%;
      left: 85%;
      z-index: 2;
      transform: translate(-50%, -50%);

      @include m.tab {
        width: 100px;
        height: 100px;
      }

      @include m.sp {
        width: 9rem;
        height: 9rem;
        top: 17%;
      }
    }

    &_box {
      width: 81%;

      @include m.pc {
        max-width: 744px;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        transform: translateY(-50%);
      }

      @include m.sp {
        width: 100%;
      }
    }

    &_info {
      width: 100%;

      &Head {
        width: 100%;
        margin: 0 0 16px 0;
        padding: 0 0 12px 40%;
        font-size: 24px;
        font-weight: bold;
        color: v.$green;
        border-bottom: 1px solid v.$green;

        @include m.tab {
          font-size: 20px;
        }

        @include m.sp {
          margin: 0 0 1.5rem;
          padding: 0 0 0.5rem;
          font-size: 1.8rem;
        }

        &--small {
          font-size: 16px;

          @include m.tab {
            font-size: 14px;
          }

          @include m.sp {
            font-size: 1.4rem;
          }
        }
      }

      &Body {
        width: 100%;
        padding: 12px 24px 16px 40%;
        display: flex;
        justify-content: flex-end;
        background: v.$gradGreen;

        @include m.sp {
          padding: 1.5rem;
        }
      }
    }
  }
  /* end 就職サポート - 卒業生のコメント */

  /* ExtentionCenter - 開講講座一覧 */
  &Extention {
    & + & {
      margin-top: 48px;

      @include m.tab {
        margin-top: 32px;
      }

      @include m.sp {
        margin-top: 4rem;
      }
    }
  }
  /* end ExtentionCenter - 開講講座一覧 */
}
