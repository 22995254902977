@use "global/mixin" as m;
@use "global/variable" as v;

.c-anime {
  &Clip {
    &--left {
      clip-path: inset(0 100% 0 0);

      &.is-view {
        clip-path: inset(0);
      }
    }

    &--right {
      clip-path: inset(0 0 0 100%);

      &.is-view {
        clip-path: inset(0);
      }
    }
  }
}
