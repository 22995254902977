@use "global/mixin" as m;
@use "global/variable" as v;

.c-layer {
  $this: &;
  position: relative;
  z-index: 1;

  img {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    background: v.$gradGreen;
    position: absolute;
    z-index: -1;

    @include m.tab {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
    }

    @include m.sp {
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
    }
  }

  &--topRight {
    @extend #{$this};
    padding: 60px 60px 0 0;

    @include m.tab {
      padding: 30px 30px 0 0;
    }

    @include m.sp {
      padding: 2rem 2rem 0 0;
    }

    &:after {
      top: 0;
      right: 0;
    }
  }

  &--bottomRight {
    @extend #{$this};
    padding: 0 60px 60px 0;

    @include m.tab {
      padding: 0 30px 30px 0;
    }

    @include m.sp {
      padding: 0 2rem 2rem 0;
    }

    &:after {
      bottom: 0;
      right: 0;
    }
  }

  &--topLeft {
    @extend #{$this};
    padding: 60px 0 0 60px;

    @include m.tab {
      padding: 30px 0 0 30px;
    }

    @include m.sp {
      padding: 2rem 0 0 2rem;
    }

    &:after {
      top: 0;
      left: 0;
    }
  }

  &--bottomLeft {
    @extend #{$this};
    padding: 0 0 60px 60px;

    @include m.tab {
      padding: 0 0 30px 30px;
    }

    @include m.sp {
      padding: 0 0 2rem 2rem;
    }

    &:after {
      bottom: 0;
      left: 0;
    }
  }
}
