@use "global/mixin" as m;
@use "global/variable" as v;

.u-fz {
  &--14 {
    font-size: 14px;

    @include m.sp {
      font-size: 1.2rem;
    }
  }
}
