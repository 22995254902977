@use "global/mixin" as m;
@use "global/variable" as v;

/* 対象者別ページ（受験生・在校生・卒業生・企業等）用scssファイルです */

.p-navi {
  &Wrap {
    width: 100%;
    padding: 0 32px;

    @include m.tab {
      padding: 0 20px;
    }

    @include m.sp {
      padding: 0 1rem;
    }

    &_cont {
      width: 100%;

      @include m.pc {
        display: flex;
      }

      &Box {
        & + & {
          @include m.pc {
            margin-left: 2em;
          }

          @include m.sp {
            margin-top: 3rem;
          }
        }

        @include m.sp {
          width: 100%;
        }
      }

      &List {
        & + & {
          margin-top: 12px;
        }
      }
    }
  }

  &Item {
    width: 100%;
    padding-left: 2.5em;

    &_list {
      & + & {
        margin-top: 12px;
      }
    }
  }

  &Inner {
    width: 100%;

    &_list {
      margin-top: 4px;
      display: flex;
      justify-content: flex-start;

      &:before {
        content: '-';
        margin: 0 0.5em 0 1em;
        color: v.$green;
      }
    }
  }

  /* 企業・採用担当者の方 */
  &Support {
    width: 100%;
    margin-top: 60px;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.sp {
        display: block;
      }
    }

    &_logo {
      width: 100%;
      margin: 0 40px 0 0;

      @include m.pc {
        max-width: 350px;
      }

      @include m.sp {
        margin: 0 0 1.5rem;
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }
    }
  }
  /* end 企業・採用担当者の方 */

  /* 卒業生の方 */
  &Pass {
    width: 100%;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.sp {
        display: block;
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        margin-bottom: 1rem;
      }
    }

    &_btn {
      width: 100%;

      @include m.pc {
        max-width: 350px;
        margin-left: 40px;
      }
    }
  }
  /* end 卒業生の方 */
}
