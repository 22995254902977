@use "global/mixin" as m;
@use "global/variable" as v;

/* 学科案内配下用scssファイルです */

.p-dp {
  /* 学科案内TOP */
  &Index {
    &_course {
      @include m.pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @include m.sp {
        width: 90%;
        margin: 0 auto;
      }

      &List {
        @include m.pc {
          width: 48%;
          max-width: 525px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }

        &:nth-child(n + 3) {
          margin-top: 60px;

          @include m.tab {
            margin-top: 40px;
          }

          @include m.sp {
            margin-top: 3rem;
          }
        }

        & + & {
          @include m.sp {
            margin-top: 6rem;
          }
        }
      }

      &Btn {
        margin-top: 30px;

        @include m.sp {
          margin-top: 2rem;
        }
      }
    }
  }

  &Design {
    &_index {
      &Arrow {
        width: 84px;
        margin: 64px auto 60px;
      }
    }
  }

  &Logo {
    &--land {
      width: 100%;
      margin: 0 auto 40px;

      @include m.pc {
        max-width: 240px;
      }

      @include m.sp {
        width: 60%;
        margin: 0 auto 3rem;
      }
    }
  }

  &Teacher {
    width: 100%;

    @include m.sp {
      margin-bottom: 6rem;
    }

    &_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include m.sp {
        display: block;
      }
    }

    &_box {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @include m.sp {
        width: 100%;
      }

      &:nth-child(n + 3) {
        @include m.pc {
          margin-top: 84px;
        }
      }

      & + & {
        @include m.sp {
          margin-top: 8rem;
        }
      }
    }

    &_inner {
      width: 100%;
      -webkit-filter: drop-shadow(20px 20px 40px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(20px 20px 40px rgba(0, 0, 0, 0.15));

      @include m.pc {
        max-width: 420px;
      }
    }
  }

  &Atelier {
    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        flex-flow: column;
        justify-content: flex-start;
      }

      & + & {
        margin-top: 100px;

        @include m.tab {
          margin-top: 80px;
        }

        @include m.sp {
          margin-top: 5rem;
        }
      }
    }

    &_pic {
      $this: &;
      width: 45%;

      @include m.pc {
        max-width: 480px;
      }

      @include m.sp {
        width: 100%;
        // order: 1;
      }

      &--left {
        @extend #{$this};

        .p-dpAtelier_picBox {
          @include m.sp {
            padding-right: 8rem;
          }
        }
      }

      &--right {
        @extend #{$this};

        @include m.sp {
          order: 0;
        }

        .p-dpAtelier_picBox {
          @include m.sp {
            padding-left: 8rem;
          }
        }
      }

      &View {
        margin-bottom: 20px;
      }

      &Box {
      }
    }

    &_lead {
      padding-bottom: 12px;

      @include m.sp {
        font-size: 18px;
        font-weight: bold;

        @include m.tab {
          font-size: 16px;
        }
      }
    }

    &_text {
      font-size: 14px;

      @include m.tab {
        font-size: 12px;
      }

      @include m.sp {
        font-size: 1.5rem;
      }
    }

    &_art {
      $this: &;
      width: 48%;
      padding-left: 48px;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 540px;
      }

      @include m.sp {
        width: 100%;
        margin-top: 3rem;
        padding-right: 2rem;
        padding-left: 0;
        padding-bottom: 2rem;
        // order: 0;
      }

      &:after {
        content: '';
        width: 90px;
        height: 60px;
        position: absolute;
        z-index: -1;

        @include m.pc {
          top: 30%;
          transform: translateY(-50%);
        }

        @include m.tab {
          width: 7vw;
          height: 4.5vw;
        }

        @include m.sp {
          width: 7.5rem;
          height: 13.5rem;
          background: url('../img/departments/designarts/index/obj_green_arrow_top.svg') no-repeat center top;
          background-size: contain;
          bottom: calc(100% - 3px);
          right: 0;
        }
      }

      &--right {
        @extend #{$this};

        &:after {
          @include m.pc {
            background: url('../img/departments/designarts/index/obj_green_arrow_left.svg') no-repeat right center;
            background-size: contain;
            right: calc(100% - 1px);
          }
        }

        .p-dpAtelier_artBox {
          @include m.sp {
            padding-left: 2rem;
          }
        }
      }

      &--left {
        @extend #{$this};
        padding-right: 48px;
        padding-left: 0;

        @include m.sp {
          padding-right: 0;
          padding-left: 2rem;
          order: 1;
        }

        &:after {
          @include m.pc {
            background: url('../img/departments/designarts/index/obj_green_arrow_right.svg') no-repeat left center;
            background-size: contain;
            left: calc(100% - 1px);
          }

          @include m.sp {
            right: auto;
            left: 0;
          }
        }

        .p-dpAtelier_artBox {
          @include m.pc {
            padding-left: 48px;
          }

          @include m.sp {
            padding-right: 2rem;
          }
        }
      }

      &Pic {
        margin-bottom: 20px;
      }
    }
  }

  &Bnr {
    width: 100%;

    &_item {
      width: 100%;
      display: block;
      position: relative;
      z-index: 1;

      @include m.sp {
        height: 10rem;
      }
    }

    &_pic {
      width: 100%;
      position: relative;
      z-index: 1;

      @include m.sp {
        height: 100%;
      }

      img {
        @include m.sp {
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }

    &_box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      @include m.pc {
        &:hover {
          &:before {
            background-color: rgba(v.$blackPale, 0);
          }

          &:after {
            width: 100%;
          }
        }
      }

      &:before,
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        width: 100%;
        background-color: rgba(v.$blackPale, 0.8);
        z-index: -2;

        @include m.pc {
          transition: background-color v.$anime 0.1s;
        }

        @include m.sp {
          background-color: rgba(v.$blackPale, 0);
        }
      }

      &:after {
        width: 0;
        background: v.$gradGreen;
        opacity: 0.9;

        @include m.pc {
          transition: width 0.2s v.$animeBezierSingle02;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }

    &_inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
    }

    &_text {
      width: auto;
      height: 62px;

      @include m.sp {
        height: 3rem;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    &_arrow {
      margin-left: 20px;

      @include m.sp {
        margin-left: 1rem;
      }
    }
  }

  &Achivement {
    margin: 0 auto 3rem;
    max-width: 100%;
    @include m.pc {
      margin-bottom: 36px;
    }

    iframe {
      height: 100% !important;
    }
  }

  /* 卒業制作展示会ページ用 */
  &Graduation {
    &_btn {
      width: 100%;
      margin-top: 60px;

      @include m.tab {
        margin-top: 40px;
      }

      @include m.sp {
        margin-top: 3rem;
      }
    }

    &_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &Box {
        width: 47%;

        @include m.pc {
          max-width: 519px;
        }

        &:nth-child(n + 3) {
          margin-top: 60px;

          @include m.tab {
            margin-top: 40px;
          }

          @include m.sp {
            margin-top: 4rem;
          }
        }
      }

      &Pic {
        width: 100%;
        margin: 0 auto 24px;

        @include m.pc {
          max-width: 350px;
        }
      }

      &Inner {
        width: 100%;

        & + & {
          margin-top: 12px;
        }
      }
    }
  }
  /* end 卒業制作展示会ページ用 */

  /* こんなキミに学んでほしい */
  &Want {
    width: 100%;

    &_intro {
      width: 100%;
      display: flex;
      align-items: center;
      background: none;
      position: relative;
      z-index: 1;

      &:before,
      &:after {
        content: '';
        height: 1px;
        display: block;
        flex: 1;
        background-color: v.$white;
      }

      &Text {
        margin: 0 20px;
        letter-spacing: 0.06em;
        color: v.$white;

        @include m.sp {
          margin: 0 1rem;
        }
      }
    }

    &_illust {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      &Box {
        width: 16%;

        @include m.pc {
          max-width: 168px;
        }

        @include m.sp {
          width: 22%;
        }

        & + & {
          margin-left: 68px;

          @include m.tab {
            margin-left: 32px;
          }

          @include m.sp {
            margin-left: 4%;
          }
        }
      }
    }

    &_job {
      width: 100%;
      padding: 40px 60px;
      background-color: v.$white;
      border: 1px solid v.$green;

      @include m.tab {
        padding: 20px 32px;
      }

      @include m.sp {
        padding: 2rem 3rem;
      }
    }
  }
  /* end こんなキミに学んでほしい */

  /* この授業のココが好き！ */
  &This {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_head {
      width: 100%;

      @include m.pc {
        max-width: 132px;
        margin-right: 24px;
      }

      @include m.tab {
        margin-right: 16px;
      }

      @include m.sp {
        width: 30%;
        margin-right: 5%;
      }
    }

    &_body {
      flex: 1;
    }
  }
  /* end この授業のココが好き！ */

  /* 出版物バックナンバー */
  &Publish {
    &List {
      border-top: 1px solid v.$gray;
      border-bottom: 1px solid v.$gray;
      padding: 6rem 0;
      @include m.pc {
        padding: 80px 0;
      }
      &_item:nth-child(n + 4) {
        @include m.pc {
          margin-top: 80px;
        }
      }
      &_heading {
        margin-bottom: 1rem;
        @include m.pc {
          margin-bottom: 16px;
        }
      }
      &_date {
        margin-bottom: 3rem;
        @include m.pc {
          margin-bottom: 40px;
        }
      }
      &_pic {
        aspect-ratio: auto 151/214;
        margin: 0 auto 3rem;
        max-width: 153px;
        border: 1px solid v.$blackPale;
        @include m.pc {
          margin-bottom: 40px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &_btn {
        @include m.sp {
          width: 100%;
        }
      }
    }

    &Pagination {
      display: flex;
      justify-content: center;
      margin-top: 6rem;
      @include m.pc {
        margin-top: 80px;
      }
      &_item,
      &_active,
      &_next {
        margin: 0 0.5em;
        width: 3rem;
        line-height: 3rem;
        font-size: 1.2rem;
        text-align: center;
        @include m.pc {
          width: 30px;
          line-height: 35px;
          font-size: 1rem;
        }
      }
      &_item,
      &_active {
        color: #fff;
        font-weight: bold;
      }
      &_item {
        background: rgba(v.$green, 0.5);
      }
      &_active {
        background: v.$green;
      }
      &_next {
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.4rem;
          height: 1.4rem;
          background: url('../img/common/ico_arrow_black.svg') no-repeat center;
          background-size: contain;
          transform: translate(-50%, -50%);
          content: '';
        }
      }
    }
  }
  /* end 出版物バックナンバー */
}
