@use "global/mixin" as m;
@use "global/variable" as v;

.c-flow {
  &Period {
    width: 100%;
    margin: 0 0 200px;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include m.sp {
      padding: 0;
      display: block;
    }

    &_box {
      width: 44%;
      position: relative;
      z-index: 1;

      @include m.pc {
        max-width: 440px;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &_arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 50px;
      border-color: transparent transparent transparent v.$green;
      transform: skew(30deg);

      @include m.sp {
        margin: 2rem auto;
        border-width: 2.5rem 1.25rem 0 1.25rem;
        border-color: v.$green transparent transparent transparent;
        transform: skew(12deg);
      }
    }

    &_lead {
      width: 100%;
      height: 54px;
      margin: 0 0 32px;
      transform: skew(30deg);

      @include m.pc {
        max-width: 420px;
      }

      @include m.sp {
        height: 4.5rem;
        margin: 0 0 2rem;
      }

      &--white {
        @extend .c-flowPeriod_lead;
        text-align: center;
        background-color: v.$white;

        .c-flow {
          &Period {
            &_lead {
              &Inner {
                color: v.$green;
              }
            }
          }
        }
      }

      &--green {
        @extend .c-flowPeriod_lead;
        text-align: center;
        background-color: v.$green;

        .c-flow {
          &Period {
            &_lead {
              &Inner {
                color: v.$white;
              }
            }
          }
        }
      }

      &Inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.06em;
        transform: skew(-30deg);

        @include m.tab {
          font-size: 20px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }
      }
    }

    &_balloon {
      width: 110%;
      padding: 32px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.75;
      letter-spacing: 0.06em;
      text-align: center;
      color: v.$green;
      background-color: v.$white;
      border-radius: 8px;
      position: absolute;
      top: 135%;
      left: 58%;
      z-index: 2;
      transform: translateX(-50%);

      @include m.pc {
        max-width: 480px;
      }

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        width: 100%;
        padding: 2rem;
        font-size: 1.4rem;
        top: 130%;
        left: 50%;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 36px 36px 36px;
        border-color: transparent transparent v.$white transparent;
        position: absolute;
        bottom: calc(100% - 8px);
        left: 50%;
        z-index: 2;
        transform: translateX(-50%) skew(60deg);

        @include m.sp {
          border-width: 0 1.8rem 1.8rem 1.8rem;
          bottom: calc(100% - 4px);
        }
      }
    }
  }
}
