@use "global/mixin" as m;
@use "global/variable" as v;

.l-footer {
  width: 100%;
  position: relative;
  z-index: 1;

  &Nav {
    width: 100%;
    background-color: v.$blackPale;

    &_cont {
      width: 100%;
      display: flex;
      align-items: center;

      @include m.sp {
        padding: 3rem 1rem;
        flex-wrap: wrap;
      }
    }

    &_list {
      width: 17%;

      @include m.sp {
        width: 33%;

        &:nth-child(n + 4) {
          margin: 2rem 0 0;
        }
      }
    }

    &_item {
      padding: 32px 0;
      display: block;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.06em;
      text-align: center;
      color: v.$white;
      position: relative;
      z-index: 1;

      @include m.pc {
        &:after {
          content: '';
          width: 0;
          height: 10px;
          background: v.$gradGreen;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          transition: width 0.15s v.$animeBezierSingle02;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      @include m.sp {
        padding: 0;
        font-size: 1.4rem;
        text-align: left;
      }
    }
  }

  &Main {
    width: 100%;
    padding: 50px 0;
    background-color: v.$white;

    @include m.sp {
      padding: 3rem 0;
    }
  }

  &Arrow {
    width: 100%;
    margin: 0 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.sp {
      margin: 0 0 3rem;
      display: block;
    }

    &_list {
      width: 33%;

      @include m.pc {
        max-width: 372px;

        & + & {
          border-left: 1px solid v.$gray;
        }
      }

      @include m.sp {
        width: 100%;
        border-bottom: 1px solid v.$gray;
      }
    }

    &_item {
      width: 100%;
      padding: 24px 48px 24px 20px;
      display: block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5714285714285714;
      position: relative;
      z-index: 1;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;

          .l-footerArrow_ico {
            transform: translateX(8px);
          }
        }
      }

      @include m.tab {
        padding: 24px 24px 24px 20px;
        font-size: 12px;
      }

      @include m.sp {
        padding: 2rem 0.5rem;
        font-size: 1.2rem;
      }
    }

    &_text {
      backface-visibility: hidden;
    }

    &_ico {
      position: absolute;
      top: 50%;
      right: 48px;
      z-index: 2;

      @include m.pc {
        transition: transform v.$anime;
      }

      @include m.tab {
        right: 24px;
      }

      @include m.sp {
        right: 2rem;
      }
    }
  }

  &Info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      margin: 0 0 2rem;
      display: block;
    }

    &_cont {
      width: 100%;
      display: flex;
      align-items: center;

      @include m.sp {
        flex-wrap: wrap;
      }
    }

    &_list {
      $this: &;
      margin: 0 20px;

      @include m.sp {
        width: 33%;
        margin: 0;

        &:nth-child(n + 4) {
          margin: 2rem 0 0;
        }
      }

      &--half {
        &--sp {
          @extend #{$this};

          @include m.sp {
            width: 50%;
          }
        }
      }
    }

    &_item {
      $this: &;
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5833333333333333;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.sp {
        font-size: 1.2rem;
      }

      &--line {
        @extend #{$this};
        display: inline-flex;
        align-items: center;
        text-decoration: underline;
      }

      &Ico {
        width: 12px;
        margin: 0 4px;
        display: block;
      }
    }

    &_text {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5833333333333333;

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }

  &Sns {
    display: flex;
    align-items: center;

    @include m.sp {
      width: 100%;
      margin: 4rem 0 0;
      justify-content: center;
    }

    &_list {
      & + & {
        margin: 0 0 0 16px;

        @include m.sp {
          margin: 0 0 0 3rem;
        }
      }
    }

    &_item {
      width: 26px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      img {
        width: auto;
        height: 100%;
        margin: 0 auto;
      }

      @include m.sp {
        width: 3.5rem;
        height: 3rem;
      }
    }
  }

  &Other {
    width: 100%;
    height: 64px;
    padding: 12px 0 36px;
    background: v.$gradGreen;

    @include m.sp {
      height: 10rem;
      padding: 2.4rem 0 2rem;
    }

    &.is-fixed {
      .l-footerBack {
        position: absolute;
        bottom: 64px;

        @include m.sp {
          bottom: 10rem;
        }
      }
    }

    &_wrap {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        padding: 0;
        display: block;
      }
    }

    &_cont {
      display: flex;
      align-items: center;

      @include m.sp {
        width: 100%;
        margin: 0 0 1.5rem;
      }
    }

    &_list {
      @include m.sp {
      }

      & + & {
        margin: 0 0 0 28px;
      }
    }

    &_item {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5833333333333333;
      color: v.$white;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_copy {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5833333333333333;
      color: v.$white;
      position: relative;
      z-index: 1;

      @include m.sp {
        text-align: right;
      }
    }
  }

  &Back {
    width: 64px;
    height: 64px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9990;
    opacity: 0;
    pointer-events: none;
    transition: opacity v.$anime;

    @include m.sp {
      width: 5.5rem;
      height: 5.5rem;
    }

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    &_item {
      width: 100%;
      height: 100%;
      display: block;
      background-color: v.$green;
      border: 2px solid v.$green;
      position: relative;
      z-index: 1;

      @include m.pc {
        transition: background-color v.$anime;

        &:before,
        &:after {
          transition: border-color v.$anime;
        }

        &:hover {
          background-color: v.$white;

          &:before,
          &:after {
            border-color: v.$green;
          }
        }
      }

      &:before,
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-top: 2px solid v.$white;
        border-right: 2px solid v.$white;
        position: absolute;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%) rotate(-45deg);

        @include m.sp {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &:before {
        top: 48%;
      }

      &:after {
        top: 68%;
      }
    }
  }
}
