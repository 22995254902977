@use "global/mixin" as m;
@use "global/variable" as v;

.c-btn {
  $this: &;
  width: 100%;
  height: 100%;
  padding: 24px 60px 24px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    width: 100%;
    z-index: -2;

    @include m.sp {
      display: none;
    }
  }

  &:after {
    width: 0;
    z-index: -1;
  }

  @include m.pc {
    cursor: pointer;
    transition: color 0.15s v.$animeBezierSingle02, border-color 0.15s v.$animeBezierSingle02;

    &:after {
      transition: width 0.15s v.$animeBezierSingle02, height 0.15s v.$animeBezierSingle02;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  @include m.tab {
    padding: 24px 40px 24px 24px;
  }

  @include m.sp {
    padding: 1.5rem 3.5rem 1.5rem 1rem;
    letter-spacing: 0.05em;

    &:after {
      width: 100%;
    }
  }

  &--black {
    @extend #{$this};
    color: v.$white;

    &:before {
      background-color: v.$blackPale;
    }

    &:after {
      background: v.$gradGreen;
    }

    &--inner {
      @extend .c-btn--black;
      margin: 0 auto;

      @include m.sp {
        margin: 0 auto;
      }
    }
  }

  &Type01 {
    @extend #{$this};
    width: 100%;
    color: v.$white;

    &:before {
      @include m.pc {
        background-color: v.$blackPale;
      }
    }

    &:after {
      background: v.$gradGreen;
    }

    &--inner {
      @extend .c-btn--black;
      margin: 0 auto;

      @include m.sp {
        margin: 0 auto;
      }
    }

    &--nolink {
      @extend .c-btnType01;
      pointer-events: none;

      @include m.sp {
        &:before {
          display: block;
          background-color: v.$blackPale;
        }
        &:after {
          display: none;
        }
      }
    }

    &--pc {
      @extend .c-btnType01;

      @include m.sp {
        color: v.$white;

        &:before {
          display: block;
          background-color: v.$blackPale;
        }

        &:after {
          width: 0;
        }
      }
    }

    &--reverse {
      @extend .c-btnType01;
      padding: 24px 24px 24px 60px;
      justify-content: flex-end;

      @include m.sp {
        padding: 1.5rem 1rem 1.5rem 3.5rem;
      }

      &:after {
        transform: rotateY(180deg);
      }

      .c-btn {
        &_text {
          order: 1;
        }

        &_arrow {
          right: auto;
          left: 20px;
          transform: translateY(-50%) rotateY(180deg);

          @include m.sp {
            right: auto;
            left: 1rem;
          }
        }

        &_pdf,
        &_external {
          right: auto;
          left: 30px;
          transform: translateY(-50%) rotateY(180deg);

          @include m.sp {
            right: auto;
            left: 1rem;
          }
        }
      }
    }

    &--bgWhite {
      &--sp {
        @extend .c-btnType01;
        @include m.sp {
          color: v.$black;

          &:after {
            background: v.$white;
          }

          .c-btn {
            &_arrow {
              background: url('../img/common/ico_arrow_black.svg') no-repeat center;
              background-size: contain;
            }

            &_external {
              background: url('../img/common/ico_external_black.svg') no-repeat center;
              background-size: contain;
            }

            &_modal {
              background: url('../img/common/ico_modal_black.svg') no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }
  }

  &Type02 {
    @extend #{$this};
    width: 100%;
    color: v.$blackPale;
    border: 1px solid v.$blackPale;

    @include m.sp {
      color: v.$white;
      border: none;
    }

    &:before {
      background-color: v.$white;
    }

    &:after {
      background: v.$gradGreen;
    }

    .c-btn {
      &_arrow {
        @include m.sp {
          background-image: url('../img/common/ico_arrow_white.svg');
        }
      }
    }

    &:hover {
      color: v.$white;
      border-top-color: v.$green;
      border-bottom-color: v.$green;
      border-left-color: v.$green;
      border-right-color: v.$greenPale;

      .c-btn {
        &_arrow {
          &--black {
            background-image: url('../img/common/ico_arrow_white.svg');
          }
        }
      }
    }
  }

  &Type03 {
    @extend #{$this};
    width: 100%;
    color: v.$green;
    border: 1px solid v.$green;

    @include m.sp {
      padding: 2rem 4rem 2rem 2rem;
      color: v.$white;
      border: none;
    }

    &:before {
      background-color: v.$white;
    }

    &:after {
      background: v.$gradGreen;
    }

    .c-btn {
      &_tri {
        border-color: v.$green;

        @include m.sp {
          border-color: v.$white;
        }
      }

      &_external {
        @include m.sp {
          background-image: url('../img/common/ico_external_white.svg');
        }
      }
    }

    &:hover {
      @include m.pc {
        color: v.$white;
        border-right-color: v.$greenPale;

        .c-btn {
          &_tri {
            border-color: v.$white;
          }

          &_external {
            background-image: url('../img/common/ico_external_white.svg');
          }
        }
      }
    }

    /* 矢印が上向きだった場合 */
    &.is-active {
      .c-btn {
        &_tri {
          transform: translateY(-50%) rotate(-135deg);
        }
      }
    }
    /* end 矢印が上向きだった場合 */
  }

  &Type04 {
    @extend #{$this};
    width: 100%;
    padding: 11px 36px;
    border: 1px solid v.$green;

    @include m.tab {
      padding: 9px 28px;
    }

    @include m.sp {
      padding: 1.2rem 1rem;
    }

    &:before {
      content: '';
      @include m.opacity();
    }

    &:after {
      display: none;
    }

    &--nolink {
      @extend .c-btnType04;
      pointer-events: none;
    }

    .c-btn {
      &_logo {
        max-width: 280px;
        width: 100%;
        height: 50px;

        @include m.sp {
          margin: 0 auto;
        }

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }

    &:hover {
      @include m.pc {
        &:before {
          opacity: 0.3;
        }
      }
    }
  }

  &Type05 {
    @extend #{$this};
    width: 100%;
    padding: 21px 60px 21px 36px;
    color: v.$white;

    @include m.tab {
      padding: 20px 40px 20px 24px;
    }

    @include m.sp {
      padding: 3rem 4rem 3rem 1rem;
      border: none;
    }

    &:before {
      background-color: v.$blackPale;
    }

    &:after {
      background: v.$gradGreen;
    }

    .c-btn {
      &_logo {
        max-width: 280px;
        width: 100%;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }
  }

  &Type06 {
    @extend #{$this};
    width: 100%;
    padding: 11px 0 11px 36px;
    border: 1px solid v.$green;

    @include m.tab {
      padding: 9px 28px;
    }

    @include m.sp {
      padding: 1.2rem 1rem;
    }

    &:before {
      content: '';
      @include m.opacity();
    }

    &:after {
      display: none;
    }

    .c-btn {
      &_logo {
        width: 100%;
        height: 50px;

        @include m.pc {
          max-width: 217px;
        }

        @include m.sp {
          width: 78%;
        }

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }

    &:hover {
      @include m.pc {
        &:before {
          opacity: 0.3;
        }
      }
    }
  }

  &_badge {
    width: 40px;
    height: 30px;
    margin-right: 16px;
    display: block;

    @include m.tab {
      width: 28px;
      height: 24px;
      margin-right: 12px;
    }

    @include m.sp {
      width: 4rem;
      height: 3rem;
      margin-right: 1.2rem;
    }

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  &_text {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &_arrow {
    $this: &;
    width: 28px;
    height: 10px;
    display: block;
    background: url('../img/common/ico_arrow_white.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: calc(50% - 3px);
    right: 20px;
    z-index: 2;
    transform: translateY(-50%);

    @include m.pc {
      transition: background-image 0.15s v.$animeBezierSingle02;
    }

    @include m.tab {
      width: 24px;
      height: 8px;
      top: calc(50% - 4px);
      right: 12px;
    }

    @include m.sp {
      width: 2.2rem;
      height: 1rem;
      top: calc(50% - 1px);
      right: 1rem;
    }

    &--black {
      @extend #{$this};
      background: url('../img/common/ico_arrow_black.svg') no-repeat center;
      background-size: contain;
    }
  }

  &_tri {
    width: 6px;
    height: 6px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(45deg);

    @include m.sp {
      right: 1.5rem;
    }
  }

  &_external {
    $this: &;
    width: 26px;
    height: 26px;
    display: block;
    background: url('../img/common/ico_external_white.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    @include m.pc {
      transition: background-image 0.15s v.$animeBezierSingle02;
    }

    @include m.tab {
      width: 18px;
      height: 18px;
      right: 16px;
    }

    @include m.sp {
      width: 1.8rem;
      height: 1.8rem;
      right: 1rem;
    }

    &--green {
      @extend #{$this};
      background: url('../img/common/ico_external_green.svg') no-repeat center;
      background-size: contain;
    }

    &--black {
      @extend #{$this};
      background: url('../img/common/ico_external_black.svg') no-repeat center;
      background-size: contain;
    }
  }

  &_modal {
    $this: &;
    width: 26px;
    height: 26px;
    display: block;
    background: url('../img/common/ico_modal_white.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    @include m.pc {
      transition: background-image 0.15s v.$animeBezierSingle02;
    }

    @include m.tab {
      width: 18px;
      height: 18px;
      right: 16px;
    }

    @include m.sp {
      width: 1.8rem;
      height: 1.8rem;
      right: 1rem;
    }
  }

  &_pdf {
    width: 25px;
    height: 33px;
    display: block;
    background: url('../img/common/ico_pdf_white.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    @include m.tab {
      width: 20px;
      height: 28px;
      right: 20px;
    }

    @include m.sp {
      width: 2rem;
      height: 3rem;
      right: 1rem;
    }
  }

  /* ボタン単体運用時（中央配置） */
  &Wrap {
    $this: &;
    max-width: 350px;
    width: 100%;
    margin: 80px auto 0;

    @include m.sp {
      width: 75%;
      margin: 4rem auto 0;
    }

    &--inner {
      @extend #{$this};
      margin: 0 auto;

      @include m.sp {
        margin: 0 auto;
      }

      & + .c-btnWrap--inner {
        @include m.sp {
          margin-top: 1.5rem;
        }
      }
    }
  }
  /* end ボタン単体運用時（中央配置） */

  /* ボタン単体運用時（サイズ指定のみでの設置） */
  &Box {
    $this: &;
    max-width: 350px;
    width: 100%;

    @include m.sp {
      width: 80%;
    }

    &--pc {
      @extend #{$this};

      @include m.sp {
        margin: 0 auto;
      }
    }
  }
  /* end ボタン単体運用時（サイズ指定のみでの設置） */

  &Full {
    &--sp {
      @extend .c-btnWrap--inner;

      @include m.sp {
        width: 100%;
        margin: 0;
      }
    }
  }
}
