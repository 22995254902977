@charset "UTF-8";

/* transition */
$anime: 0.3s ease;
$animeBezier: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
$animeBezier02: 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$animeBezierSingle: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$animeBezierSingle02: cubic-bezier(0.55, 0.055, 0.675, 0.19);

/* color */
$white: #fff;
$whitePale: #f2f2f2;

$black: #000;
$blackPale: #454545;

$gray: #d8d6d6;
$grayPale: #f7f7f7;
$grayPale02: #eee;
$grayDeep: #808080;
$grayDeep02: #bfbfbf;

$yellow: #ffeb00;
$yellowGeimin: #ffcd46;

$green: #39ba70;
$greenPale: #8fdb5f;
$greenPale02: #f0f8f2;
$greenPale03: #eef9f3;
$gradGreen: linear-gradient(to right, $green 0%, $greenPale 100%);
$gradGreenPale: linear-gradient(to right, rgba($green, 0.95) 0%, rgba($greenPale, 0.95) 100%);

$redPale: #ef7373;

$pinkDeep: #fecccc;

$blue: #3948ba;
$blueDeep: #2b3689;

/* font */
$petch: 'Chakra Petch', sans-serif;
