@use "global/mixin" as m;
@use "global/variable" as v;

.p-index {
  &Hero {
    &_wrap {
      width: 100%;
      padding: 100px 0 120px;
      background: rgba(v.$black, 0.7);

      @include m.sp {
        padding: 5rem 0 6rem;
      }
    }

    &_cont {
      width: 100%;
      margin: 0 0 180px;
      text-align: center;

      @include m.sp {
        margin: 0 0 10rem;
      }
    }

    &_text {
      font-size: 18px;
      font-weight: 500;
      line-height: 2.1;
      letter-spacing: 0.06em;
      color: v.$white;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.4rem;
        line-height: 1.8;
      }

      & + & {
        padding: 32px 0 0;

        @include m.sp {
          padding: 2rem 0 0;
        }
      }
    }

    &_lead {
      width: 100%;
      margin: 60px 0 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @include m.sp {
        margin: 4rem 0 0;
      }

      &Inner {
        width: 100%;
        height: 48px;

        @include m.sp {
          height: 3rem;
        }

        & + & {
          margin: 60px 0 0;

          @include m.sp {
            margin: 3rem 0 0;
          }
        }

        img {
          width: auto;
          height: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  &Topics {
    width: 100%;
    margin: 0 0 76px;

    @include m.sp {
      margin: 0 0 6rem;
    }
  }

  &Information {
    width: 100%;

    @include m.sp {
    }
  }

  /* 夢 x 仕事 */
  &Dream {
    &_wrap {
      width: 100%;
      padding: 120px 0 240px;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @include m.sp {
        padding: 6rem 0;
      }

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(v.$white, 0.95);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
      }
    }

    &_cont {
      width: 100%;

      & + & {
        margin: 140px 0 0;

        @include m.sp {
          margin: 6rem 0 0;
        }
      }
    }

    &_box {
      width: 100%;

      & + & {
        margin: 48px 0 0;

        @include m.sp {
          margin: 3rem 0 0;
        }
      }
    }

    &_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &Box {
        width: 852px;
        height: 852px;
        background: v.$gradGreen;
        position: absolute;

        @include m.sp {
          width: 32rem;
          height: 32rem;
        }

        &--left {
          &Center {
            @extend .p-indexDream_bgBox;
            top: 32.5%;
            left: -33.5%;
            transform: translate(-50%, -50%) rotate(135deg);
            transform-origin: center;

            @include m.sp {
              top: 14.5%;
            }
          }

          &Btm {
            @extend .p-indexDream_bgBox;
            top: 100%;
            left: 0;
            transform: translate(-50%, -50%) rotate(-45deg);
            transform-origin: center;

            @include m.sp {
              width: 20rem;
              height: 20rem;
            }
          }
        }

        &--right {
          &Center {
            @extend .p-indexDream_bgBox;
            top: 32.5%;
            left: 133.5%;
            transform: translate(-50%, -50%) rotate(-45deg);
            transform-origin: center;

            @include m.sp {
              top: 14.5%;
            }
          }

          &Btm {
            @extend .p-indexDream_bgBox;
            top: 100%;
            left: 100%;
            transform: translate(-50%, -50%) rotate(-45deg);
            transform-origin: center;

            @include m.sp {
              width: 20rem;
              height: 20rem;
            }
          }
        }
      }
    }
  }
  /* end 夢 x 仕事 */

  /* 表現 x 創造 */
  &Creation {
    width: 100%;
    padding: 0 0 120px;
    background-color: rgba(v.$black, 0.7);
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include m.sp {
      padding: 0 0 6rem;
    }

    &_course {
      width: 100%;
      margin: 0 0 120px;
      overflow: hidden;

      @include m.sp {
        marign: 0 0 6rem;
      }
    }

    &_recommend {
      width: 100%;

      & + & {
        margin: 240px 0 0;

        @include m.sp {
          margin: 5rem 0 0;
        }
      }
    }
  }
  /* end 表現 x 創造 */

  /* 教員 x 一流 */
  &Teacher {
    width: 100%;

    &_wrap {
      width: 100%;
      padding: 120px 0;
      background-color: rgba(v.$white, 0.9);
      position: relative;
      z-index: 1;
      overflow: hidden;

      @include m.sp {
        padding: 6rem 0 8rem;
      }
    }

    &_slide {
      @include m.sp {
        margin: 8rem 0;
      }

      & + & {
        @include m.pc {
          margin-top: 240px;
        }

        @include m.sp {
          margin-top: 12rem;
        }
      }
    }
  }
  /* end 教員 x 一流 */

  /* 未経験 x 安心 */
  &Education {
    width: 100%;

    &_wrap {
      width: 100%;
      padding: 120px 0;
      background-color: rgba(v.$white, 0.9);
      position: relative;
      z-index: 1;
      overflow: hidden;

      @include m.sp {
        padding: 6rem 0;
      }
    }

    &_heading {
      width: 100%;
      margin: 0 0 120px;
      display: flex;
      justify-content: center;

      @include m.sp {
        margin: 0 0 6rem;
        display: block;
      }
    }

    &_chart {
      width: 100%;
      margin: 0 0 108px;

      @include m.sp {
        margin: 0 0 6rem;
      }
    }
  }
  /* end 未経験 x 安心 */

  /* 設備 x 実践 */
  &Facility {
    width: 100%;
    position: relative;
    z-index: 1;

    &_btn {
      width: 100%;
      padding: 112px 0;
      background-color: rgba(v.$white, 0.9);

      @include m.sp {
        padding: 5rem 0;
      }
    }
  }
  /* end 設備 x 実践 */

  /* 学費 x 奨学金 */
  &Scholarship {
    width: 100%;

    &_wrap {
      width: 100%;
      padding: 120px 0 160px;
      background-color: rgba(v.$white, 0.9);
      position: relative;
      z-index: 1;

      @include m.sp {
        padding: 6rem 0 8rem;
      }
    }

    &_cont {
      width: 100%;

      & + & {
        margin: 160px 0 0;

        @include m.sp {
          margin: 8rem 0 0;
        }
      }
    }

    &_note {
      width: 100%;
      margin: 100px 0 0;
      border-top: 1px solid v.$gray;

      @include m.sp {
        margin: 5rem 0 0;
      }
    }

    &_other {
      width: 100%;
      margin: 60px 0 0;
      display: flex;
      justify-content: center;

      @include m.sp {
        margin: 3rem 0 0;
      }

      &Text {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5833333333333333;
        text-indent: -1em;
        padding: 0 0 0 1em;

        @include m.sp {
          font-size: 1.2rem;
          text-indent: -0.5em;
          padding: 0 0 0 0.5em;
        }
      }
    }
  }
  /* end 学費 x 奨学金 */
}
