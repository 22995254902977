@use "global/mixin" as m;
@use "global/variable" as v;

/* 秘密の芸民SHOWページ用scssファイルです */

@keyframes poyon {
  0% {
    transform: scale(1, 1) translate(0%, 0%);
    opacity: 0;
  }
  15% {
    transform: scale(0.9, 0.9) translate(0%, 5%);
  }
  30% {
    transform: scale(1.3, 0.8) translate(0%, 10%);
  }
  50% {
    transform: scale(0.8, 1.3) translate(0%, -10%);
  }
  70% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  100% {
    transform: scale(1, 1) translate(0%, 0%);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translate(-100px, 60px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes slideRight {
  0% {
    transform: translate(100px, 60px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.p-geiminShow {
  color: #fff;
  text-align: center;
  font-weight: bold;

  &_anime {
    opacity: 0;
    transform: translateY(10vh);
    transition: opacity 1s ease, transform 1s ease;

    &--pudding {
      opacity: 0;

      &.is-view {
        animation: poyon 1s;
        opacity: 1;
      }
    }

    &--slideLeft {
      opacity: 0;

      &.is-view {
        animation: slideLeft 0.3s;
        opacity: 1;
      }
    }

    &--slideRight {
      opacity: 0;

      &.is-view {
        animation: slideRight 0.3s;
        opacity: 1;
      }
    }

    &.is-view {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &_mv {
    margin-top: 64px;

    @include m.tab02 {
      margin-top: 5rem;
    }
  }

  &_body {
    background-image: linear-gradient(to right, v.$blue 50%, v.$blueDeep 50%);
    padding: 1px 0 200px;

    @include m.sp {
      background: v.$blue;
      padding-bottom: 8rem;
    }
  }

  &_about {
    margin: 40px 0 140px;

    @include m.sp {
      margin: 2rem 0 6rem;
    }

    &_ttl {
      height: 64px;

      @include m.sp {
        height: auto;
      }

      img {
        height: 100%;
        width: auto;
        margin: auto;

        @include m.sp {
          width: 100%;
          height: auto;
        }
      }
    }

    &_subttl {
      color: v.$yellowGeimin;
      margin-top: 25px;
      font-size: 24px;
      letter-spacing: 0.06em;

      @include m.sp {
        margin-top: 1.5rem;
        font-size: 1.2rem;
      }
    }

    &_txt {
      margin-top: 80px;
      font-size: 18px;
      line-height: 2;

      @include m.sp {
        font-size: 1.2rem;
        margin-top: 5rem;
      }
    }

    &_point {
      margin-top: 80px;
      font-size: 36px;

      @include m.sp {
        font-size: 1.8rem;
        margin-top: 5rem;
      }
    }
  }

  &_divider {
    position: relative;
    margin-top: 120px;
    padding-top: 120px;

    @include m.sp {
      margin-top: 6rem;
      padding-top: 6rem;
    }

    &::before {
      content: '';
      display: block;
      background-image: url('../img/campuslife/geimin_show/icon_wave.svg');
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 100%;
      height: 18px;
      opacity: 0.4;
      position: absolute;
      left: 0;
      top: 0;

      @include m.sp {
        background-image: url('../img/campuslife/geimin_show/icon_wave_sp.svg');
      }
    }
  }

  &_block {
    @include m.pc {
      overflow: hidden;
    }

    &_ttl {
      margin-bottom: 100px;

      @include m.sp {
        margin-bottom: 6rem;
        width: 100vw;
        transform: translateX(-3.5vw);
        overflow: hidden;
      }

      img {
        @include m.sp {
          width: 150vw;
          transform: translateX(-25vw);
        }
      }
    }
  }

  &_box {
    & + .p-geiminShow_box {
      margin-top: 120px;
      padding-top: 120px;

      @include m.sp {
        margin-top: 6rem;
        padding-top: 6rem;
      }
    }

    &_head {
    }

    &_q {
      height: 70px;
      width: auto;
      margin: auto;

      @include m.sp {
        height: 3.5rem;
      }
    }

    &_ttl {
      margin: 20px auto 80px;
      max-width: 100%;
      width: 920px;
      border-radius: 4em;
      font-size: 24px;
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: v.$blackPale;
      background: rgba(255, 255, 255, 0.8);
      letter-spacing: 0.06em;

      @include m.sp {
        margin: 1rem auto 4rem;
        font-size: 1.2rem;
        padding: 2rem 1rem;
        height: auto;
      }
    }

    &_row {
      display: flex;
      justify-content: space-evenly;

      @include m.sp {
        flex-direction: column;
      }
    }

    &_item {
      @include m.pc {
        margin: 0 15px;
      }

      &:nth-child(2n) {
        @include m.sp {
          background-color: v.$blueDeep;
          width: 100vw;
          transform: translateX(-3.5vw);
          padding: 4rem 0 5rem;
          margin-top: 5rem;
        }
      }
    }

    &_class {
      font-size: 36px;
      margin-bottom: 60px;

      @include m.sp {
        font-size: 1.8rem;
        margin-bottom: 4rem;
      }
    }

    &_arrow {
      width: 18px;
      height: 22px;
      margin: 20px auto 0;

      @include m.sp {
        width: 0.9rem;
        height: 1.1rem;
        margin-top: 1rem;
      }
    }

    &_chart {
      width: auto;
      margin: auto;
      // max-width: 50vw;

      @include m.pc {
        width: 80%;
        height: auto;
        max-width: 100%;
      }

      @include m.sp {
        width: 70%;
        height: auto;
      }
    }

    &_person {
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;

      @include m.sp {
        margin-top: 4rem;
        gap: 2.5rem;
      }

      &Img {
        width: 190px;
        height: 190px;
        flex-shrink: 0;

        @include m.tab02 {
          width: 140px;
          height: 140px;
        }

        @include m.sp {
          width: 9.5rem;
          height: 9.5rem;
        }
      }

      &Txt {
        line-height: 2;
        width: 195px;
        text-align: left;

        @include m.sp {
          font-size: 1.2rem;
          width: 12em;
        }
      }
    }

    &_result {
      margin: 80px auto 0;

      @include m.sp {
        margin-top: 5rem;
      }

      &Ttl {
        height: 72px;
        width: auto;
        margin: auto;

        @include m.sp {
          height: 3.6rem;
        }
      }

      &List {
        max-width: 100%;
        width: 920px;
        margin: 20px auto 0;
        border-radius: 60px;
        border: 5px solid v.$yellowGeimin;
        background-color: v.$green;
        padding: 40px 50px 40px 60px;
        text-align: left;
        line-height: 2;

        @include m.sp {
          margin-top: 1rem;
          padding: 2rem 3rem;
          border-radius: 3rem;
          border-width: 3px;
        }
      }

      &Item {
        font-size: 24px;

        @include m.sp {
          font-size: 1.2rem;
        }

        &::before {
          content: '';
          display: inline-block;
          color: v.$yellowGeimin;
          width: 0.8em;
          height: 0.8em;
          background-image: url('../img/campuslife/geimin_show/icon_check.svg');
          background-size: contain;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          margin-right: 10px;

          @include m.sp {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &_bnrs {
    display: flex;
    margin-bottom: 100px;

    @include m.tab02 {
      flex-direction: column;
    }

    @include m.sp {
      margin-bottom: 4rem;
    }

    .p-dpBnr {
      & + .p-dpBnr {
        @include m.sp {
          border-top: 1px solid #fff;
        }
      }

      &_item {
        @include m.sp {
          height: 16em;
        }
      }

      &_box {
        &::after {
          @include m.sp {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgb(0, 0, 0) 150%);
          }
        }
      }

      &_text {
        height: 40px;

        @include m.sp {
          height: 3rem;
        }
      }

      &_inner {
        max-width: 448px;
        width: 90%;
        margin: auto;
        padding-top: 3vw;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;

        @include m.tab02 {
          padding-top: 0;
          align-content: center;
        }
      }

      &_msg {
        width: fit-content;
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        margin-top: 20px;
        line-height: 2;

        @include m.sp {
          margin-top: 1rem;
          font-size: 1.2rem;
          line-height: 1.5;
        }
      }
    }
  }
}
