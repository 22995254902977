@use "global/mixin" as m;
@use "global/variable" as v;

.c-intro {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &_heading {
    margin: 0 0 84px;

    @include m.sp {
      margin: 0 0 4rem;
    }

    &--line {
      width: auto;
      height: 62px;
      margin: 0 0 84px;
      position: relative;
      z-index: 1;

      @include m.sp {
        height: 2.8rem;
        margin: 0 0 4rem;
      }

      &:after {
        content: '';
        width: 0;
        height: 20px;
        background: v.$gradGreen;
        position: absolute;
        top: calc(100% + 16px);
        left: -8px;
        z-index: 2;
        transform: skew(30deg);
        transition: width 0.4s v.$animeBezierSingle;

        @include m.sp {
          width: calc(100% + 1rem);
          height: 1rem;
          top: calc(100% + 8px);
          left: -4px;
        }
      }

      img {
        width: auto;
        height: 100%;
        margin: 0 auto;
      }

      &.is-view {
        &:after {
          width: calc(100% + 16px);

          @include m.sp {
            width: calc(100% + 8px);
          }
        }
      }
    }
  }

  /* 80px間隔保つ見出し + テキスト */
  &Cont {
    width: 100%;

    &.swiper {
      @include m.sp {
        padding: 0 4rem;
      }
    }

    & + & {
      margin: 80px 0 0;

      @include m.tab {
        margin: 60px 0 0;
      }

      @include m.sp {
        margin: 4rem 0 0;
      }
    }

    &_head {
      width: 100%;
      margin: 0 0 80px;

      @include m.tab {
        margin: 0 0 60px;
      }

      @include m.sp {
        margin: 0 0 4rem;
      }
    }

    &_body {
      & + & {
        margin: 80px 0 0;

        @include m.tab {
          margin: 60px 0 0;
        }

        @include m.sp {
          margin: 4rem 0 0;
        }
      }
    }
  }
  /* end 80px間隔保つ見出し + テキスト */

  /* 40px間隔保つ見出し + テキスト */
  &Unit {
    width: 100%;

    & + & {
      margin: 40px 0 0;

      @include m.tab {
        margin: 32px 0 0;
      }

      @include m.sp {
        margin: 2rem 0 0;
      }
    }

    &_head {
      width: 100%;
      margin: 0 0 28px;

      @include m.tab {
        margin: 0 0 20px;
      }

      @include m.sp {
        margin: 0 0 1.5rem;
      }
    }

    &_body {
      & + & {
        margin: 40px 0 0;

        @include m.tab {
          margin: 32px 0 0;
        }

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }
  }
  /* end 40px間隔保つ見出し + テキスト */

  /* 20px間隔保つ見出し + テキスト(Unit内で使用想定) */
  &Inner {
    width: 100%;

    & + & {
      margin: 20px 0 0;

      @include m.tab {
        margin: 12px 0 0;
      }

      @include m.sp {
        margin: 1rem 0 0;
      }
    }

    &_head {
      width: 100%;
      margin: 0 0 16px;

      @include m.tab {
        margin: 0 0 8px;
      }

      @include m.sp {
        margin: 0 0 1rem;
      }
    }

    &_body {
      & + & {
        margin: 20px 0 0;

        @include m.tab {
          margin: 12px 0 0;
        }

        @include m.sp {
          margin: 1rem 0 0;
        }
      }
    }
  }
  /* end 20px間隔保つ見出し + テキスト(Unit内で使用想定) */
}
