@use "global/mixin" as m;
@use "global/variable" as v;

/* オープンキャンパスページ用scssファイルです */

%underline {
  background: linear-gradient(180deg, transparent 0%, transparent 65%, #e1f450 65%, #e1f450 90%, transparent 90%);
}

.p-oc {
  &Breadcrumbs {
    color: #fff;
    .c-breadcrumbs_list + .c-breadcrumbs_list:before {
      border-right-color: #fff;
      border-bottom-color: #fff;
    }
    .c-breadcrumbs_item:hover {
      color: #fff;
    }
    &_outer {
      padding: 0.1px;
    }
  }
  &Lead {
    margin-top: 4em;
  }
  &Merit {
    overflow: hidden;
    background: url(../img/admission/opencampus/merit_bg.png) top center;
    @include m.sp {
      padding-right: 2%;
      padding-left: 2%;
    }
    img {
      display: inline;
      width: auto;
      max-width: 100%;
      vertical-align: bottom;
    }
    &_heading {
      @include m.sp {
        font-size: 1.8rem;
      }
    }
    &_lead {
      margin: 4em auto;
      text-align: center;

      @include m.sp {
        margin-bottom: 0;
      }
    }
    &Point {
      &Head {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 2em;
        @include m.pc {
          margin-bottom: -80px;
        }
        @include m.sp {
          position: relative;
          margin-bottom: 3em;
        }

        &--re {
          .p-ocMeritPointHead_pic {
            @include m.sp {
              right: unset;
              left: 0;
            }

            &--04 {
              @include m.sp {
                left: -5%;
              }
            }
          }

          .p-ocMeritPointHead_txt {
            @include m.sp {
              margin-left: auto;
            }
          }
        }

        &_head {
          margin: 0 2%;
          width: 52%;
          max-width: 635px;

          @include m.sp {
            width: 100%;
          }
        }
        &_pic {
          margin: 0 2%;
          width: 40%;
          max-width: 370px;
          @include m.sp {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        &_headingWrap {
          $this: &;
          position: relative;
          margin-bottom: 1em;
          width: 100%;
          @include m.pc {
            padding: 10px 0 0 10px;
            padding-bottom: 30.4%;
            background: url(../img/admission/opencampus/point_brackets.png) no-repeat top left / contain;
          }
          &::before {
            position: absolute;
            top: 20%;
            left: 8%;
            width: 11.82%;
            padding-bottom: 18.6%;
            background: no-repeat center / contain;
            content: '';
            @include m.pc {
              left: 14%;
            }
          }
          @for $i from 1 through 4 {
            &--#{$i} {
              @extend #{$this};
              &::before {
                background-image: url(../img/admission/opencampus/point_#{$i}.png);
              }
            }
          }
        }
        &_heading {
          @include m.sp {
            width: 100%;
            padding: 2rem 0 2rem 9rem;
            position: relative;
            font-size: 1.8rem;
            letter-spacing: 0.06em;
            line-height: 1.75;
            z-index: 1;

            &:before,
            &:after {
              content: '';
              width: 2.5rem;
              height: 2.5rem;
              position: absolute;
              z-index: -1;
            }

            &:before {
              background: url('../img/admission/opencampus/point_brackets_top.png') no-repeat left top;
              background-size: contain;
              top: 0.5rem;
              left: -0.5rem;
            }

            &:after {
              background: url('../img/admission/opencampus/point_brackets_btm.png') no-repeat right bottom;
              background-size: contain;
              bottom: 0.5rem;
              right: -0.5rem;
            }
          }
          @include m.pc {
            position: absolute;
            top: 50%;
            left: 30%;
            transform: translate(0, -50%);
          }
          > span {
            @extend %underline;
          }
        }
        &_txt {
          margin-bottom: 3em;
          line-height: 2.125;

          @include m.sp {
            width: 54%;
            font-size: 1.4rem;
            margin-bottom: 2em;
          }
        }
      }
      &_pic {
        position: relative;

        @include m.sp {
          width: 85%;
          margin: auto;
        }

        > img {
          position: relative;
          z-index: +1;
        }
        &::before {
          position: absolute;
          bottom: -10%;
          right: -10%;
          width: 100%;
          height: 100%;
          background: v.$gradGreen;
          content: '';
        }
      }
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 880px;
      &_item {
        $this: &;
        position: relative;
        @include m.sp {
          margin-bottom: 8em;
        }
        @include m.pc {
          width: 46%;
          cursor: pointer;

          &:hover {
            .p-ocMeritList_link {
              &:before {
                opacity: 0;
              }
              &:after {
                opacity: 1;
              }
            }
          }
        }
        &:last-child {
          @include m.sp {
            margin-bottom: 0;
          }
        }
        &::before {
          position: absolute;
          top: -10%;
          right: -10%;
          width: 100%;
          height: 100%;
          background: v.$gradGreen;
          content: '';
        }
        &--1 {
          @extend #{$this};
          &:nth-child(even) {
            @include m.pc {
              margin-top: 60px;
            }
          }
        }
        &--2 {
          @extend #{$this};
          &:nth-child(odd) {
            @include m.pc {
              margin-top: 60px;
            }
          }
        }
      }
      &_inner {
        position: relative;
        box-shadow: 20px 20px 40px rgba(#000, 0.16);
        padding-bottom: 0.1em;
        z-index: +1;
        background: #fff;
        @include m.pc {
          padding-bottom: 0.1em;
        }
      }
      &_ttl {
        padding: 20px 20px 0;
      }
      &_txt {
        margin-bottom: 1em;
        padding: 1em 20px 0;
        line-height: 2.125;
      }
      &_link {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 50px;
        background: url(../img/admission/opencampus/icon_window.svg) no-repeat center;
        background-size: 25px auto;

        &:before,
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;

          @include m.pc {
            transition: opacity v.$anime;
          }
        }

        &:before {
          background-color: v.$blackPale;
          opacity: 0;
          @include m.pc {
            opacity: 1;
          }
        }

        &:after {
          background: v.$gradGreen;
          opacity: 1;

          @include m.pc {
            opacity: 0;
          }
        }
      }
      &Teacher {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid v.$gray;
        margin: 0 20px;
        padding: 20px 0;
        &_pic {
          margin-right: 5%;
          width: 100px;
        }
        &_body {
          width: calc(95% - 100px);
        }
        &_ttl {
          display: inline-block;
          margin-bottom: 0.5em;
          padding: 0.2em 0.5em;
          background: v.$green;
          color: #fff;
        }
        &_course {
        }
        &_name {
        }
      }
    }
  }
  &Present {
    margin-top: 4em;
    &_item {
      background: #fff;
    }
    &_ttl {
      padding: 1em 1em 0.5em;
      > span {
        @extend %underline;
      }
    }
    &_txt {
      padding: 0 1em 1em;
      line-height: 2.125;
    }
  }
  &Voice {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4em;
    margin-bottom: 4em;
    @include m.sp {
      flex-direction: column;
    }
    &_wrap {
      overflow: hidden;
    }
    &_body {
      width: 100%;
      @include m.pc {
        width: 58%;
      }
    }
    &_pic {
      position: relative;
      @include m.sp {
        width: 77%;
        margin: auto;
      }
      @include m.pc {
        width: 38%;
      }
      > img {
        position: relative;
        z-index: +1;
      }
      &::before {
        position: absolute;
        bottom: -18%;
        right: -18%;
        width: 100%;
        height: 100%;
        background: v.$gradGreen;
        content: '';

        @include m.sp {
          bottom: -15%;
        }
      }
    }
    &_school,
    &_name,
    &_txt {
      margin-bottom: 1em;
      line-height: 2.125;
    }
    &_school {
      line-height: 1.5;
    }
    &_name {
      @include m.sp {
        font-size: 1.6rem;
      }
    }
    &_cource {
      display: block;
      font-weight: bold;

      @include m.sp {
        line-height: 1.5;
        font-size: 1.2rem;
      }
    }
    &_txt {
      position: relative;
      padding-top: 1em;
      line-height: 2.125;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 2px;
        background: v.$green;
        content: '';
      }
    }
  }
  &Step {
    counter-reset: num;
    margin-top: 4em;
    @include m.pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &_item {
      position: relative;
      margin-bottom: 4em;
      padding: 1.35em 0 0.5em;
      background: #fff;
      @include m.pc {
        margin-bottom: 2em;
        padding: 24px 0 8px;
        width: 30%;
      }
      &::before {
        position: absolute;
        top: 0.75em;
        left: 0.75em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        line-height: 1;
        background: v.$green;
        color: #fff;
        font-size: 2rem;
        text-align: center;
        counter-increment: num;
        content: counter(num);
        @include m.pc {
          top: 24px;
          left: 14px;
          width: 50px;
          height: 50px;
          font-size: 35px;
        }
      }
      &::after {
        position: absolute;
        opacity: 0.5;
        content: '';
        @include m.sp {
          bottom: -3em;
          left: 50%;
          border: 2em solid transparent;
          border-top: 2em solid #fff;
          border-bottom: 0;
          transform: translate(-50%, 0);
        }
        @include m.pc {
          top: 50%;
          right: calc(-20px - 5%);
          border: 20px solid transparent;
          border-left: 20px solid #fff;
          border-right: 0;
          transform: translate(0, -50%);
        }
      }
      &:last-child::after {
        display: none;
      }
    }
    &_ttl {
      margin-bottom: 1em;
      padding: 0 1em 0 3em;
      @include m.pc {
        padding-left: 80px;
      }
    }
    &_txt {
      margin-bottom: 1em;
      padding: 0 1em;
      line-height: 2.125;
    }
    &_pic {
      margin-bottom: 1em;
    }
  }
  &Access {
    &_heading {
      margin-bottom: 0.5em;
    }
  }
  &Fixed {
    position: fixed;
    bottom: 80px;
    right: 0;
    z-index: 9990;
    padding: 20px;
    width: 340px;
    background: rgba(#fff, 0.7);
    @include m.sp {
      display: none;
    }
    &_ttl {
      margin-bottom: 0.5em;
    }
    &_btn {
      margin-bottom: 1em;
    }
  }
}
