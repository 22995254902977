@use "global/mixin" as m;
@use "global/variable" as v;

.c-summary {
  width: 100%;
  padding: 80px 0;
  background: v.$gradGreen;
  position: relative;
  z-index: 1;

  @include m.sp {
    padding: 4rem 0;
  }

  &_cont {
    width: 100%;
    padding: 0 7%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.sp {
      padding: 0;
      flex-flow: column;
      justify-content: center;
    }

    & + & {
      margin: 40px 0 0;

      @include m.sp {
        margin: 4rem 0 0;
      }
    }

    &--inner {
      @extend .c-summary_cont;
      padding: 0 0 0 4%;

      @include m.sp {
        padding: 0;
        flex-flow: row;
      }

      .c-summary {
        &_head {
          margin: 0 28px 0 0;
        }

        &_body {
          @include m.sp {
            flex: 1;
          }

          & + .c-summary_head {
            margin: 0 0 0 28px;

            @include m.sp {
              margin: 0 0 0 1rem;
            }
          }
        }
      }
    }
  }

  &_head {
    width: 140px;
    height: 140px;
    margin: 0 40px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 2.125;
    text-align: center;
    color: v.$green;
    background-color: v.$white;
    border-radius: 50%;

    @include m.sp {
      width: 11rem;
      height: 11rem;
      margin: 0 0 2rem;
      font-size: 1.4rem;
      line-height: 1.6;
    }

    &--blank {
      @extend .c-summary_head;
      height: 0;
      opacity: 0;

      @include m.sp {
        display: none;
      }
    }
  }

  &_body {
    @include m.pc {
      flex: 1;
    }

    & + .c-summary_head {
      margin: 0 0 0 40px;
    }
  }

  &_heading {
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.5555555555555556;
    letter-spacing: 0.06em;
    color: v.$white;

    @include m.tab {
      font-size: 18px;
    }

    @include m.sp {
      font-size: 1.8rem;
    }

    &--inner {
      width: 100%;
      padding: 0 0 8px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.06em;
      color: v.$white;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        padding: 0 0 0.5rem;
        font-size: 1.8rem;
      }
    }
  }

  &_text {
    font-size: 16px;
    font-weight: bold;
    line-height: 2.125;
    color: v.$white;

    @include m.tab {
      font-size: 14px;
    }

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &_note {
    width: 100%;

    &List {
      text-indent: -0.8em;
      padding: 0 0 0 0.8em;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.8571428571428572;
      color: v.$white;

      @include m.sp {
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }
  }
}
