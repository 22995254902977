@use "global/mixin" as m;
@use "global/variable" as v;

.c-tag {
  $this: &;
  padding: 10px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 1px solid;

  &--green {
    @extend #{$this};
    color: v.$green;
    border-color: v.$green;

    &Bg {
      @extend #{$this};
      color: v.$white;
      background-color: v.$green;
    }
  }
}
