@use "global/mixin" as m;
@use "global/variable" as v;

.u-text {
  &--normal {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }

  &Indent {
    &Type01 {
      padding: 0 0 0 1em;
      text-indent: -1em;
    }
  }
}
